<div style="text-align: end">
    <button (click)="cancel()" aria-label="close dialogue" color="primary" mat-icon-button>
        <svg fill="#FCB040" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        </svg>
    </button>
</div>
<div mat-dialog-content style="min-width:50vw;width: auto ">
    <div class="row">
        <h1 class="mina__title ">Add \ Update Session Link</h1>
        <form (ngSubmit)="onSubmit()" [formGroup]="updateUrlForm">
            <!--      URL        -->
            <div class="col-12">
                <mat-form-field appearance="fill" class="w-100">
                    <mat-label>URL</mat-label>
                    <input formControlName="url" matInput placeholder="URl" type="text"/>
                    <mat-hint *ngIf="updateUrlForm.controls.url.errors?.required && updateUrlForm.controls.url.touched"
                              align="end">{{'forms.errors.field_required' | translate}}
                    </mat-hint>
                </mat-form-field>
            </div>
        </form>
    </div>
    <div class="submit-btn text-center m-auto">
        <button
            (click)="onSubmit()"
            [class.button__disabled]="loading || updateUrlForm.invalid || updateUrlForm.untouched"
            [class.cursorDisabled]="loading"
            [class.spinner]="loading"
            class="our__button blue__button">
            Submit
        </button>
    </div>
</div>
