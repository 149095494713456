import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {ApiResponse} from '@core/@data/Api/Api';
import {environment as env} from '../../../../environments/environment';
import {AuthService} from "@core/@http/services/auth.service";

@Injectable({
  providedIn: 'root'
})
export class DoTestBarService {
  private BehaviorSubjects = new BehaviorSubject(false);

  constructor(
    private http: HttpClient,
    private authService: AuthService,
  ) {
    this.authService.userDataBehaviorSubject.subscribe((res) => {
        if (res && res?.type === 'student') {
          this.GetStudentExams().subscribe((res) => {
            if (res?.data?.english?.length === 0 || !res?.data?.english) {
              this.showBar();
            }
          });
        }
      }
    );
  }


  GetStudentExams(): Observable<ApiResponse<{ english: any[] }>> {
    return this.http.get<ApiResponse<{ english: any[] }>>(`${env.ApiUrl}/student/exams/old/placement`);
  }

  showBar(): void {
    this.BehaviorSubjects.next(true);
  }

  hideBar(): void {
    this.BehaviorSubjects.next(false);
  }

  getBarStatus(): Observable<boolean> {
    return this.BehaviorSubjects.asObservable();
  }

}
