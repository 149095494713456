<div style="text-align: end">
    <button (click)="cancel()" aria-label="close dialogue" color="primary" mat-icon-button>
        <svg fill="#FCB040" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M0 0h24v24H0z" fill="none"/>
            <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
        </svg>
    </button>
</div>
<div mat-dialog-content>
    <div class="row">
        <div *ngIf="data!.data!.extra && data!.isTeacher" class="col-12 text-center">
            <img [lazyLoad]="data!.data!.extra!.image | imgDefault:'./assets/images/man-avatar.svg'"
                 alt="rate session"
                 class="rounded-circle "
                 defaultImage="./assets/images/man-avatar.svg"
                 height="75px"
                 width="75px">
            <p class="dialog-message mb-0">{{ data!.data!.extra!.name | slice:0:30 }}</p>
        </div>
        <div *ngIf="data!.data!.extra && !data.isTeacher" class="col-12 text-center">
            <img [lazyLoad]="data!.data!.extra!.image | imgDefault:'./assets/images/man-avatar.svg'"
                 alt="rate session"
                 class="rounded-circle "
                 defaultImage="./assets/images/man-avatar.svg"
                 height="75px"
                 width="75px">
            <p class="dialog-message mb-0">{{ data!.data!.extra!.name  | nameSplit:' ': 3 }}</p>
        </div>
        <div class="col-12">
            <p class="dialog-message mb-0">{{ data!.message }}</p>
            <div class="d-flex justify-content-between align-items-center">
                <bar-rating [(rate)]="rate"
                            [max]="5"
                            [theme]="'stars'"
                            [titles]="['1', '2' , '3', '4','5']"></bar-rating>
                <span><mark class="our-text-primary">{{rate}}</mark> / 5</span>
            </div>
        </div>
        <div class="col-12">
            <mat-form-field appearance="fill" class="w-100">
                <textarea #comment matInput placeholder="Comment..."></textarea>
                <mat-hint *ngIf="(comment.value|getLength)<5 && (comment.value|getLength)!== 0" align="start">
                    {{'forms.errors.forms' | translate}}
                </mat-hint>
            </mat-form-field>
        </div>
    </div>

    <div class="text-center m-auto mt-2">
        <button
            (click)="onSubmit()"
            [class.spinner]="loading"
            [ngClass]="{'button__disabled cursorDisabled': loading||rate < 1 || comment.value.length < 5}"
            class="w-75 our__button blue__button py-2">
            {{'actions.submit' | translate}}
        </button>
    </div>
</div>

