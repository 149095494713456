import {Injectable, TemplateRef} from '@angular/core';
import {GeneralService} from '@core/@http/services/general.service';
import {HotToastService} from '@ngneat/hot-toast';


@Injectable({
    providedIn: 'root',
})
export class ToasterService {
    constructor(
        private toast: HotToastService,
        private generalService: GeneralService,
    ) {
    }

    showSuccess(msg: string, time = 3000): void {
        this.toast.success(
            msg, {
                duration: time,
            });
    }

    showSuccessTranslated(key: string, time = 3000): void {
        this.toast.success(
            this.generalService.getTranslation(key), {
                duration: time,
            });
    }

    showFail(msg: string, duration = 3000): void {
        this.toast.error(msg, {
            duration: duration,
        });
    }

    showFailTranslated(key: string, time = 3000): void {
        this.toast.error(
            this.generalService.getTranslation(key), {
                duration: time,
            });
    }

    showFailOnlyOnce(msg: string, duration = 3000, id: string): void {
        this.toast.error(msg, {
            duration: duration,
            id: id,
        });
    }

    ShowWarning(msg: string): void {
        this.toast.warning(msg, {
            duration: 3000,
        });
    }

    showTemplate(template: TemplateRef<any>, options: any): void {
        this.toast.show(template, {...options});
    }

    showLoading(message: string): void {
        this.toast.loading(message, {
            position: 'top-center',
            id: 'loading',
        });
    }

    hideLoading(): void {
        this.toast.close('loading');
    }
}
