import {NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../../../../shared.module';
import {ChangeSessionUrlDialogueComponent} from '../change-session-url-dialouge/change-session-url-dialogue.component';
import {ChangeSessionUrlDialogueService} from '../change-session-url-dialouge/change-session-url-dialogue.service';


@NgModule({
    declarations: [

        ChangeSessionUrlDialogueComponent,
    ],
    imports: [
        SharedModule,
        RouterModule,
    ],
    exports: [
        ChangeSessionUrlDialogueComponent,
    ],
    providers: [
        ChangeSessionUrlDialogueService,
    ],
})
export class ChangeSessionUrlDialogueMoudle {
}
