import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment as env} from '../../../../environments/environment';
import {Observable} from 'rxjs';
import {ApiResponse} from '@core/@data/Api/Api';
import {ChannelData, Message, MessageObject} from '@core/@data/chat';


@Injectable({
  providedIn: 'root'
})
export class ChatService {

  constructor(private http: HttpClient) {
  }

  GetChannels(userType: string): Observable<ApiResponse<ChannelData[]>> {
    return this.http.get<ApiResponse<ChannelData[]>>(`${env.ApiUrl}/${userType}/chat/channels`);
  }

  GetChannel(userType: string, channel: string): Observable<ApiResponse<ChannelData>> {
    return this.http.get<ApiResponse<ChannelData>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}`);
  }

  GetChannelPeerId(userType: string, id: number): Observable<ApiResponse<ChannelData>> {
    return this.http.get<ApiResponse<ChannelData>>(`${env.ApiUrl}/${userType}/chat/channels/peer/${id}`);
  }

  GetChannelMessages(userType: string, channel: string, page: number): Observable<ApiResponse<Message[]>> {
    return this.http.get<ApiResponse<Message[]>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages?page=${page}`);
  }

  SearchChannelMessages(userType: string, channel: string, page: number): Observable<ApiResponse<Message>> {
    return this.http.get<ApiResponse<Message>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages?page=${page}`);
  }

  SendMessage(userType: string, channel: string, message: MessageObject | FormData): Observable<ApiResponse<Message>> {
    return this.http.post<ApiResponse<Message>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages`, message);
  }

  DeleteMessage(userType: string, channel: string, messageId: number): any {
    this.http.delete(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages/${messageId}`);
  }

  GetChannelAttachments(userType: string, channel: string, page: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages/files?page=${page}`);
  }

  GetChannelMessage(userType: string, channel: string, messageId: number): Observable<any> {
    return this.http.get<ApiResponse<any>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages/${messageId}`);
  }

  ReadMessage(userType: string, channel: string): Observable<any> {
    return this.http.get<ApiResponse<any>>(`${env.ApiUrl}/${userType}/chat/channels/${channel}/messages/read`);
  }

}// End of Class
