import {Component, OnInit} from '@angular/core';
import {GeneralService} from '@core/@http/services/general.service';
import {DoTestBarService} from '@core/components/do-test-bar/do-test-bar.service';

@Component({
    selector: 'app-do-test-bar',
    templateUrl: './do-test-bar.component.html',
    styleUrls: ['./do-test-bar.component.scss'],
})
export class DoTestBarComponent implements OnInit {
    showBar = false;
    lang = 'en';

    constructor(
        private doTestBarService: DoTestBarService,
        private generalService: GeneralService,
    ) {
        this.generalService.getCurrentLang().subscribe((lang) => {
            this.lang = lang;
        });
        this.doTestBarService.getBarStatus().subscribe((res) => {
            this.showBar = res;
        });
    }

    ngOnInit(): void {

    }

    hideBar(): void {
        this.doTestBarService.hideBar();
    }
}


