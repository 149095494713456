import {Injectable} from '@angular/core';
import {
  ChangeSessionUrlDialogueComponent,
} from '@shared/modules/courses-utilities/components/change-session-url-dialouge/change-session-url-dialogue.component';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

@Injectable()
export class ChangeSessionUrlDialogueService {
  dialogRef!: MatDialogRef<ChangeSessionUrlDialogueComponent>;

  constructor(private dialog: MatDialog) {
  }

  public open(options: { sessionId: number; }): void {
    this.dialogRef = this.dialog.open(ChangeSessionUrlDialogueComponent, {
      data: {
        sessionId: options.sessionId,
      },
    });
  }

  public confirmed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      }),
    );
  }
}
