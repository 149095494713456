import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'checkCount',
})
export class CheckCountPipe implements PipeTransform {
  transform(value: any): number {
    if (value && value > 0) {
      return value;
    } else {
      return 0;
    }
  }
}
