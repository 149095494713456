import {DOCUMENT, isPlatformServer, ViewportScroller} from '@angular/common';
import {Component, HostListener, Inject, PLATFORM_ID} from '@angular/core';

@Component({
  selector: 'app-top-of-page',
  templateUrl: './top-of-page.component.html',
  styleUrls: ['./top-of-page.component.scss']
})
export class GoToTopOfPageComponent {
  public isShown = false;

  constructor(private viewportScroller: ViewportScroller,
              @Inject(DOCUMENT) private document: Document,
              @Inject(PLATFORM_ID) private platformId: Object) {
  }

  @HostListener('window:scroll') onWindowScroll(): void {
    if (isPlatformServer(this.platformId)) {
      return;
    }
    const yCoordinate = this.viewportScroller.getScrollPosition()[1];
    this.isShown = yCoordinate > 400;
  }

  public goToTop(): void {
    this.viewportScroller.scrollToPosition([0, 0]);
  }
}
