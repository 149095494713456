<div *ngIf="!(isTest$ | async);else noNavbarLayout">
  <!--  <app-guest-nav-bar *showGuest></app-guest-nav-bar>-->
  <!--  <app-auth-nav-bar *showAuthed></app-auth-nav-bar>-->
  <app-guest-nav-bar *appShowAuthed="false"></app-guest-nav-bar>
  <app-auth-nav-bar *appShowAuthed="true"></app-auth-nav-bar>
</div>
<ng-template #noNavbarLayout>
  <router-outlet></router-outlet>
</ng-template>
<app-spinner *ngIf="loadingService.isGlobalLoading$ | async"></app-spinner>
