import {EnvConfig} from './env-config.interface';

export const environment: EnvConfig = {
  production: true,
  ApiUrl: 'https://api.otocourses.com/v1',
  PusherKeys: {
    broadcaster: 'pusher',
    key: 'a9aa0fe588e93e2df8f7',
    cluster: 'eu',
    authEndpoint: `https://api.otocourses.com/broadcasting/auth`,
    encrypted: true,
  }
};
