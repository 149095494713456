import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'tasksDivisionText',
    pure: true,
})
export class TaskDivisionPipe implements PipeTransform {
    transform(totalTasks: number, finishedTasks: number, hasFile: boolean): { isShow: boolean; value: number, isFinished: boolean } {
        let fTasks = finishedTasks;
        let tTasks = totalTasks + 1;
        if (hasFile) {
            fTasks = fTasks + 1;
        }
        const value = fTasks / tTasks;
        const show = !isNaN(value) && value !== 1 && fTasks < tTasks;
        return {isShow: show, value: value * tTasks, isFinished: fTasks / tTasks === 1};
    }
}
