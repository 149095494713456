import {Attribute, Component, Input, OnInit} from '@angular/core';

export type skeletonType =
  'sidebar'
  | 'teacher-card'
  | 'teachers-cards'
  | 'profile-page'
  | 'table'
  | 'small-table'
  | 'general-stats'
  | 'lines';

@Component({
  selector: 'app-content-loader',
  templateUrl: './content-loader.component.html',
  styleUrls: ['./content-loader.component.scss'],
})
export class ContentLoaderComponent implements OnInit {
  speed = 2;
  @Input() numberOfDiagrams = 3;

  constructor(
    @Attribute('type') public type: skeletonType,
  ) {
  }

  get loopArray(): number[] {
    return Array(this.numberOfDiagrams).fill(0).map((_, index) => index);
  }

  ngOnInit(): void {
  }

}
