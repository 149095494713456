<div *ngIf="data!.title" class="header2">
  <h1 class="our-text-primary" mat-dialog-title>{{ data!.title }}</h1>
</div>

<div *ngIf="loading" class="container">
  <div class="row">
    <div class="col-12 content__loader">
      <app-content-loader type="teachers-cards"></app-content-loader>
    </div>
  </div>
</div>
<section *ngIf="!loading" class="data__section-session2">
  <div class="container">
    <div class="row justify-content-center align-items-start" style="min-height: 300px;max-height: 400px;">
      <div class="col-12 col-md-6">
        <app-mat-calendar-demo (dateSelected)="availableSlots=$event;"
                               [datesArray]="slots"></app-mat-calendar-demo>
      </div>
      <div class="col-12 col-md-6">
        <div class="slots__container p-0">
          <h2>{{ 'sentences.available_slots' | translate }}</h2>
          <div *ngIf="(availableSlots|getLength)>0" [class.table__box__shadow]="(availableSlots| getLength)>4"
               class="content slot__lists__container">
            <ul class="slots__lists">
              <li *ngFor="let slot of availableSlots;let i = index;trackBy:trackBy;" class="list__item">
                <p class="slot__content">
                  <span class="list__counter">{{ i + 1 }} : </span>{{ slot?.start }}</p>
                <button
                  (click)="slotClick(slot)"
                  class="our__button blue__button">
                  {{ data!.data!.isReSchedule ? ('actions.reschedule' | translate) : ('actions.book' | translate) }}
                </button>
              </li>
            </ul>
          </div>
          <p *ngIf="(availableSlots|getLength) === 0" class="our-text-primary text-center mt-4">
            {{ 'no_data.slots' | translate }}
          </p>
        </div>
      </div>
    </div>
    <div *ngIf="!data!.data!.isReSchedule" class="row justify-content-center">
      <div class="col-12">
        <div class="w-100 d-flex justify-content-center align-items-center flex-wrap">
          <div class="w-100">
            <p class="our-text-sec text-center mb-0 m-auto" style="max-width: 350px;">
              {{ 'sentences.book_sessions_note' | translate }}
            </p>
          </div>
          <!--          <button (click)="bookMoreSessions()" class="book__link my-2">-->
          <!--            {{ 'actions.book_more_session'|translate }}-->
          <!--          </button>-->
          <!--          <br>-->
          <a [href]="'/teacher-details' | getUrl : getExtraData()" class="book__link my-2" target="_blank">
            {{ 'actions.book_more_session'|translate }}
          </a>
          <br>
          <!--          <a [fragment]="'schedule'"-->
          <!--             [queryParams]="{-->
          <!--            user: this.data.data.username,-->
          <!--            course: 'no-course'-->
          <!--          }"-->
          <!--             [routerLink]="['teacher-details']"-->
          <!--             class="book__link my-2" target="_blank">-->
          <!--            {{ 'actions.book_more_session'|translate }}-->
          <!--          </a>-->
        </div>
      </div>
    </div>
  </div>
</section>

<section mat-dialog-actions>
  <div class="example-button-row">
    <button (click)="close(true)" class="our__button red__button">
      {{ data!.cancelText }}
    </button>
  </div>
</section>
