import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {AfterViewInit, Component, Inject, OnDestroy, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';
import {ApiResponse} from '@core/@data/Api/Api';
import {PresentationalDialogue} from '@core/@data/Dialogue/Presentational';
import {MenuLink, RouteLink} from '@core/@data/General/Navbar';
import {NewStudentCoursesStats} from '@core/@data/New/NEW-Student';
import {Notification} from '@core/@data/Notification';
import {User} from '@core/@data/UsersData/UserData';
import {AuthService} from '@core/@http/services/auth.service';
import {ChatService} from '@core/@http/services/chat.service';
import {GeneralService} from '@core/@http/services/general.service';
import {NotificationService} from '@core/@http/services/notification.service';
import {StudentCoursesService} from '@core/@http/services/Student/student-courses.service';
import {WebsocketService} from '@core/@http/services/websocket.service';
import {AnnouncementBarService} from '@core/components/announcement-bar/announcement-bar.service';
import {DoTestBarService} from '@core/components/do-test-bar/do-test-bar.service';
import {TranslateService} from '@ngx-translate/core';
import {
  PresentationalDialogueService
} from '@shared/components/Dialouges/presentational-dialouge/presentational-dialogue.service';
import {ToasterService} from '@shared/services/toastr.service';
import {interval, Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {environment as env} from '../../../../../environments/environment';
import {StudentLinks, StudentMenu, TeacherLinks, TeacherMenu} from '@core/components/app-main-navbar/data';
import {ChatMainService} from '@shared/modules/chat/new-chat-ui/services/chat-main.service';
import {calculateDateInRange, DefaultScheduleRange} from '@shared/functions/dates-utils';


@Component({
  selector: 'app-auth-nav-bar',
  templateUrl: './auth-nav-bar.component.html',
  styleUrls: ['./auth-nav-bar.component.scss'],
})
export class AuthNavBarComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  userName!: string;
  userImage!: string;
  userType!: User;
  notifications: Notification[] = [];
  unReadMessages = 0;
  pusherSubscription: any;
  availableSessions = 0;
  showBar$!: Observable<boolean>;
  showTestBar$!: Observable<boolean>;
  StudentLinks: RouteLink[] = StudentLinks;
  TeacherLinks: RouteLink[] = TeacherLinks;
  StudentMenu: MenuLink[] = StudentMenu;
  TeacherMenu: MenuLink[] = TeacherMenu;
  lang = {key: 'en', value: 'EN'};
  showDefaultScheduleBar = false;

  constructor(
    public authService: AuthService,
    public chatMainService: ChatMainService,
    private notificationService: NotificationService,
    private websocketService: WebsocketService,
    private chatService: ChatService,
    private router: Router,
    private toasterService: ToasterService,
    private http: HttpClient,
    private studentCoursesService: StudentCoursesService,
    private notificationBarService: AnnouncementBarService,
    private doTestBarService: DoTestBarService,
    private presentationalDialogueService: PresentationalDialogueService,
    public generalService: GeneralService,
    public translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object,
  ) {
    this.generalService.getCurrentLang().subscribe((lang) => {
      if (this.translateService.currentLang == 'en') {
        this.lang = {key: 'ar', value: 'ع'};
      } else {
        this.lang = {key: 'en', value: 'EN'};
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.sidenav?.close().then();
    });
    this.authService.userAccountBehaviorSubject.subscribe((res) => {
      this.userName = res?.account?.first_name + ' ' + res?.account?.last_name;
      this.userImage = res?.account?.image as string;
      if (res && ('is_allowDefaultSchedule' in res?.account) && res?.account?.is_allowDefaultSchedule && calculateDateInRange(DefaultScheduleRange)) {
        this.showDefaultScheduleBar = true;
        this.TeacherMenu.splice(this.TeacherMenu.length, 0, {
          title: 'Default Schedule',
          route: '/teacher/my-calendar/default-schedule',
          key: 'routes.default_schedule',
          icon: 'myCalendarIcon',
        });
        // this.TeacherLinks.unshift({
        //   isExact: true,
        //   name: 'Default Schedule',
        //   link: '/teacher/my-calendar/default-schedule',
        //   key: 'routes.default_schedule'
        // });
      }
    });
    this.authService.userDataBehaviorSubject.subscribe((res) => {
      if (res !== null) {
        this.userType = res;
        if (res.type === 'student') {
          this.showBar$ = this.notificationBarService.getBarStatus();
          this.showTestBar$ = this.doTestBarService.getBarStatus();
          // Get student Credit of Sessions
          this.GetStudentCoursesStatistics().subscribe((res) => {
            this.availableSessions = res;
          });
        }
      }
    });
  }

  ngAfterViewInit() {
    interval(1000).pipe(take(5)).subscribe((res) => {
      // if ((res + 1) === 5 && this.userType !== null) {
      //     this.chatMainService.init(this.userType);
      // }
      if (res === 0 && this.userType !== null) {
        this.chatMainService.init(this.userType);
        this.initNotifications(this.userType);
      }
    });
    this.chatMainService.Status$.subscribe((status) => {
      // if (status === 'Finished') {
      //     this.allChannels = this.chatMainService.allChannels;
      // }
    });
    this.chatMainService.unReadMessageBehaviorSubject$.subscribe((res) => {
      this.unReadMessages = res;
      // console.log('unReadMessageBehaviorSubject$ auth navbar res', res);
    });
  }

  logout(): void {
    this.authService.Logout();
  }

  NotificationRead(notification: Notification): void {
    const options: PresentationalDialogue<string[]> = {
      title: this.generalService.getTranslation('words.notification'),
      isMessage: true,
      message: notification.data.text,
      isData: false,
      isActions: true,
      cancelText: this.generalService.getTranslation('actions.cancel'),
      data: [],
    };
    this.presentationalDialogueService.open(options);
    this.notificationService.MarkAsRead(this.userType?.type, {
      notifications: [notification.id],
    }).subscribe((_) => {
      this.notifications = this.notifications.filter((not) => {
        return not.id !== notification.id;
      });
    });
  }

  initNotifications(User: User) {
    this.notificationService.GetAllNotifications(User.type).pipe(
      map((data) => {
        return data.data.slice(0, 15);
      }),
    ).subscribe((allResults) => {
      this.notifications = allResults.filter((notification) => {
        return !(notification.read_at && notification.read_at !== '');
      });
      this.pusherSubscription = this.websocketService.listenNotificationChannel(User.id);
      this.pusherSubscription.bind('notification.new', (data: any) => {
        this.toasterService.showSuccess(data?.data?.text);
      });
    });
  }

  ngOnDestroy(): void {
  }

  GetStudentCoursesStatistics(): Observable<number> {
    return this.http.get<ApiResponse<NewStudentCoursesStats>>(`${env.ApiUrl}/student/courses/statistics`).pipe(
      map((data) => {
        return data?.data?.general?.statistics?.active_packages_count;
      }),
    );
  }

  changeLang(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.replace(`${this.lang.key}/${this.router.url.slice(4)}`);
    }
  }

}
