import {Component} from '@angular/core';

@Component({
  selector: 'app-square-box-loader',
  templateUrl: './square-box-loader.html',
  styleUrls: ['./square-box-loader.scss']
})
export class SquareBoxLoaderComponent {
  constructor() {
  }

}
