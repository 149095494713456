import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'getLevel',
})
export class GetLevelPipe implements PipeTransform {
  transform(value: string | undefined): { color: string; value: string; } {
    if (!value) {
      return {color: 'gray', value: 'No test attempts yet'};
    }
    if (value && value !== '') {
      switch (value) {
        case 'A1': {
          return {color: 'green', value: 'Beginner'};
        }
        case 'A2':
        case 'B1': {
          return {color: 'blue', value: 'Intermediate'};
        }
        case 'B2':
        case 'C1': {
          return {color: 'red', value: 'Advanced'};
        }
        default: {
          return {color: 'gray', value: 'No test attempts yet'};
        }
      }
    } else {
      return {color: 'gray', value: 'No test attempts yet'};
    }
  }
}

