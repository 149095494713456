import {Component, OnInit} from '@angular/core';
import {GeneralService} from '@core/@http/services/general.service';

@Component({
  selector: 'app-default-schedule-bar',
  templateUrl: './default-schedule-bar.component.html',
  styleUrls: ['./default-schedule-bar.component.scss'],
})
export class DefaultScheduleBarComponent implements OnInit {
  lang = 'en';

  constructor(
    private generalService: GeneralService,
  ) {
    this.generalService.getCurrentLang().subscribe((lang) => {
      this.lang = lang;
    });
  }

  ngOnInit(): void {

  }

}


