import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {LocalstorageService} from '@core/@http/services/localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class AnnouncementBarService {
  private BehaviorSubjects = new BehaviorSubject(false);

  constructor(private localStorageService: LocalstorageService,
  ) {
    const flag = this.localStorageService.state$?.getValue()?.is_v;
    if (flag && flag === 'false') {
      this.showBar();
    }
  }

  showBar(): void {
    this.BehaviorSubjects.next(true);
  }

  hideBar(): void {
    this.BehaviorSubjects.next(false);
  }

  getBarStatus(): Observable<boolean> {
    return this.BehaviorSubjects.asObservable();
  }

  VerifyEmail(): void {
    this.localStorageService.setState('is_v', 'true');
    this.hideBar();
  }
}
