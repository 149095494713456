import {Component, EventEmitter, OnInit, Output} from '@angular/core';
import {ChannelData} from '@core/@data/chat';
import {ChatMainService} from '@shared/modules/chat/new-chat-ui/services/chat-main.service';

@Component({
  selector: 'app-chat-channels',
  templateUrl: './chat-channels.component.html',
  styleUrls: ['./chat-channels.component.scss'],
})
export class ChatChannelsComponent implements OnInit {
  @Output() channelSelected: EventEmitter<ChannelData> = new EventEmitter<ChannelData>();
  loading = true;

  constructor(public chatMainService: ChatMainService) {
  }

  ngOnInit(): void {
  }

  channelClick(channel: ChannelData) {
    this.channelSelected.emit(channel);
  }
}
