import {CommonModule} from '@angular/common';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {RouterModule} from '@angular/router';
import {LocalizeRouterModule} from '@gilsdav/ngx-translate-router';
import {ContentLoaderModule} from '@ngneat/content-loader';
import {TranslateModule} from '@ngx-translate/core';
import {DialogueComponent} from '@shared/components/Dialouges/dialouge/dialogue.component';
import {
  PresentationalDialogueComponent
} from '@shared/components/Dialouges/presentational-dialouge/presentational-dialogue.component';
import {RateDialogueComponent} from '@shared/components/Dialouges/rate-dialouge/rate-dialogue.component';
import {
  MatCalendarSessionsComponent
} from '@shared/components/Dialouges/sessions-dialouge/mat-calendar-demo/mat-calendar-demo.component';
import {SessionsDialogueComponent} from '@shared/components/Dialouges/sessions-dialouge/sessions-dialogue.component';
import {ContentLoaderComponent} from '@shared/components/loaders/content-loader/content-loader.component';
import {SpinnerComponent} from '@shared/components/loaders/spinner/spinner.component';
import {SquareBoxLoaderComponent} from '@shared/components/loaders/square-box-loader/square-box-loader';
import {AutomaticLoginComponent} from '@shared/components/other/automatic-login/automatic-login.component';
import {BreadCrumbsComponent} from '@shared/components/other/bread-crumbs/bread-crumbs.component';
import {NotFoundComponent} from '@shared/components/other/not-found/not-found.component';
import {SmallStartRatingComponent} from '@shared/components/other/small-start-rating/small-start-rating.component';
import {SubmitButtonComponent} from '@shared/components/other/submit-button/submit-button.component';
import {YoutubeIframeComponent} from '@shared/components/other/youtube-iframe/youtube-iframe.component';
import {AuthDirective} from '@shared/directives/auth.directive';
import {BlockCopyPasteDirective} from '@shared/directives/block-copy-paste.directive';
import {DisableOpenDevtoolsDirective} from '@shared/directives/disable-open-devtools.directive';
import {GuestDirective} from '@shared/directives/guest.directive';
import {RefreshPageDirective} from '@shared/directives/refresh-page.directive';
import {RoleDirective} from '@shared/directives/role.directive';
import {ShowAuthedDirective} from '@shared/directives/show-authed.directive';
import {ShowInTimeDirective} from '@shared/directives/show-in-time.directive';
import {MaterialModule} from '@shared/material/material.module';
import {AgeCalculationPipe} from '@shared/pipes/age-calculation.pipe';
import {CheckExistingPipe} from '@shared/pipes/check-existing.pipe';
import {CheckNumberExistingPipe} from '@shared/pipes/check-number-existing.pipe';
import {CheckUrlPipe} from '@shared/pipes/check-url.pipe';
import {CheckCountPipe} from '@shared/pipes/checkCount.pipe';
import {ConvertSecondsFormatPipe} from '@shared/pipes/convert-seconds-format.pipe';
import {FilterPipe} from '@shared/pipes/filter.pipe';
import {GetCourseByIdPipe} from '@shared/pipes/get-course-by-id.pipe';
import {GetLengthPipe} from '@shared/pipes/get-length.pipe';
import {GetLevelDataPipe} from '@shared/pipes/get-level-data.pipe';
import {GetLevelPipe} from '@shared/pipes/get-level.pipe';
import {GetCourseColorPipe} from '@shared/pipes/getcourse-color.pipe';
import {HtmlPipe} from '@shared/pipes/html.pipe';
import {ImgDefaultPipe} from '@shared/pipes/img-default.pipe';
import {NameFilterPipe} from '@shared/pipes/name-filter.pipe';
import {NameSplitPipe} from '@shared/pipes/name-split.pipe';
import {SafeUrlPipe} from '@shared/pipes/safe-url.pipe';
import {SafeYoutubeUrlPipe} from '@shared/pipes/safe-youtube-url.pipe';
import {StringToNumberPipe} from '@shared/pipes/string-to-number.pipe';
import {TaskDivisionPipe} from '@shared/pipes/task-division.pipe';
import {TimesDiffPipe} from '@shared/pipes/times-diff.pipe';
import {TruncateTextPipe} from '@shared/pipes/truncate-text.pipe';
import {LazyLoadImageModule} from 'ng-lazyload-image';
import {BarRatingModule} from 'ngx-bar-rating';
import {DefaultImageOnErrorDirective} from '@shared/directives/default-image-on-error.directive';
import {GetCalendarUrlPipe} from '@shared/pipes/get-calendar-url.pipe';
import {DateRangeCheckDirective} from '@shared/directives/date-range-check.directive';
import {AnswerTypePipe} from '@shared/pipes/answer-type.pipe';
import {GetUrlPipe} from '@shared/pipes/get-url.pipe';

/*----------------------   PIPES   ----------------------*/
export const pipes: any[] = [
  SafeYoutubeUrlPipe,
  TruncateTextPipe,
  ImgDefaultPipe,
  NameSplitPipe,
  SafeUrlPipe,
  FilterPipe,
  HtmlPipe,
  GetLengthPipe,
  GetLevelPipe,
  CheckExistingPipe,
  TimesDiffPipe,
  CheckUrlPipe,
  GetLevelDataPipe,
  ConvertSecondsFormatPipe,
  NameFilterPipe,
  StringToNumberPipe,
  AgeCalculationPipe,
  TaskDivisionPipe,
  GetCourseColorPipe,
  CheckNumberExistingPipe,
  GetCourseByIdPipe,
  CheckCountPipe,
  GetCalendarUrlPipe,
  AnswerTypePipe,
  GetUrlPipe
];

/*----------------------   Directives   ----------------------*/
export const directives: any[] = [
  GuestDirective,
  AuthDirective,
  RoleDirective,
  DisableOpenDevtoolsDirective,
  BlockCopyPasteDirective,
  RefreshPageDirective,
  ShowAuthedDirective,
  ShowInTimeDirective,
  DefaultImageOnErrorDirective,
  DateRangeCheckDirective
];

/*----------------------   Components   ----------------------*/
export const components: any[] = [
  NotFoundComponent,
  SubmitButtonComponent,
  SpinnerComponent,
  BreadCrumbsComponent,
  YoutubeIframeComponent,
  SmallStartRatingComponent,
  SquareBoxLoaderComponent,
  AutomaticLoginComponent,
  ContentLoaderComponent,
];

/*----------------------   Dialogue Components   ----------------------*/
export const dialoguesComponents: any[] = [
  PresentationalDialogueComponent,
  MatCalendarSessionsComponent,
  SessionsDialogueComponent,
  RateDialogueComponent,
  DialogueComponent,
];

/*----------------------   Imports Components   ----------------------*/
export const imports: any[] = [
  CommonModule,
  RouterModule,
  MaterialModule,
  ReactiveFormsModule,
  FormsModule,
  LazyLoadImageModule,
  BarRatingModule,
  ContentLoaderModule,
  TranslateModule,
  LocalizeRouterModule
];
