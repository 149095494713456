import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'checkNumberExisting',
})
export class CheckNumberExistingPipe implements PipeTransform {
    transform(value: number, indexes: number[]): boolean {
        if (value && indexes.length > 0) {
            return !indexes.includes(value);
        }
        else {
            return false;
        }
    }
}
