import {Routes} from '@angular/router';
import {RequestVerifyEmailComponent} from '@app/modules/@auth/request-verify-email/request-verify-email.component';
import {
  ValidateResetPasswordComponent
} from '@app/modules/@auth/validate-reset-password/validate-reset-password.component';
import {AuthGuardService} from '@core/@http/guards/auth.guard';
import {GuestGuardService} from '@core/@http/guards/gest.guard';
import {StudentGuard} from '@core/@http/guards/student.guard';
import {TeacherGuard} from '@core/@http/guards/teacher.guard';

/*--------------------------- Static Pages -------------------------------*/
export const StaticPagesRoutes: Routes = [
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./modules/static-pages/home-page/home-page.module').then((m) => {
        return m.HomePageModule;
      }),
  },
  {
    path: 'courses',
    loadChildren: () => import('./modules/static-pages/courses/courses.module').then((m) => {
      return m.CoursesModule;
    }),
  },
  {
    path: 'cefr',
    loadChildren: () => import('./modules/static-pages/cefr/cefr.module').then((m) => {
      return m.CefrModule;
    }),
  },
  {
    path: 'contact-us',
    loadChildren: () => import('./modules/static-pages/contact-us/contact-us.module')
      .then((m) => {
        return m.ContactUsModule;
      }),
  },
  {
    path: 'instructor-register',
    loadChildren: () => import('./modules/static-pages/instructor-form/instructor-form.module')
      .then((m) => {
        return m.InstructorFormModule;
      }),
    canActivate: [GuestGuardService],
  },
  {
    path: 'about-us',
    loadChildren: () => import('./modules/static-pages/about-us/about-us.module')
      .then((m) => {
        return m.AboutUsModule;
      }),
  },
  {
    path: 'terms-conditions',
    loadChildren: () => import('./modules/static-pages/terms-conditions/terms-conditions.module')
      .then((m) => {
        return m.TermsConditionsModule;
      }),
  },
  {
    path: 'cancellation-and-refund-policy',
    loadChildren: () => import('./modules/static-pages/refund-policy/refund-policy.module')
      .then((m) => {
        return m.RefundPolicyModule;
      }),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./modules/static-pages/privacy-policy/privacy-policy.module').then((m) => {
      return m.PrivacyPolicyModule;
    }),
  },
  {
    path: 'groups/courses',
    loadChildren: () =>
      import('./modules/group-courses/group-courses.module')
        .then((m) => {
          return m.GroupCoursesModule;
        }),
  },
  {
    path: 'course-groups',
    loadChildren: () =>
      import('./modules/static-pages/courses-groups/courses-groups.module')
        .then((m) => {
          return m.CoursesGroupsModule;
        }),
  },
  {
    path: 'email-unsubscription',
    loadChildren: () =>
      import('./modules/email-unsubscription/email-unsubscription.module')
        .then((m) => {
          return m.EmailUnsubscriptionModule;
        }),
  },
];
/*--------------------------- Teachers Data Pages -------------------------------*/
export const TeacherPublicPagesRoutes: Routes = [
  {
    path: 'teachers',
    loadChildren: () => import('./modules/teachers/teachers.module').then((m) => {
        return m.TeachersModule;
      },
    ),
  },
  {
    path: 'teacher-details',
    loadChildren: () => import('./modules/teacher-profile/teacher-details.module')
      .then((m) => {
        return m.TeacherDetailsModule;
      }),
  },
  {
    path: 'book-teacher',
    loadChildren: () => import('./modules/Student/book-teacher/book-teacher.module')
      .then((m) => {
        return m.BookTeacherModule;
      }),
    canActivate: [StudentGuard],
  },
  {
    path: 'payment',
    loadChildren: () => import('./modules/Student/payment/payment.module')
      .then((m) => {
        return m.PaymentModule;
      }),
    canActivate: [StudentGuard],
  },
];
/*--------------------------- Teacher Auth Pages -------------------------------*/
export const TeacherRoutes: Routes = [
  {
    path: 'teacher/my-account',
    loadChildren: () =>
      import('./modules/Teacher/dashboard-teacher/teacher-dashboard.module').then((m) => {
        return m.TeacherDashboardModule;
      }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/profile',
    loadChildren: () => import('./modules/Teacher/teacher-profile/teacher-profile.module')
      .then((m) => {
        return m.TeacherProfileModule;
      }),
  },
  {
    path: 'teacher/setting',
    loadChildren: () => import('./modules/Teacher/teacher-update-profile/teacher-update-profile.module')
      .then((m) => {
        return m.TeacherUpdateProfileModule;
      }),
  },
  {
    path: 'teacher/my-calendar',
    loadChildren: () =>
      import('./modules/Teacher/set-schedule/set-schedule.module').then((m) => {
        return m.SetScheduleModule;
      }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/student-report',
    loadChildren: () =>
      import('./modules/Teacher/teacher-lesson-report/teacher-lesson-report.module').then((m) => {
        return m.TeacherLessonReportModule;
      }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/student-course-file',
    loadChildren: () =>
      import('./modules/Teacher/teacher-course-file/teacher-course-file.module').then((m) => {
        return m.TeacherCourseFileModule;
      }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/student-course-assessment',
    loadChildren: () =>
      import('./modules/Teacher/teacher-students-assessments/teacher-students-assessments.module')
        .then((m) => {
          return m.TeacherStudentsAssessmentsModule;
        }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/student-package-assessments',
    loadChildren: () =>
      import('./modules/Teacher/student-assessments-page/student-assessments-page.module')
        .then((m) => {
          return m.StudentAssessmentsPageModule;
        }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/student-file',
    loadChildren: () =>
      import('./modules/Teacher/student-file/student-file.module')
        .then((m) => {
          return m.StudentFileModule;
        }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/my-students',
    loadChildren: () =>
      import('./modules/Teacher/my-students/my-students.module')
        .then((m) => {
          return m.MyStudentsModule;
        }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/unevaluated-tests',
    loadChildren: () =>
      import('./modules/Teacher/test-evaluation/test-evaluation.module')
        .then((m) => {
          return m.TestEvaluationModule;
        }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/create-group',
    loadChildren: () =>
      import('./modules/Teacher/create-group-course/create-group-course.module')
        .then((m) => {
          return m.CreateGroupCourseModule;
        }),
    canActivate: [TeacherGuard],
  },
  {
    path: 'teacher/conversation-club',
    loadChildren: () =>
      import('./modules/Teacher/conversation-club/conversation-club.module')
        .then((m) => {
          return m.ConversationClubModule;
        }),
    canActivate: [TeacherGuard],
  },
];
/*--------------------------- Student Auth Pages -------------------------------*/
export const StudentRoutes: Routes = [
  {
    path: 'my-account',
    loadChildren: () => import('./modules/Student/dashboard/dashboard.module').then((m) => {
      return m.DashboardModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'my-schedule',
    loadChildren: () => import('./modules/Student/my-schedule/my-schedule.module').then((m) => {
      return m.MyScheduleModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'course-file',
    loadChildren: () => import('./modules/Student/student-file/student-file.module').then((m) => {
      return m.StudentFileModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'lesson-report',
    loadChildren: () => import('./modules/Student/session-report/session-report.module').then((m) => {
      return m.SessionReportModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'test',
    loadChildren: () => import('@student/all-placement-tests/simple-test/simple-test.module').then((m) => {
      return m.SimpleTestModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'placement-test',
    loadChildren: () => import('@student/all-placement-tests/placement-test/placement-test.module').then((m) => {
      return m.PlacementTestModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'check-placement-test',
    loadChildren: () => import('./modules/Student/check-placement-test/check-placement-test.module').then((m) => {
      return m.CheckPlacementTestModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'full-placement-test',
    loadChildren: () => import('@student/all-placement-tests/full-placement-test/full-placement-test.module').then((m) => {
      return m.FullPlacementTestModule;
    }),
    canActivate: [StudentGuard],
  },
  {
    path: 'my-learning',
    loadChildren: () =>
      import('./modules/Student/my-learning/my-learning.module')
        .then((m) => {
          return m.MyLearningModule;
        }),
    canActivate: [StudentGuard],
  },
  {
    path: 'my-credit',
    loadChildren: () =>
      import('./modules/Student/my-credit/my-credit.module')
        .then((m) => {
          return m.MyCreditModule;
        }),
    canActivate: [StudentGuard],
  },
  {
    path: 'setting',
    loadChildren: () =>
      import('./modules/Student/update-profile/update-profile.module')
        .then((m) => {
          return m.UpdateProfileModule;
        }),
    canActivate: [StudentGuard],
  },
  {
    path: 'assessments',
    loadChildren: () =>
      import('./modules/Student/student-assessments/student-assessments.module')
        .then((m) => {
          return m.StudentAssessmentsModule;
        }),
    canActivate: [StudentGuard],
  },
  {
    path: 'conversation-club',
    loadChildren: () =>
      import('./modules/Student/conversation-club/conversation-club.module')
        .then((m) => {
          return m.ConversationClubModule;
        }),
    canActivate: [StudentGuard],
  },
];
/*---------------------------  Auth Pages -------------------------------*/
/*--------------------------- Student Auth Pages -------------------------------*/
export const StudentAndTeacherRoutes: Routes = [
  {
    path: 'messages',
    loadChildren: () => import('./@shared/modules/chat/new-chat-page/new-chat-page.module').then((m) => {
      return m.NewChatPageModule;
    }),
    canActivate: [AuthGuardService],
  },
];
export const AuthRoutes: Routes = [
  {
    path: 'user/login',
    loadChildren: () => import('./modules/@auth/login-with-social-media-page/login-with-social-media.module').then((m) => {
      return m.LoginWithSocialMediaModule;
    }),
    canActivate: [GuestGuardService],
  },
  {
    path: 'user/signup',
    loadChildren: () => import('./modules/@auth/register-page/register.module').then((m) => {
      return m.RegisterModule;
    }),
    canActivate: [GuestGuardService],
  },
  {
    path: 'user/forget-password',
    loadChildren: () => import('./modules/@auth/request-reset-password/request-reset-password.module')
      .then((m) => {
        return m.RequestResetPasswordModule;
      }),
    canActivate: [GuestGuardService],
  },
  {
    path: 'user/reset-password',
    loadChildren: () => import('./modules/@auth/reset-password/reset-password.module')
      .then((m) => {
        return m.ResetPasswordModule;
      }),
    canActivate: [GuestGuardService],
  },
  {
    path: 'user/validate-request',
    component: ValidateResetPasswordComponent,
    loadChildren: () => import('./modules/@auth/validate-reset-password/validate-reset-password.module')
      .then((m) => {
        return m.ValidateResetPasswordModule;
      }),
    canActivate: [GuestGuardService],
  },
  {
    path: 'user/verify-email',
    component: RequestVerifyEmailComponent,
    loadChildren: () => import('./modules/@auth/request-verify-email/request-verify-email.module')
      .then((m) => {
        return m.RequestVerifyEmailModule;
      }),
  },
  {
    path: 'user/validate-email',
    loadChildren: () => import('./modules/@auth/validate-email/validate-email.module')
      .then((m) => {
        return m.ValidateEmailModule;
      }),
  },
];
/*---------------------------  shared Pages ( teachers & students) -------------------------------*/
export const TeacherStudentSharedPages: Routes = [
  {
    path: 'features/change-log',
    loadChildren: () => import('./@shared/modules/change-log/change-log.module').then((m) => {
      return m.ChangeLogModule;
    }),
    canActivate: [AuthGuardService],
  }
];
/*--------------------------- Test New Features Pages -------------------------------*/
export const TestNewFeatures: Routes = [
  {
    path: 'test-new-features',
    loadChildren: () => import('./modules/test-new-features/test-new-features.module').then((m) => {
      return m.TestNewFeaturesModule;
    }),
    // canActivate: [AuthGuardService],
  },

];
