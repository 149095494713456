<ng-container *ngIf="chatMainService.allChannels$.value.length > 0;">
    <div class="all-channels">
        <ng-container *ngFor="let channel of (chatMainService.allChannels$ | async)">
            <div (click)="channelClick(channel)" class="channel-wrapper">
                <div class="channel-image">
                    <img
                        *ngIf="channel.is_group"
                        [src]="'./assets/images/group-of-people-svgrepo-com.svg'"
                        alt="chat image"
                        class=""
                        height="35px"
                        width="35px">
                    <img
                        *ngIf="!channel.is_group"
                        [defaultImageOnError]="'./assets/images/network-avatar-svgrepo-com.svg'"
                        [lazyLoad]="channel!.students[0]!.image| imgDefault:'./assets/images/man-avatar.svg'"
                        alt="chat image"
                        class="" defaultImage="./assets/images/network-avatar-svgrepo-com.svg" height="35px"
                        width="35px">
                </div>
                <div class="channel-content-wrapper">
                    <div class="channel-content">
                        <p *ngIf="!channel.is_group" class="channel-name">
                            {{chatMainService.currentUser.type === 'student' ? (channel?.teacher?.name | nameSplit:' ': 2 | slice:0:20) : (channel.students[0].name | nameSplit:' ': 2 | slice:0:20)}}
                        </p>
                        <p *ngIf="channel.is_group" class="channel-name">
                            {{ channel?.chat_name }}
                        </p>
                        <p *ngIf="channel!.last_message" class="channel-last-message">
                            {{ channel!.last_message!.message | slice:0:16  }}
                            {{(channel!.last_message!.message | getLength) > 16 ? '...' : ''}}
                        </p>
                    </div>
                    <div *ngIf="channel.unread_messages_count > 0" class="channel-unread-identifier">
                            <span>
                                 <svg height="12" viewBox="0 0 24 24"
                                      width="12"
                                      xmlns="http://www.w3.org/2000/svg"><path
                                     d="M24 24H0V0h24v24z" fill="none"/><circle
                                     cx="12" cy="12"
                                     r="8"/>
                                 </svg>
                            </span>
                        <span>{{channel.unread_messages_count}}</span>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</ng-container>
<ng-container *ngIf="chatMainService.allChannels$.value.length < 0">
    <div class="d-flex align-items-center justify-content-center w-100 h-100 flex-column">
        <h5>{{'no_data.chats' | translate}}</h5>
        <div class="text-center" style="width: 250px;">
            <svg data-name="Layer 1" height="250px" viewBox="0 0 863.91732 364.20537" width="250px"
                 xmlns="http://www.w3.org/2000/svg">
                <polygon
                    fill="#f0f0f0"
                    points="311.959 119.745 0 119.745 0 222.156 11.817 222.156 11.817 248.941 38.601 222.156 311.959 222.156 311.959 119.745"/>
                <rect fill="#fff" height="81.92868" width="294.62811" x="8.66553" y="129.71814"/>
                <rect fill="#f0f0f0" height="4.30497" width="141.85589" x="34.72148" y="154.42552"/>
                <rect fill="#f0f0f0" height="4.30497" width="247.24292" x="34.72148" y="169.31777"/>
                <rect fill="#f0f0f0" height="4.30497" width="247.00081" x="34.72148" y="184.21003"/>
                <path d="M692.07274,618.96676s1.487-31.15875,31.97119-27.537" fill="#f0f0f0"
                      transform="translate(-168.04134 -267.89732)"/>
                <circle cx="515.41796" cy="306.16087" fill="#f0f0f0" r="15.2571"/>
                <rect fill="#f0f0f0" height="30.14703" width="4.30672" x="512.9354" y="331.85294"/>
                <circle cx="666.92952" cy="180.07338" fill="#3f3d56" r="123.29665"/>
                <path
                    d="M757.348,457.86815a32.62688,32.62688,0,0,1,50.081,0,36.26372,36.26372,0,1,0-51.27085-1.18987Q756.73918,457.28694,757.348,457.86815Z"
                    fill="#fff" transform="translate(-168.04134 -267.89732)"/>
                <path
                    d="M855.26,457.86815a32.627,32.627,0,0,1,50.08092,0,36.26371,36.26371,0,1,0-51.2708-1.18987Q854.65117,457.28694,855.26,457.86815Z"
                    fill="#fff" transform="translate(-168.04134 -267.89732)"/>
                <circle cx="601.97649" cy="151.39215" fill="#3f3d56" r="12.47434"/>
                <circle cx="699.88499" cy="151.39215" fill="#3f3d56" r="12.47434"/>
                <circle cx="578.08341" cy="210.89752" fill="#33abb8" r="14.50548"/>
                <circle cx="744.8965" cy="210.89752" fill="#33abb8" r="14.5055"/>
                <polygon fill="#33abb8" points="661.49 181.886 650.611 229.029 668.742 210.898 661.49 181.886"/>
                <polygon
                    fill="#3f3d56"
                    points="717.39 363.205 705.038 352.839 705.326 363.205 701.49 363.205 701.183 352.244 684.507 363.205 677.526 363.205 701.059 347.737 700.147 315.258 699.466 290.728 703.293 290.623 703.984 315.258 704.894 347.708 723.354 363.205 717.39 363.205"/>
                <polygon
                    fill="#3f3d56"
                    points="659.363 363.205 647.012 352.839 647.3 363.205 643.474 363.205 643.167 352.244 626.49 363.205 619.509 363.205 643.033 347.737 642.122 315.258 641.441 290.728 645.276 290.623 645.967 315.258 646.868 347.708 665.328 363.205 659.363 363.205"/>
                <path
                    d="M836.784,315.60813c-3.3831,0-6.36764,2.628-8.36294,6.66445-1.75872-6.06969-5.45374-10.29078-9.7689-10.29078a6.56326,6.56326,0,0,0-.87094.1463c-1.65871-6.4805-5.51368-11.02542-10.00816-11.02542-6.00841,0-10.8791,8.118-10.8791,18.13187s4.87073,18.13187,10.8791,18.13187a6.56119,6.56119,0,0,0,.87093-.14629c1.65871,6.4805,5.51369,11.02541,10.00817,11.02541,3.3831,0,6.36764-2.62795,8.36294-6.66444,1.75876,6.06971,5.45374,10.29077,9.7689,10.29077,6.00841,0,10.8791-8.118,10.8791-18.13187S842.79244,315.60813,836.784,315.60813Z"
                    fill="#3f3d56" transform="translate(-168.04134 -267.89732)"/>
                <path
                    d="M718.72328,451.807l-67.92039-11.01653c-3.42269-.55515-6.90789-1.11141-10.34147-.6282s-6.87069,2.1737-8.62107,5.16688a8.651,8.651,0,0,0,9.14985,12.853c-3.70741-.12023-7.60411-.19978-10.894,1.51369s-5.61946,5.87559-4.01553,9.22024a8.27667,8.27667,0,0,0,1.91922,2.4289,17.60582,17.60582,0,0,0,18.52289,3.14128c-2.50047,3.58582-7.46212,4.11838-11.7541,4.94866s-9.25362,3.258-9.41312,7.62664c-.17922,4.90869,5.66264,7.51763,10.47189,8.51687A137.41687,137.41687,0,0,0,712.648,489.3171a30.98,30.98,0,0,0,7.737-3.95049,17.43266,17.43266,0,0,0-7.05356-30.96345"
                    fill="#3f3d56" transform="translate(-168.04134 -267.89732)"/>
                <path
                    d="M1011.89005,507.47917a137.41884,137.41884,0,0,0-51.17256-57.63676,30.97519,30.97519,0,0,0-7.80737-3.80966,17.43272,17.43272,0,0,0-20.50879,24.24615l-5.31525-2.74921Q943.09323,497.98314,959.1,528.43656c1.61312,3.06929,3.26318,6.18918,5.71292,8.64309s5.86648,4.18514,9.31075,3.78525a8.6006,8.6006,0,0,0,6.77916-12.2999,16.64264,16.64264,0,0,0,5.752,5.05979c3.34648,1.59972,8.07321.9603,9.7823-2.33177a8.27455,8.27455,0,0,0,.78809-2.99368,17.60592,17.60592,0,0,0-8.62117-16.69248c4.36853-.1565,7.77622,3.48909,11.01912,6.42052,3.24327,2.93143,8.1652,5.43808,11.75289,2.94008C1015.40712,518.16062,1013.98161,511.92354,1011.89005,507.47917Z"
                    fill="#3f3d56" transform="translate(-168.04134 -267.89732)"/>
                <polygon
                    fill="#cacaca"
                    points="55.757 0 506 0 506 147.807 488.945 147.807 488.945 186.463 450.289 147.807 55.757 147.807 55.757 0"/>
                <rect fill="#fff" height="118.24561" width="425.22943" x="68.26381" y="14.39335"/>
                <rect fill="#33abb8" height="6.21326" width="204.73707" x="102.45877" y="48.91591"/>
                <rect fill="#33abb8" height="6.21326" width="356.83952" x="102.45877" y="70.40954"/>
                <rect fill="#33abb8" height="6.21326" width="356.49009" x="102.45877" y="91.90316"/>
                <path d="M1030.95866,632.10268h-381a1,1,0,0,1,0-2h381a1,1,0,0,1,0,2Z"
                      fill="#cacaca" transform="translate(-168.04134 -267.89732)"/>
            </svg>
        </div>
    </div>
</ng-container>
