import {NgModule} from '@angular/core';

import {AppRoutingModule, routes} from './app-routing.module';
import {AppComponent} from './app.component';
import {CoreModule} from '@core/core.module';
import {BrowserModule, BrowserTransferStateModule, TransferState} from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {RouterModule} from '@angular/router';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {LocalizeParser, LocalizeRouterModule, LocalizeRouterSettings} from '@gilsdav/ngx-translate-router';
import {localizeBrowserLoaderFactory} from '@core/utils/translations/localize-browser.loader';
import {Location} from '@angular/common';
import {HotToastModule} from '@ngneat/hot-toast';
import {ServiceWorkerModule} from '@angular/service-worker';
import {translateBrowserLoaderFactory} from '@core/utils/translations/translate-browser.loader';

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule.withServerTransition({appId: 'serverApp'}),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    BrowserTransferStateModule,
    // Old
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    // TranslateModule.forRoot({
    //   defaultLanguage: 'en',
    //   loader: {
    //     provide: TranslateLoader,
    //     useFactory: (createTranslateLoader),
    //     deps: [HttpClient],
    //   },
    // }),
    LocalizeRouterModule.forRoot(routes, {
      parser: {
        provide: LocalizeParser,
        useFactory: localizeBrowserLoaderFactory,
        deps: [TranslateService, Location, LocalizeRouterSettings, HttpClient, TransferState],
      },
      initialNavigation: true,
    }),
    HotToastModule.forRoot({
      position: 'top-right',
    }),
    ServiceWorkerModule.register('ngsw-worker.js', {
      // enabled: environment.production,
      enabled: false,
      registrationStrategy: 'registerWhenStable:15000',
    }),
    CoreModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule {
}
