import {Component, HostListener, Inject, OnInit} from '@angular/core';
import {PresentationalDialogue} from '@core/@data/Dialogue/Presentational';
import {DaySlot, Slot} from '@core/@data/schedule';
import {AuthService} from '@core/@http/services/auth.service';
import {GeneralService} from '@core/@http/services/general.service';
import {SessionsService} from '@core/@http/services/sessions.service';
import {TeacherService} from '@core/@http/services/teacher.service';
import {ToasterService} from '@shared/services/toastr.service';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-presentational-dialogue',
  templateUrl: './sessions-dialogue.component.html',
  styleUrls: [
    '../../../../../styles/components/dialogues.scss',
    './sessions-dialogue.component.scss',
  ],
})
export class SessionsDialogueComponent implements OnInit {
  loading = true;
  slots: DaySlot[] = [];
  availableSlots: Slot[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PresentationalDialogue<any>,
    private teacherService: TeacherService,
    private authService: AuthService,
    private sessionService: SessionsService,
    private toasterService: ToasterService,
    private generalService: GeneralService,
    private mdDialogRef: MatDialogRef<SessionsDialogueComponent>,
  ) {
  }

  ngOnInit(): void {
    this.teacherService.GetTeacherSchedule(this.data?.data?.username).pipe(
      map((res) => {
        return res?.data?.schedule;
      }), catchError(err => {
        this.toasterService.showFailTranslated('toaster.fails.something_wrong');
        this.loading = false;
        this.close(false);
        return throwError(err);
      })).subscribe((res) => {
      this.slots = res as DaySlot[];
      this.loading = false;
    });
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  @HostListener('keydown.esc') onEsc(): void {
    this.close(false);
  }

  trackBy(index: any, item: Slot): number {
    return item.id;
  }

  slotClick(slot: Slot): void {
    this.loading = true;
    this.sessionService.ReScheduleSession(this.data?.data.type, this.data.data?.sessionId, slot.id).subscribe((res) => {
      this.toasterService.showSuccess(res.message);
      this.mdDialogRef.close(true);
    }, (error) => {
      if (error.error?.data?.errors) {
        error.error.data.errors?.forEach((err: string) => {
          this.toasterService.showFail(err);
        });
      } else {
        this.toasterService.showFail(error?.error?.message);
      }
      this.loading = false;
    });
  }

  bookMoreSessions(): void {
    this.close(true);
    this.generalService.navigateToWithParamsWithWindow('/teacher-details', {
      queryParams: {
        user: this.data.data.username,
        course: 'no-course',
      },
      fragment: 'schedule',
    });
  }

  getExtraData(): object {
    return {
      queryParams: {
        user: this.data.data.username,
        course: 'no-course',
      },
      fragment: 'schedule',
    }
  }
}
