import {BaseLoginProvider, createSocialUser, GoogleInitOptions, SocialUser} from '@libs/social-login';
import {EventEmitter} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {filter, skip, take} from 'rxjs/operators';


const defaultInitOptions: GoogleInitOptions = {
  oneTapEnabled: true,
  scopes: 'profile',
  prompt: 'select_account'
};

export class GoogleLoginProvider extends BaseLoginProvider {
  public static readonly PROVIDER_ID: string = 'GOOGLE';
  // @ts-ignore
  public readonly changeUser = new EventEmitter<SocialUser | null>();
  private readonly socialUser$ = new BehaviorSubject<SocialUser | null>(null);
  private readonly accessToken$ = new BehaviorSubject<string | null>(null);
  private readonly receivedAccessToken = new EventEmitter<string>();
  private tokenClient: google.accounts.oauth2.TokenClient | undefined;

  constructor(
    private clientId: string,
    private readonly initOptions: GoogleInitOptions
  ) {
    super();
    this.initOptions = {...defaultInitOptions, ...this.initOptions};
    // emit changeUser events but skip initial value from behaviorSubject
    this.socialUser$.pipe(skip(1)).subscribe(this.changeUser);
    // emit receivedAccessToken but skip initial value from behaviorSubject
    // @ts-ignore
    this.accessToken$.pipe(skip(1)).subscribe(this.receivedAccessToken);
  }

  initialize(autoLogin?: boolean): Promise<void> {
    return new Promise((resolve, reject) => {
      try {
        this.loadScript(
          GoogleLoginProvider.PROVIDER_ID,
          'https://accounts.google.com/gsi/client',
          () => {
            google.accounts.id.initialize({
              client_id: this.clientId,
              auto_select: autoLogin,
              callback: ({credential}) => {
                const socialUser = createSocialUser(credential);
                this.socialUser$.next(socialUser);
              },
              prompt_parent_id: this.initOptions.prompt_parent_id,
              itp_support: this.initOptions.oneTapEnabled ?? false
            });
            if (this.initOptions.oneTapEnabled) {
              this.socialUser$
                .pipe(filter((user) => user === null))
                // tslint:disable-next-line:no-console
                .subscribe(() => google.accounts.id.prompt(console.debug));
            }
            if (this.initOptions.scopes) {
              const scope =
                this.initOptions.scopes instanceof Array
                  ? this.initOptions.scopes.filter((s) => s).join(' ')
                  : this.initOptions.scopes;
              this.tokenClient = google.accounts.oauth2.initTokenClient({
                client_id: this.clientId,
                scope,
                prompt: this.initOptions.prompt,
                callback: (tokenResponse) => {
                  if (tokenResponse.error) {
                    this.accessToken$.error({
                      code: tokenResponse.error,
                      description: tokenResponse.error_description,
                      uri: tokenResponse.error_uri,
                    });
                  } else {
                    this.accessToken$.next(tokenResponse.access_token);
                  }
                },
              });
            }

            resolve();
          }
        );
      } catch (err) {
        reject(err);
      }
    });
  }

  getLoginStatus(): Promise<SocialUser> {
    return new Promise((resolve, reject) => {
      if (this.socialUser$.value) {
        resolve(this.socialUser$.value);
      } else {
        reject(
          `No user is currently logged in with ${GoogleLoginProvider.PROVIDER_ID}`
        );
      }
    });
  }

  refreshToken(): Promise<SocialUser | null> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      google.accounts.id.revoke(this.socialUser$.value.id, (response) => {
        if (response.error) {
          reject(response.error);
        } else {
          resolve(this.socialUser$.value);
        }
      });
    });
  }

  getAccessToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      if (!this.tokenClient) {
        if (this.socialUser$.value) {
          reject(
            'No token client was instantiated, you should specify some scopes.'
          );
        } else {
          reject('You should be logged-in first.');
        }
      } else {
        this.tokenClient.requestAccessToken({
          hint: this.socialUser$.value?.email,
        });
        this.receivedAccessToken.pipe(take(1)).subscribe((res) => {
          return resolve(res);
        });
      }
    });
  }

  revokeAccessToken(): Promise<void> {
    return new Promise((resolve, reject) => {
      if (!this.tokenClient) {
        reject(
          'No token client was instantiated, you should specify some scopes.'
        );
      } else if (!this.accessToken$.value) {
        reject('No access token to revoke');
      } else {
        google.accounts.oauth2.revoke(this.accessToken$.value, () => {
          this.accessToken$.next(null);
          resolve();
        });
      }
    });
  }

  signIn(): Promise<SocialUser> {
    return Promise.reject(
      'You should not call this method directly for Google, use "<asl-google-signin-button>" wrapper ' +
      'or generate the button yourself with "google.accounts.id.renderButton()" ' +
      '(https://developers.google.com/identity/gsi/web/guides/display-button#javascript)'
    );
  }

  async signOut(): Promise<void> {
    google.accounts.id.disableAutoSelect();
    this.socialUser$.next(null);
  }
}
