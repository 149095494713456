import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {NavigationExtras, Router} from '@angular/router';
import {ApiResponse} from '@core/@data/Api/Api';
import {ContactUSForm} from '@core/@data/General/ContactForm';
import {Country, TimeZone} from '@core/@data/General/GeneralData';
import {Package} from '@core/@data/Packages/Package';
import {Gateway} from '@core/@data/Payments/Gateway';
import {TranslateService} from '@ngx-translate/core';
import {LangChangeEvent} from '@ngx-translate/core/lib/translate.service';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment as env} from '../../../../environments/environment';
import {MiniToolBarItem} from '@core/@data/General/Navbar';
import {Languages} from '@core/components/app-main-navbar/data';
import {getUrlForRouting} from '@shared/functions/routing.utils';

@Injectable({
  providedIn: 'root',
})
export class GeneralService {
  currentLayout$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  selectLang: MiniToolBarItem = Languages[0];
  public currentLang: string = 'en';
  private currentLang$: BehaviorSubject<string>;

  constructor(
    private http: HttpClient,
    private router: Router,
    @Inject(PLATFORM_ID) private platformId: Object,
    private _adapter: DateAdapter<any>,
    private translateService: TranslateService) {
    this.currentLang$ = new BehaviorSubject(this.translateService.currentLang);
    this.currentLang = this.translateService.currentLang;
    this.translateService.onLangChange.subscribe(($event: LangChangeEvent) => {
      this.currentLang$.next($event.lang);
    });
    this.selectLang = this.translateService.currentLang === 'en' ? Languages[1] : Languages[0];
  }

  getCurrentLang(): Observable<string> {
    return this.currentLang$.asObservable();
  }

  getAllCountries(): Observable<ApiResponse<Country[]>> {
    return this.http.get<ApiResponse<Country[]>>(`${env.ApiUrl}/system/countries`);
  }

  TeacherRegister(data: object): Observable<any> {
    return this.http.post<any>(`${env.ApiUrl}/teachers/form`, data);
  }

  GetTimeZones(): Observable<ApiResponse<TimeZone[]>> {
    return this.http.get<ApiResponse<TimeZone[]>>(`${env.ApiUrl}/system/timezones`);
  }

  ContactUsForm(data: ContactUSForm): Observable<any> {
    return this.http.post(`${env.ApiUrl}/contact-us`, data);
  }

  GetPaymentGateWays(): Observable<ApiResponse<Gateway[]>> {
    return this.http.get<ApiResponse<Gateway[]>>(`${env.ApiUrl}/payments/gateways`);
  }

  ContactUS(body: object): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/contact-us`, body);
  }

  BookACall(body: object): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/book-call`, body);
  }

  getCoursePackages(id: number): Observable<ApiResponse<Package[]>> {
    return this.http.get<ApiResponse<Package[]>>(`${env.ApiUrl}/payments/packages?course_id=${id}`);
  }

  getCoursePackagesAuthed(id: number): Observable<ApiResponse<Package[]>> {
    return this.http.get<ApiResponse<Package[]>>(`${env.ApiUrl}/student/payments/packages?course_id=${id}`);
  }

  navigateToHome(): void {
    this.router.navigate([`/${this.translateService.currentLang}`]).then();
  }

  navigateToWithParams(route: string, extra?: NavigationExtras): void {
    this.router.navigate([`/${this.translateService.currentLang + route}`], extra).then();
  }

  navigateToWithParamsWithWindow(route: string, extra?: NavigationExtras): void {
    window.location.href = getUrlForRouting(route, this.currentLang, extra);
  }

  navigateToWithParamsWithReturn(route: string, extra?: NavigationExtras): Promise<boolean> {
    return this.router.navigate([`/${this.translateService.currentLang + route}`], extra);
  }

  scrollToTop(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.scrollTo(0, 0);
    }
  }

  reloadPage(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.reload();
    }
  }

  getTranslation(key: string): string {
    return this.translateService.instant(key) ?? '';
  }

  ChangeCalendarLanguage(lang: string): void {
    this._adapter.setLocale(lang);
  }

  setLayout(isTest: boolean = false): void {
    this.currentLayout$.next(isTest);
  }

  getLayout(): Observable<boolean> {
    return this.currentLayout$.asObservable();
  }

  selectLanguage(lang: string): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.replace(`${lang}/${this.router.url.slice(4)}`);
    }
  }
}// End of Class
