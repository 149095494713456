import {isPlatformBrowser} from '@angular/common';
import {HttpClient, HttpEventType, HttpRequest, HttpResponse} from '@angular/common/http';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import {Page} from '@core/@data/Api/Pagination';
import {ChannelData, Message, MessageObject} from '@core/@data/chat';
import {ChatService} from '@core/@http/services/chat.service';
import {WebsocketService} from '@core/@http/services/websocket.service';
import {FileTypes} from '@shared/data/constants';
import {ChatMainService} from '@shared/modules/chat/new-chat-ui/services/chat-main.service';
import {ToasterService} from '@shared/services/toastr.service';
import {interval, Observable, Subject, Subscription} from 'rxjs';
import {delay, filter, take, takeUntil} from 'rxjs/operators';
import {environment} from '../../../../../../../environments/environment';

@Component({
  selector: 'app-chat-body',
  templateUrl: './chat-body.component.html',
  styleUrls: ['./chat-body.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChatBodyComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('msgBody') msgBody!: ElementRef;
  @ViewChild('scrollMe', {static: false, read: ElementRef}) scrollMe!: ElementRef<any>;
  @Input() ActiveChannel: ChannelData = {} as ChannelData;
  loadMoreLoading = false;
  Messages: Message[] = [];
  loading = true;
  pages = 20;
  page = 1;
  isLast = false;
  isNext = false;
  sendMsgLoading = false;
  sendFileLoading = false;
  formData: FormData = new FormData();
  FileTypes = FileTypes;
  @ViewChild('file', {static: false}) file: any;
  progress$!: Observable<number>;
  fileName = '';
  cancel$: Subject<any> = new Subject<any>();
  subscriptions: Subscription[] = [];

  ///////////////////////////////////////
  constructor(
    private chatService: ChatService,
    private websocketService: WebsocketService,
    public chatMainService: ChatMainService,
    @Inject(PLATFORM_ID) private platformId: Object,
    private cd: ChangeDetectorRef,
    private toasterService: ToasterService,
    private http: HttpClient,
  ) {
  }

  ngOnInit(): void {
    this.chatMainService.HandleReadMessage(this.ActiveChannel.token);
    this.ActiveChannel.unread_messages_count = 0;
    this.chatService
      .GetChannelMessages(this.chatMainService.currentUser.type, this.ActiveChannel.token, 1)
      .subscribe((data) => {
        this.Messages = data.data;
        if (data.pagination) {
          this.setPaginationAttributes(data.pagination.meta.page);
        }
        this.loading = false;
        this.scrollToBottom$();
      });
    this.subscriptions.push(this.chatMainService.NewMessage$.pipe(filter(
      (msg) => {
        return msg?.channel?.token === this.ActiveChannel?.token;
      })).subscribe((message) => {
      this.Messages.push(message);
      if (message.sender.id !== this.chatMainService.currentUser.id && message.sender.type !== this.chatMainService.currentUser.type) {
        this.Messages.forEach((msg) => {
          msg.is_read = true;
        });
      }
      this.subscriptions.push(this.chatService.ReadMessage(this.chatMainService.currentUser.type, this.ActiveChannel.token)
        .pipe(delay(1000)).subscribe(
          (res) => {
            this.chatMainService.HandleReadMessage(this.ActiveChannel.token);
            this.ActiveChannel.unread_messages_count = 0;
          }));
      this.scrollToBottom$();
    }));
  }

  setPaginationAttributes(page: Page): void {
    this.isLast = page.isLast;
    this.isNext = page.isNext;
    this.page = page.next;
    this.pages = page.last;
  }

  DownloadFile(url: string, name: string): void {
    if (isPlatformBrowser(this.platformId)) {
      const link = document.createElement('a');
      link.setAttribute('target', '_blank');
      link.setAttribute('download', name);
      link.href = url;
      document.body.appendChild(link);
      link.click();
      window.URL.revokeObjectURL(url);
      link.remove();
    }
  }

  sendTextMessage(): void {
    if (this.msgBody.nativeElement.value) {
      this.sendMsgLoading = true;
      const data: MessageObject = {
        message: this.msgBody.nativeElement.value,
        type: 'text',
      };
      this.SendMessage(data);
    }
    this.msgBody.nativeElement.value = '';
    return;
  }

  SendMessage(data: MessageObject | FormData): void {
    this.chatService.SendMessage(this.chatMainService.currentUser.type, this.ActiveChannel.token, data).subscribe({
      next: (result) => {
        this.sendMsgLoading = false;
        this.sendFileLoading = false;
      },
      error: (error) => {
        this.sendMsgLoading = false;
        this.sendFileLoading = false;
        error.error.data.errors.forEach((err: string) => {
          this.toasterService.showFail(err);
        });
      },
    });
  }

  trackBy(index: number, item: Message): number {
    return item.id;
  }

  scrollToBottom$(): void {
    interval(500).pipe(take(1)).subscribe((res) => {
      if (this.scrollMe) {
        this.scrollMe.nativeElement.scroll({
          top: this.scrollMe.nativeElement.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }
    });
  }

  ngAfterViewInit(): any {
    this.scrollToBottom$();
  }

  loadMore(): void {
    if (!this.isLast && this.isNext) {
      this.loadMoreLoading = true;
      this.chatService.GetChannelMessages(this.chatMainService.currentUser.type, this.ActiveChannel.token, this.page)
        .subscribe((data) => {
          this.Messages.unshift(...data.data);
          this.loadMoreLoading = false;
          if (data.pagination) {
            this.setPaginationAttributes(data.pagination.meta.page);
          }
        });
    }
  }

  sendGeneralFile(): any {
    if (this.file.nativeElement.files.length > 0) {
      const files: { [key: string]: File } = this.file.nativeElement.files;
      this.fileName = files[0].name;
      this.sendFileLoading = true;
      this.scrollToBottom$();
      this.progress$ = this.upload(files[0]).pipe(takeUntil(this.cancel$));
    }
  }

  upload(file: File): Observable<number> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);
    formData.append('type', 'file');
    const URL = `${environment.ApiUrl}/${this.chatMainService.currentUser.type}/chat/channels/${this.ActiveChannel.token}/messages`;
    const req = new HttpRequest('POST', URL, formData, {
      reportProgress: true,
    });
    const progress = new Subject<number>();
    this.http.request(req).pipe(takeUntil(this.cancel$)).subscribe((event: any) => {
      if (event.type === HttpEventType.UploadProgress) {
        progress.next(Math.round((100 * event.loaded) / event.total));
      } else if (event instanceof HttpResponse) {
        progress.complete();
        this.sendFileLoading = false;
      }
    }, (error) => {
      this.sendMsgLoading = false;
      this.sendFileLoading = false;
      progress.complete();
      error.error.data.errors.forEach((err: string) => {
        this.toasterService.showFail(err);
      });
    });
    return progress.asObservable();
  }

  cancel(): void {
    this.cancel$.next('');
    this.cancel$.complete();
    this.sendFileLoading = false;
  }

  onKeydown(event: Event): void {
    this.sendTextMessage();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((sub) => {
      sub.unsubscribe();
    });
  }
}
