export const DefaultScheduleRange = '15-20';

export function getMonth(): number {
  const today = new Date();
  return today.getMonth() + 1;
}

export function calculateDateInRange(range: `${number}-${number}`): boolean {
  const [startDay, endDay] = range.split('-').map(Number);
  const today = new Date();
  const currentMonth = today.getMonth() + 1;
  const currentDate = today.getDate();
  return currentMonth === getMonth() &&
    currentDate >= startDay &&
    currentDate <= endDay;
}
