<nav aria-label="breadcrumb">
  <ol class="breadcrumb mb-0">
    <li>
      <a (click)="back()" *ngIf="isHaveBackButton" class="mx-1 breadcrumb__back">
        <svg fill="#fcb040" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
          <path d="M0 0h24v24H0z" fill="none"/>
          <path d="M21 11H6.83l3.58-3.59L9 6l-6 6 6 6 1.41-1.41L6.83 13H21z"/>
        </svg>
      </a>
    </li>
    <li class="breadcrumb-item">
      <a [routerLink]="['/' | localize]" class="mx-1">{{ 'home' | translate }}</a></li>
    <div *ngIf="Routes!.length > 0">
      <ng-container *ngFor="let route of Routes;let last=last">
        <span class="mx-1 divider">></span>
        <li [class.active]="last" aria-current="page" class="breadcrumb-item ">
          <!--  IS HAS QUERY PARAMS      -->
          <a *ngIf="route?.isHasQueryParam && route?.queryParam"
             [fragment]="route?.fragment"
             [queryParams]="route?.queryParam"
             [routerLink]="[route.route | localize]"
             class="mx-1">{{ route.name }}</a>
          <!--  IS NOT HAS QUERY PARAMS      -->
          <a *ngIf="!route?.isHasQueryParam && !route?.queryParam" [fragment]="route?.fragment"
             [routerLink]="[route.route | localize]"
             class="mx-1">{{ route.name }}</a>
        </li>
      </ng-container>
    </div>
  </ol>
</nav>
