import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {MenuLink} from '@core/@data/General/Navbar';
import {AuthService} from '@core/@http/services/auth.service';
import {GeneralService} from '@core/@http/services/general.service';

@Component({
  selector: 'app-side-nav-auth',
  templateUrl: './side-nav-auth.component.html',
  styleUrls: ['./side-nav-auth.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SideNavAuthComponent implements OnInit {
  @Input() userName!: string;
  @Input() userImage!: string;
  @Input() credit!: number;
  StudentMenu: MenuLink[] = [
    {route: '/teachers', title: 'Teachers', icon: 'teachersIcon', key: 'routes.our_teachers'},
    {route: '/courses', title: 'Our Courses', icon: 'ourCoursesIcon', key: 'routes.our_courses'},
    {route: '/groups/courses', title: 'Our Groups', icon: 'ourCoursesIcon', key: 'routes.our_groups'},
    {route: '/cefr', icon: 'cefrIcon', title: 'CEFR', key: 'routes.what_cefr'},
    {route: '/my-account/my-courses', icon: 'myCoursesIcon', title: 'My Courses', key: 'routes.my_courses'},
    {route: '/my-account/my-active-group-courses', title: 'My Groups', icon: 'groups', key: 'routes.my_group_courses'},
    {route: '/my-schedule', title: 'My Schedule', icon: 'mySchedule', key: 'routes.my_schedule'},
    {route: '/messages', icon: 'messagesIcon', title: 'My Messages', key: 'routes.my_messages'},
    {route: '/my-account/notifications', icon: 'notificationIcon', title: 'Notifications', key: 'routes.notifications'},
    {route: '/my-credit', icon: 'myTransactionsIcon', title: 'My Transactions', key: 'routes.my_transactions'},
    {
      route: '/my-account/my-courses-history',
      icon: 'allCoursesIcon',
      title: 'Courses History',
      key: 'routes.my_courses_history'
    },
    {
      route: '/my-account/my-group-courses-history',
      title: 'Course Groups History',
      icon: 'allCoursesIcon',
      key: 'routes.my_group_courses_history',
    },
    {route: '/my-learning', icon: 'myLearningIcon', title: 'My Learning', key: 'routes.my_learning'},
    {
      route: '/check-placement-test',
      icon: 'mySchedule',
      title: 'Check Placement Club',
      key: 'routes.check_placement_test'
    },
    {route: '/conversation-club', icon: 'allCoursesIcon', title: 'Conversation Club', key: 'routes.conversation_club'},
    {route: '/features/change-log', icon: 'allCoursesIcon', title: 'Site Updates', key: 'routes.change_log'},
    {route: '/setting', icon: 'settingIcon', title: 'Setting', key: 'routes.setting'},
  ];
  TeacherMenu: MenuLink[] = [
    {route: '/teacher/my-account/my-courses', icon: 'myCoursesIcon', title: 'My Courses', key: 'routes.my_courses'},
    {route: '/teacher/my-account/my-courses-groups', icon: 'groups', title: 'My Groups', key: 'routes.my_groups'},
    {route: '/teacher/my-calendar', icon: 'myCalendarIcon', title: 'My Calendar', key: 'routes.my_calendar'},
    {route: '/teacher/my-students', icon: 'myStudentsIcon', title: 'My Students', key: 'routes.my_students'},
    {route: '/messages', icon: 'messagesIcon', title: 'My Messages', key: 'routes.my_messages'},
    {
      route: '/teacher/my-account/notifications',
      icon: 'notificationIcon',
      title: 'Notifications',
      key: 'routes.notifications'
    },
    {route: '/teacher/my-account/all-courses', icon: 'allCoursesIcon', title: 'All Courses', key: 'routes.all_courses'},
    {
      route: '/teacher/conversation-club',
      icon: 'allCoursesIcon',
      title: 'Conversation Club',
      key: 'routes.conversation_club'
    },
    {route: '/features/change-log', icon: 'allCoursesIcon', title: 'Site Updates', key: 'routes.change_log'},
    {
      route: '/teacher/my-account/all-courses-groups',
      icon: 'allCoursesIcon',
      title: 'All Group Courses',
      key: 'routes.all_courses_groups',
    },
    {
      route: '/teacher/create-group',
      icon: 'allCoursesIcon',
      title: 'All Group Courses',
      key: 'routes.create_group',
    },
    {
      route: '/teacher/unevaluated-tests',
      icon: 'testEvaluation',
      title: 'Test Evaluation',
      key: 'routes.test_evaluation'
    },
    {route: '/teacher/profile', icon: 'profileIcon', title: 'Profile', key: 'routes.profile'},
    {route: '/teacher/setting', icon: 'settingIcon', title: 'Setting', key: 'routes.setting'},
  ];

  constructor(private authService: AuthService, public generalService: GeneralService) {
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.authService.Logout();
  }
}
