import {Component, OnInit} from '@angular/core';
import {Seo} from '@core/@data/General/seo';
import {GeneralService} from '@core/@http/services/general.service';
import {SeoService} from '@core/@http/services/seo.service';
import {StudentAuthService} from '@core/@http/services/Student/student-auth.service';
import {ToasterService} from '@shared/services/toastr.service';

@Component({
    selector: 'app-request-reset-password',
    templateUrl: './request-verify-email.component.html',
    styleUrls: ['./request-verify-email.component.scss'],
})
export class RequestVerifyEmailComponent implements OnInit {
    loading = false;

    constructor(
        private toasterService: ToasterService,
        private seoService: SeoService,
        private studentAuthService: StudentAuthService,
        private generalService: GeneralService,
    ) {
        const seo: Seo = {
            title: 'OTO Courses | Verify Email',
            description: 'Get Started With OTO Courses, English Teachers, English Teachers, English Assessment, OTO Account',
            keywords: 'English Teachers, English Teachers, English Assessment, OTO Account',
        };
        this.seoService.setSeo(seo);
    }

    ngOnInit(): void {

    }

    sendEmail(): void {
        this.loading = true;
        this.studentAuthService.RequestVerifyEmail().subscribe(_ => {
                this.toasterService.showSuccessTranslated('toaster.check_mail', 5000);
                this.loading = false;
                this.generalService.navigateToWithParams('/user/validate-email');
            },
            (error) => {
                this.loading = false;
                error.error.data.errors.forEach((err: string) => {
                    this.toasterService.showFail(err);
                });
            });
    }
} // END OF CLASS
