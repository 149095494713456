import {Component, OnInit} from '@angular/core';
import {ChannelData} from '@core/@data/chat';
import {ChatView} from '@shared/modules/chat/new-chat-ui/models/types';
import {ChatMainService} from '@shared/modules/chat/new-chat-ui/services/chat-main.service';

@Component({
  selector: 'app-chat-floating-button',
  templateUrl: './chat-floating-button.component.html',
  styleUrls: ['./chat-floating-button.component.scss'],
})
export class ChatFloatingButtonComponent implements OnInit {
  isShow = false;
  isOpened = false;
  currentView: ChatView = 'Loading';
  SelectedChannel: ChannelData = {} as ChannelData;

  constructor(public chatMainService: ChatMainService) {
  }

  ngOnInit(): void {
    this.chatMainService.Status$.subscribe((status) => {
      if (status === 'Finished') {
        this.currentView = 'Channels';
      }
    });
    // this.chatMainService.unReadMessageBehaviorSubject$.subscribe((res) => {
    // console.log('res', res);
    // });
  }

  toggleShow() {
    this.isShow = !this.isShow;
  }

  toggleOpen() {
    this.isOpened = !this.isOpened;
  }

  changeView(view: ChatView) {
    this.currentView = view;
  }

  ChannelSelected($event: ChannelData) {
    this.SelectedChannel = $event;
    this.currentView = 'Messages';
  }
}
