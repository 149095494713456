<ng-container *ngIf='loading'>
  <app-chat-loader></app-chat-loader>
</ng-container>
<ng-container *ngIf='!loading'>
  <section class='chat-area'>
    <div class='chat-title'>
      {{ActiveChannel.chat_name}}
    </div>
    <div class='messages-container-wrapper'>
      <div #scrollMe class='messages-container'>
        <ng-container *ngIf='page <= pages && !isLast && isNext'>
          <div class='text-center'>
            <button (click)='loadMore()'
                    [class.cursorDisabled]='loadMoreLoading'
                    [class.spinner-small]='loadMoreLoading'
                    [disabled]='loadMoreLoading'
                    [inViewportOptions]='{ threshold: 0 }'
                    class='load__more__btn'
                    inViewport
                    type='button'>
              {{'actions.load_more'| translate}}
            </button>
          </div>
        </ng-container>
        <ng-container *ngFor='let message of Messages;trackBy:trackBy'>
          <div
            [class.reverse]='message.is_sender || (message.sender.id === chatMainService.currentUser.id &&  message.sender.type === chatMainService.currentUser.type)'
            class='message-wrapper'>
            <div class='profile-picture'>
              <img [alt]='message.sender.name'
                   [defaultImageOnError]="'/assets/images/man-avatar.svg'"
                   [src]="message.sender.image | imgDefault:'/assets/images/man-avatar.svg'">
            </div>
            <div class='message-content'>
              <p class='name'>{{message.sender.name}}</p>
              <div *ngIf="message.type === 'text' && message.message.length > 0" class='message'>
                {{message.message}}
              </div>
              <div *ngIf="message.type === 'file'" class='message-file'>
                <a [href]='message.file.url' class='icon sketch' rel='noreferrer' target='_blank'>
                  <svg class='file-icon'>
                    <use xlink:href='#file-icon' xmlns:xlink='http://www.w3.org/1999/xlink'></use>
                  </svg>
                </a>
                <div class='file-info'>
                  <a [href]='message.file.url' class='file-name' rel='noreferrer'
                     target='_blank'>{{message?.file?.name}}</a>
                  <div class='file-size'>{{message?.file?.size}}</div>
                </div>
                <button (click)='DownloadFile(message.file.url,message.file.name)'
                        class='download-icon'>
                </button>
              </div>
              <div class='message-footer'>
                <ng-container
                  *ngIf='message.is_sender || (message.sender.id === chatMainService.currentUser.id &&  message.sender.type === chatMainService.currentUser.type)'>
                  <div class='is-reading'>
                    <svg *ngIf='message.is_read' fill='#8db759' height='24' viewBox='0 0 24 24'
                         width='24'
                         xmlns='http://www.w3.org/2000/svg'>
                      <path
                        d='M0 0h24v24H0z' fill='none' />
                      <path
                        d='M18 7l-1.41-1.41-6.34 6.34 1.41 1.41L18 7zm4.24-1.41L11.66 16.17 7.48 12l-1.41 1.41L11.66 19l12-12-1.42-1.41zM.41 13.41L6 19l1.41-1.41L1.83 12 .41 13.41z' />
                    </svg>
                  </div>
                </ng-container>
                <div class='message-timing'>
                  <div class='date'>{{message.date.day}}</div>
                  <div class='time'>{{message.date.time}}</div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
        <div *ngIf='sendFileLoading' class='files-uploader-wrapper'>
          <div *ngIf='progress$ | async ; let progress' class='files-uploader'>
            <div class='file'>
              <div class='file-header'>
                <p class='file-uploader-name'>{{fileName}}</p>
                <button (click)='cancel()' *ngIf='progress > 5' aria-label='close dialogue'
                        color='primary' mat-icon-button>
                  <svg fill='#32aab7' height='24' viewBox='0 0 24 24' width='24'
                       xmlns='http://www.w3.org/2000/svg'>
                    <path d='M0 0h24v24H0z' fill='none' />
                    <path
                      d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z' />
                  </svg>
                </button>
              </div>
              <mat-progress-bar [value]='progress' mode='determinate'></mat-progress-bar>
              <p class='file-uploader-size'>{{progress}} / 100</p>
            </div>
          </div>
        </div>
      </div>
      <div class='chat-actions w-100'>
        <section class='chat-typing-area-wrapper'>
          <div class='chat-typing-area'>
                        <textarea #msgBody
                                  (keydown.enter)='onKeydown($event)'
                                  [placeholder]="'sentences.type_your_message' | translate"
                                  cdkAutosizeMaxRows='2'
                                  cdkAutosizeMinRows='2'
                                  cdkTextareaAutosize
                                  class='chat-input'
                                  matInput></textarea>
            <div class='actions'>
              <!--      Send File     -->
              <input
                #file
                (input)='sendGeneralFile()'
                [accept]='FileTypes'
                class='d-none'
                type='file'
              />
              <button
                (click)='file!.click()'
                [class.cursorDisabled]='sendFileLoading'
                [class.spinner-small]='sendFileLoading'
                [disabled]='sendFileLoading'
                class='send-button'>
                <svg class='feather feather-send' fill='#fff' height='20' width='20'
                     xmlns='http://www.w3.org/2000/svg'>
                  <path
                    d='M9.5 18q-1.917 0-3.208-1.365Q5 15.271 5 13.375V5.271q0-1.354.948-2.313Q6.896 2 8.25 2q1.396 0 2.323 1.01.927 1.011.927 2.386v7.625q0 .833-.594 1.406T9.5 15q-.875 0-1.438-.625-.562-.625-.562-1.458V5H9v8.021q0 .208.146.354t.354.146q.208 0 .354-.146t.146-.354v-7.75q0-.729-.51-1.25Q8.979 3.5 8.25 3.5t-1.24.531q-.51.531-.51 1.281v8.209q0 1.25.885 2.114.886.865 2.115.865 1.292 0 2.146-.917.854-.916.854-2.166V5H14v8.521q0 1.875-1.302 3.177T9.5 18Z' />
                </svg>
              </button>
              <button
                (click)='sendTextMessage()'
                [class.cursorDisabled]='sendMsgLoading'
                [class.spinner-small]='sendMsgLoading'
                [disabled]='sendMsgLoading'
                class='send-button'>
                <svg fill='none' stroke='currentColor' stroke-linecap='round'
                     stroke-linejoin='round' stroke-width='2' viewBox='0 0 24 24'
                     xmlns='http://www.w3.org/2000/svg'>
                  <path d='M22 2L11 13M22 2l-7 20-4-9-9-4 20-7z' />
                </svg>
              </button>
            </div>
          </div>
        </section>

      </div>
    </div>
  </section>
</ng-container>

<svg display='none' xmlns='http://www.w3.org/2000/svg'>
  <symbol clip-rule='evenodd' fill-rule='evenodd' id='file-icon'
          image-rendering='optimizeQuality' shape-rendering='geometricPrecision' text-rendering='geometricPrecision'
          viewBox='0 0 441 512.02' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M324.87 279.77c32.01 0 61.01 13.01 82.03 34.02 21.09 21 34.1 50.05 34.1 82.1 0 32.06-13.01 61.11-34.02 82.11l-1.32 1.22c-20.92 20.29-49.41 32.8-80.79 32.8-32.06 0-61.1-13.01-82.1-34.02-21.01-21-34.02-50.05-34.02-82.11s13.01-61.1 34.02-82.1c21-21.01 50.04-34.02 82.1-34.02zM243.11 38.08v54.18c.99 12.93 5.5 23.09 13.42 29.85 8.2 7.01 20.46 10.94 36.69 11.23l37.92-.04-88.03-95.22zm91.21 120.49l-41.3-.04c-22.49-.35-40.21-6.4-52.9-17.24-13.23-11.31-20.68-27.35-22.19-47.23l-.11-1.74V25.29H62.87c-10.34 0-19.75 4.23-26.55 11.03-6.8 6.8-11.03 16.21-11.03 26.55v336.49c0 10.3 4.25 19.71 11.06 26.52 6.8 6.8 16.22 11.05 26.52 11.05h119.41c2.54 8.79 5.87 17.25 9.92 25.29H62.87c-17.28 0-33.02-7.08-44.41-18.46C7.08 432.37 0 416.64 0 399.36V62.87c0-17.26 7.08-32.98 18.45-44.36C29.89 7.08 45.61 0 62.87 0h173.88c4.11 0 7.76 1.96 10.07 5l109.39 118.34c2.24 2.43 3.34 5.49 3.34 8.55l.03 119.72c-8.18-1.97-16.62-3.25-25.26-3.79v-89.25zm-229.76 54.49c-6.98 0-12.64-5.66-12.64-12.64 0-6.99 5.66-12.65 12.64-12.65h150.49c6.98 0 12.65 5.66 12.65 12.65 0 6.98-5.67 12.64-12.65 12.64H104.56zm0 72.3c-6.98 0-12.64-5.66-12.64-12.65 0-6.98 5.66-12.64 12.64-12.64h142.52c3.71 0 7.05 1.6 9.37 4.15a149.03 149.03 0 0 0-30.54 21.14H104.56zm0 72.3c-6.98 0-12.64-5.66-12.64-12.65 0-6.98 5.66-12.64 12.64-12.64h86.2c-3.82 8.05-6.95 16.51-9.29 25.29h-76.91zm264.81 47.03c3.56-.14 6.09-1.33 7.54-3.55 3.98-5.94-1.44-11.81-5.19-15.94l-40.04-40.71c-4.32-4.26-9.32-4.31-13.64 0l-41.01 41.82c-3.51 3.96-7.86 9.36-4.19 14.83 1.49 2.22 4 3.41 7.56 3.55h19.74v30.69c0 5.84 4.81 10.69 10.7 10.69h28.06c5.9 0 10.71-4.81 10.71-10.69v-30.69h19.76z' />
  </symbol>
</svg>
