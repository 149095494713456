import {Pipe, PipeTransform} from '@angular/core';
import {isValidURL} from '@shared/functions/checks';

@Pipe({
  name: 'checkUrl'
})
export class CheckUrlPipe implements PipeTransform {

  transform(value: string | undefined | unknown): boolean {
    if (value) {
      return isValidURL(value + '');
    }
    return false;
  }

}
