import {Pipe, PipeTransform} from '@angular/core';
import {DatePipe} from '@angular/common';
import {StudentPackageDataSource} from '@core/@data/New/NEW-Student';
import {Session} from '@core/@data/CoursesData/Session';

@Pipe({
  name: 'appGetCalendarUrlPipe',
  pure: true
})
export class GetCalendarUrlPipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {
  }

  transform(courseName: string, pkg: StudentPackageDataSource, session: Session): string {
    return this.generateCalendarLink(pkg, session, 'google');
  }

  convertDate(inputDate: string): string {
    // Parse the input date string into a JavaScript Date object
    const dateObj = new Date(inputDate);

    // Format the Date object to the desired output format
    const formattedDate = this.datePipe.transform(dateObj, 'yyyyMMdd\'T\'HHmmss\'Z\'');

    return formattedDate || '';
  }

  generateCalendarLink(pkg: StudentPackageDataSource, session: Session, platform: 'google' | 'outlook' | 'apple' | 'yahoo'): string {
    const eventTitle = `${pkg.course.name} session with ${pkg.teacher.name}`;
    const encodedTitle = encodeURIComponent(eventTitle);
    // const encodedDescription = encodeURIComponent(`Session will starts at ${session.date_user.starts_at} and ends at ${session.date_user.ends_at} ==> user timezone and  starts at ${session.date_utc.starts_at} and ends at ${session.date_utc.ends_at} ==> system timezone`);
    const encodedDescription = encodeURIComponent(`OTO Courses <b>${pkg.course.name}</b> session with <b>${pkg.teacher.name}</b> on <b>${session.date.split(' ')[0]}</b>, at <b>${session.date.split(' ')[1]}</b>.\nIn order to start your session please login to your account and click on 'Start Live' at the time of the session. \n<a href="https://otocourses.com/" target="_blank" >https://otocourses.com/</a>`);


    const encodedLocation = encodeURIComponent('Online Meeting');
    const startDate = this.convertDate(session.date_utc.starts_at + '');
    const endDate = this.convertDate(session.date_utc.ends_at + '');
    switch (platform) {
      case 'google': {
        return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedTitle}&dates=${startDate}/${endDate}&details=${encodedDescription}&location=${encodedLocation}`;
      }
      case 'outlook': {
        return `https://outlook.live.com/owa/?path=/calendar/action/compose&startdt=${startDate}&enddt=${endDate}&subject=${encodedTitle}&body=${encodedDescription}&location=${encodedLocation}`;
      }
      case 'yahoo': {
        return `https://calendar.yahoo.com/?v=60&view=d&type=20&title=${encodedTitle}&st=${startDate}&et=${endDate}&desc=${encodedDescription}&in_loc=${encodedLocation}`;
      }
      case 'apple': {
        return `webcal://p33-caldav.icloud.com/published/2/YourUniqueID.ics?start=${startDate}&end=${endDate}&title=${encodedTitle}&description=${encodedDescription}&location=${encodedLocation}`;
      }
      default: {
        return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodedTitle}&dates=${startDate}/${endDate}&details=${encodedDescription}&location=${encodedLocation}`;
      }
    }
  }
}
