import {decodeJwt, SocialUser} from '@libs/social-login';

export function createSocialUser(idToken: string): SocialUser {
  const user = new SocialUser();
  user.idToken = idToken;
  const payload = decodeJwt(idToken);
  user.id = payload?.sub ?? '';
  user.name = payload?.name ?? '';
  user.email = payload?.email ?? '';
  user.photoUrl = payload?.picture ?? '';
  user.firstName = payload['given_name'] ?? '';
  user.lastName = payload['family_name'] ?? '';
  return user;
}
