import {DatePipe} from '@angular/common';
import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateAdapter} from '@angular/material/core';
import {MatCalendarCellClassFunction} from '@angular/material/datepicker';
import {DaySlot} from '@core/@data/schedule';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-mat-calendar-demo',
    templateUrl: './mat-calendar-demo.component.html',
    styleUrls: ['./mat-calendar-demo.component.scss'],
})
export class MatCalendarSessionsComponent implements OnInit {
    minDate = new Date();
    selectedDate!: any;
    @Input() datesArray!: DaySlot[];
    @Output() readonly dateSelected: EventEmitter<any> = new EventEmitter();

    constructor(private datePipe: DatePipe, private _adapter: DateAdapter<any>, private translateService: TranslateService) {
        if (this.translateService.currentLang === 'ar') {
            this._adapter.setLocale('ar');
        }
    }

    ngOnInit(): void {
    }

    dateClass: MatCalendarCellClassFunction<Date> = (cellDate, view) => {
        // Only highlight dates inside the month view.
        if (view === 'month') {
            // @ts-ignore
            if (Object.keys(this.datesArray).includes(this.datePipe.transform(cellDate, 'yyyy-MM-dd'))) {
                return 'example-custom-date-class';
            }
            return '';
        }
        return '';
    };
    myDateFilter = (d: Date): any => {
        // @ts-ignore
        if (Object.keys(this.datesArray).includes(this.datePipe.transform(d, 'yyyy-MM-dd'))) {
            return true;
        }
        return false;
    };

    onSelect(event: any): any {
        this.selectedDate = this.datePipe.transform(event ?? new Date(), 'yyyy-MM-dd');
        for (const [key, values] of Object.entries(this.datesArray)) {
            if (this.selectedDate === key) {
                this.dateSelected.emit(values);
                break;
            }
        }
    }
}

