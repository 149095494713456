import {AnswerContentType} from '@core/@data/Exams/PlacementExam';

// export function getAnswerType(url: string): AnswerContentType {
//   // Regular expression to check if the input is HTML code
//   const htmlRegex = /<[a-z][\s\S]*>/i;
//
//   // Regular expression to check if the input is a URL
//   const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
//   if (htmlRegex.test(url)) {
//     return 'html';
//   } else if (urlRegex.test(url)) {
//     if (isValidURL(url)) {
//       if (url.startsWith('http') && /\.(jpg|png|gif|svg)$/.test(url)) {
//         return 'image';
//       } else if (url.startsWith('http') && /\.(mp3|ogg|wav)$/.test(url)) {
//         return 'audio';
//       } else if (url.startsWith('http') && /\.(mp4|webm)$/.test(url)) {
//         return 'video';
//       } else {
//         return 'url';
//       }
//     } else {
//       return 'paragraph';
//     }
//   } else {
//     return 'paragraph';
//   }
// }
//
// export function isValidURL(str: string): boolean {
//   const res = str.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g);
//   return (res !== null);
// }

export function getAnswerType(url: string): AnswerContentType {
  const isHTML = isHTMLContent(url);
  const isURL = isValidURL(url);

  if (isHTML) {
    return 'html';
  } else if (isURL) {
    if (url.startsWith('http') && /\.(jpg|png|gif|svg)$/.test(url)) {
      return 'image';
    } else if (url.startsWith('http') && /\.(mp3|ogg|wav)$/.test(url)) {
      return 'audio';
    } else if (url.startsWith('http') && /\.(mp4|webm)$/.test(url)) {
      return 'video';
    } else {
      return 'url';
    }
  } else {
    return 'paragraph';
  }
}

export function isValidURL(str: string): boolean {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(str);
}

export function isHTMLContent(str: string): boolean {
  const htmlRegex = /<[a-z][\s\S]*>/i;
  return htmlRegex.test(str);
}
