import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {environment as env} from '../../../../environments/environment';
import {ApiResponse, ApiResponseWithPagination} from '../../@data/Api/Api';
import {ScheduleOld} from '../../@data/schedule';
import {Teacher} from '../../@data/UsersData/Teacher';
import {LocalstorageService} from '@core/@http/services/localstorage.service';

export interface TeachersFilter {
  page?: number;
  search: string;
  date: any;
  searchCourse: any;
  free_time_from: any;
  free_time_to: any;
}

@Injectable({
  providedIn: 'root',
})
export class TeacherService {
  EndPoint = '';

  constructor(
    private http: HttpClient,
    private localstorageService: LocalstorageService,
  ) {
    if (this.localstorageService.state$.getValue()?.r === 's') {
      this.EndPoint = `${env.ApiUrl}/student/teachers`;
    } else {
      this.EndPoint = `${env.ApiUrl}/teachers`;
    }
  }

  GetTeacher(teacherName: string): Observable<ApiResponse<Teacher>> {
    return this.http.get<ApiResponse<Teacher>>(`${this.EndPoint}/${teacherName}`);
  }

  GetTeachersSearch(filter: string): Observable<ApiResponseWithPagination<Teacher[]>> {
    return this.http.get<ApiResponseWithPagination<Teacher[]>>
    (`${this.EndPoint}/search?${filter}`);
  }

  GetTeacherSchedule(teacherName: string): Observable<ApiResponse<ScheduleOld>> {
    return this.http.get<ApiResponse<ScheduleOld>>(`${this.EndPoint}/${teacherName}/schedule`);
  }

  //
  // TeachersSearchByDay(page: number, days: any): Observable<ApiResponse<Teacher[]>> {
  //   return this.http.get<ApiResponse<Teacher[]>>(`${env.ApiUrl}/teachers/search?page=${page}&free_days[]=${days}`);
  // }
  //
  // GetTeacherCourses(teacherName: string): Observable<Course[]> {
  //   return this.http.get<Course[]>(`${env.ApiUrl}/teachers/${teacherName}/courses`);
  // }
  //
  //
  // GetTeacherScheduleNew(teacherName: string): Observable<ApiResponse<Schedule>> {
  //   return this.http.get<ApiResponse<Schedule>>(`${env.ApiUrl}/teachers/${teacherName}/schedule/raw`);
  // }
  //
  // GetAllTeachers(page: number): Observable<ApiResponse<Teacher[]>> {
  //   return this.http.get<ApiResponse<Teacher[]>>(`${env.ApiUrl}/teachers?page=${page}`);
  // }
  //
  // TeachersSearch(filter: TeachersFilter): Observable<ApiResponseWithPagination<Teacher[]>> {
  //   return this.http.get<ApiResponseWithPagination<Teacher[]>>
  //   (`${env.ApiUrl}/teachers/search?page=${filter.page}
  //                                       &keyword=${filter.search}
  //                                       &free_days[]=${filter.date}
  //                                       &course_id=${filter.searchCourse}
  //                                       &free_time_from=${filter.free_time_from}
  //                                       &free_time_to=${filter.free_time_to}
  //                                       `);
  // }

}// End of Class
