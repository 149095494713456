<div *ngIf="data.title" class="header">
    <h1 mat-dialog-title>{{ data!.title }}</h1>
</div>
<div *ngIf="data.isMessage" mat-dialog-content>
    <p [innerHTML]="data!.message" class="dialog-message"></p>
</div>
<div *ngIf="data.isList" mat-dialog-content>
    <p class="dialog-message">{{ data!.message }}</p>
    <mat-list *ngIf="data!.data" role="list">
        <ng-container *ngFor="let item of data!.data">
            <mat-list-item class="dialog-message" role="listitem">
                {{item}}
            </mat-list-item>
        </ng-container>
    </mat-list>
</div>
<section *ngIf="data.isData" class="data__section my-4">
    <mat-list *ngFor="let item of data!.data;let i = index" dense>
        <mat-list-item>
            <div class="d-flex justify-content-between align-items-center">
                <div>
                    <span class="mr-2" style="font-size: 1rem;">{{i + 1}}
                        ) {{'words.record' |translate}} {{i + 1}}</span>
                    <a
                        [href]="item"
                        color="primary"
                        mat-button
                        rel="noopener" target="_blank">{{'actions.open_in_new_window' |translate}}</a>
                </div>
                <div>
                    <button (click)="SetVideo(item)" *ngIf="!isSelectVideo" color="primary" mat-button>
                        {{'actions.open_in_this_tab'|translate}}
                    </button>
                    <button (click)="isSelectVideo = false; selectVideo=''" *ngIf="isSelectVideo" class="float-end"
                            color="primary"
                            mat-icon-button>
                        <svg fill="#FCB040" height="24" viewBox="0 0 24 24" width="24"
                             xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 0h24v24H0z" fill="none"/>
                            <path
                                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/>
                        </svg>
                    </button>
                </div>
            </div>
        </mat-list-item>
    </mat-list>
</section>
<section *ngIf="isSelectVideo" class="videoIframe">
    <iframe [src]="selectVideo | safeUrl" class="frame" id="companyFrame">
    </iframe>
</section>
<section *ngIf="data!.isActions" mat-dialog-actions>
    <div class="example-button-row">
        <button (click)="cancel()" class="our__button red__button">
            {{ data!.cancelText }}
        </button>
        <button (click)="confirm()" *ngIf="data!.confirmText && data!.confirmText !== ''"
                class="our__button blue__button">
            {{ data!.confirmText }}
        </button>
    </div>
</section>
