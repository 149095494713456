import {StartEnd} from '@core/@data/schedule';

export function addMinutes(date: Date, minutes: number): Date {
    return new Date(date.getTime() + minutes * 60000);
}

export function createTimeSlots(startTime: any, endTime: any, interval: number): StartEnd[] {
    const totalTime = mileSecToMinute((endTime?.getTime() - startTime?.getTime()));
    let totalSlotsCount = (totalTime / interval) - 1;
    let timeSlots: StartEnd[] = [
        {
            starts_at: startTime,
            ends_at: addMinutes(startTime, interval),
        },
    ];
    if (totalSlotsCount > 0) {
        for (let i = 1; i <= totalSlotsCount; i++) {
            timeSlots.push({
                starts_at: timeSlots[i - 1]?.ends_at,
                ends_at: addMinutes(timeSlots[i - 1]?.ends_at, interval),
            });
        }
    }
    return timeSlots;
}

export function mileSecToMinute(timeInMile: number): number {
    return Math.floor((timeInMile / 1000) / 60);
}

export function GetEventColor(value: string = ''): string {
    const courseName = value.split('-')[0].trim();
    switch (courseName) {
        case 'Basic English': {
            return '#ffa384';
        }
        case 'General English': {
            return '#54d2d2';
        }
        case 'Advanced Conversation English': {
            return '#3d5b59';
        }
        case 'Business English': {
            return '#ab3987';
        }
        case 'IELTS': {
            return '#2f3061';
        }
        case 'TOEFL': {
            return '#5c2b4c';
        }
        case 'Phonetics': {
            return '#000c66';
        }
        case 'English for kids': {
            return '#050a30';
        }
        case 'School English': {
            return '#0e86d4';
        }
        case 'Native English Conversation': {
            return '#2e0f38';
        }
        case 'Native General English': {
            return '#2e0f38';
        }
        case 'Native Business English': {
            return '#2e0f38';
        }
        case 'Native English for kids': {
            return '#2e0f38';
        }
        case 'Native School English': {
            return '#2e0f38';
        }
        case 'Native Test Preparation': {
            return '#2e0f38';
        }
        default: {
            return '#33abb8';
        }
    }
}

export function CalculateDifferenceBetweenTwoDates(date: string): boolean {
    const dateTime = new Date(date).getTime();
    const DiffInHours = (dateTime - new Date().getTime()) / (1000 * 3600);
    if (DiffInHours > 0 && DiffInHours < 8) {
        return true;
    }
    if (DiffInHours > 0 && DiffInHours > 8) {
        return false;
    }
    else {
        return false;
    }
}

export function getSafeDate(date: string): Date {
    return new Date(date.replace(/-/g, '/'));
}
