export class SocialUser {
  provider = '';
  id = '';
  email = '';
  name = '';
  photoUrl = '';
  firstName = '';
  lastName = '';
  authToken = '';
  idToken = ''; // Reference https://developers.google.com/identity/sign-in/web/backend-auth
  authorizationCode = ''; // Reference https://developers.google.com/identity/sign-in/web/reference#googleauthgrantofflineaccessoptions
  response: any;
}
