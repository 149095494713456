<section class="main">
  <div class="layout">
    <svg class="left__svg" fill="none" viewBox="0 0 1434 1573"
         xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd"
            d="M1160.91 782.241L723.241 344.569L285.569 782.241L723.241 1219.91L1160.91 782.241ZM1428.38 867.344C1475.38 820.343 1475.38 744.139 1428.38 697.138L808.344 77.1029C761.343 30.1019 685.139 30.1019 638.138 77.1029L18.1028 697.138C-28.8982 744.139 -28.8982 820.343 18.1028 867.344L638.138 1487.38C685.139 1534.38 761.342 1534.38 808.343 1487.38L1428.38 867.344Z"
            fill="#FDC066"
            fill-rule="evenodd"/>
    </svg>
    <svg class="right__svg" fill="none" viewBox="0 0 1434 1573"
         xmlns="http://www.w3.org/2000/svg">
      <path clip-rule="evenodd"
            d="M1160.91 782.241L723.241 344.569L285.569 782.241L723.241 1219.91L1160.91 782.241ZM1428.38 867.344C1475.38 820.343 1475.38 744.139 1428.38 697.138L808.344 77.1029C761.343 30.1019 685.139 30.1019 638.138 77.1029L18.1028 697.138C-28.8982 744.139 -28.8982 820.343 18.1028 867.344L638.138 1487.38C685.139 1534.38 761.342 1534.38 808.343 1487.38L1428.38 867.344Z"
            fill="#FDC066"
            fill-rule="evenodd"/>
    </svg>
  </div>
  <section class="content__body">
    <div class="container">
      <div class="breadcrumbs">
        <app-bread-crumbs
          [Routes]="[{name:'bread_crumbs.request_verification'|translate,route:'/user/validate-request'}]"></app-bread-crumbs>
      </div>
      <section class="auth__section row justify-content-center align-items-center align-content-center">
        <div class="col-12 col-md-7 col-lg-6 col-xl-5">
          <div class="auth__card">
            <a [routerLink]="'/' | localize" class="logo">
              <img alt="OTO English" src="/assets/images/header/OTOCourseslogo.svg"
                   width="75px">
            </a>
            <h1 class="card__title">{{'routes.password_reset'|translate}}</h1>
            <p class="auth__details">{{'forms.sentences.type_code'|translate}}</p>
            <div class="auth__form">
              <form (ngSubmit)="onSubmit()" [formGroup]="validateResetPasswordForm">
                <!--       Code        -->
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>{{'forms.labels.code' | translate}}</mat-label>
                  <input [placeholder]="'forms.labels.code' | translate" formControlName="code"
                         matInput/>
                  <mat-hint
                    *ngIf="validateResetPasswordForm.controls.code.errors?.required &&validateResetPasswordForm.controls.code.touched"
                    align="end">{{'forms.errors.field_required' | translate}}
                  </mat-hint>
                  <mat-hint *ngIf="validateResetPasswordForm.controls.code.errors?.pattern"
                            align="start">{{'forms.errors.code_format' | translate}}
                  </mat-hint>
                  <mat-hint *ngIf="validateResetPasswordForm.controls.code.errors?.minlength"
                            align="end">
                    {{'forms.errors.code_verification' | translate}}
                  </mat-hint>
                  <mat-hint *ngIf="validateResetPasswordForm.controls.code.errors?.maxlength"
                            align="end">
                    {{'forms.errors.code_verification' | translate}}
                  </mat-hint>
                </mat-form-field>
                <div class="submit-btn text-center m-auto">
                  <app-submit-button [form]="validateResetPasswordForm" [loading]="loading"
                                     [title]="'actions.check' | translate"></app-submit-button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  </section>
</section>
