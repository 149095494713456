import {Component, HostListener, Inject} from '@angular/core';
import {PresentationalDialogue} from '@core/@data/Dialogue/Presentational';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-presentational-dialogue',
  templateUrl: './presentational-dialogue.component.html',
  styleUrls: ['../../../../../styles/components/dialogues.scss']
})
export class PresentationalDialogueComponent {
  isSelectVideo = false;
  selectVideo: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PresentationalDialogue<any>,
    private mdDialogRef: MatDialogRef<PresentationalDialogueComponent>
  ) {
  }

  public cancel(): void {
    this.close(false);
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public confirm(): void {
    this.close(true);
  }

  @HostListener('keydown.esc') onEsc(): void {
    this.close(false);
  }

  @HostListener('keydown.enter') onEnter(): void {
    this.close(true);
  }

  SetVideo(url: string): any {
    this.isSelectVideo = true;
    this.selectVideo = url;
  }
}
