import {Injectable} from '@angular/core';
import {Meta, Title} from '@angular/platform-browser';
import {Seo} from '../../@data/General/seo';

@Injectable({
  providedIn: 'root'
})
export class SeoService {

  constructor(
    private meta: Meta,
    private title: Title,
  ) {
  }

  setSeo(seo: Seo): void {
    this.title.setTitle(seo.title);
    this.meta.addTag({
      name: 'keywords',
      content: seo.keywords,
    });
    this.meta.addTag({
      name: 'description',
      content: seo.description,
    });
  }

}
