import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'nameSplit'
})
export class NameSplitPipe implements PipeTransform {

  transform(value: string | undefined | null, delimiter: string | undefined, wordsCount: number | undefined): string {
    if (value === null && value) {
      return '';
    } else {
      const words: string[] = value?.split(delimiter as string) as string[];
      return words.splice(0, wordsCount).join(delimiter);
    }
  }
}
