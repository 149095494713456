import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {Seo} from '@core/@data/General/seo';
import {AuthService} from '@core/@http/services/auth.service';
import {GeneralService} from '@core/@http/services/general.service';
import {SeoService} from '@core/@http/services/seo.service';
import {ToasterService} from '@shared/services/toastr.service';

@Component({
    selector: 'app-validate-reset-password',
    templateUrl: './validate-reset-password.component.html',
    styleUrls: ['./validate-reset-password.component.scss'],
})
export class ValidateResetPasswordComponent implements OnInit {
    loading = false;
    validateResetPasswordForm!: UntypedFormGroup;
    email!: string;

    constructor(
        private toasterService: ToasterService,
        private route: ActivatedRoute,
        private fb: UntypedFormBuilder,
        private authService: AuthService,
        private seoService: SeoService,
        private generalService: GeneralService,
    ) {
        const seo: Seo = {
            title: 'OTO Courses | Request Verification',
            description: 'Get Started With OTO Courses, English Teachers, English Teachers, English Assessment, OTO Account',
            keywords: 'English Teachers, English Teachers, English Assessment, OTO Account',
        };
        this.seoService.setSeo(seo);
    }

    ngOnInit(): void {
        this.route.queryParams.subscribe((params) => {
            this.email = params?.email ?? '';
        });
        this.validateResetPasswordForm = this.fb.group({
            code: ['',
                [Validators.required,
                    Validators.minLength(6),
                    Validators.maxLength(6),
                    Validators.pattern('^[0-9]+(\\.?[0-9]+)?$'),
                ]],
        });
        this.validateResetPasswordForm.controls.code.valueChanges.subscribe((_) => {
            this.validateResetPasswordForm.markAllAsTouched();
        });
    }


    onSubmit(): void {
        this.loading = true;
        const code = this.validateResetPasswordForm.controls.code.value;
        this.authService.ValidateResetPassword(this.email, code).subscribe(res => {
                this.toasterService.showSuccessTranslated('toaster.success.valid_code', 5000);
                this.loading = false;
                this.generalService.navigateToWithParams('/user/reset-password',
                    {
                        queryParams: {
                            code,
                            email: this.email,
                        },
                        skipLocationChange: true,
                    });
            },
            (_) => {
                this.loading = false;
                this.toasterService.showFailTranslated('toaster.fails.invalid_code');
            });
    }
} // END OF CLASS
