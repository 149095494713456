import {isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnInit, PLATFORM_ID, ViewChild} from '@angular/core';
import {MatSidenav} from '@angular/material/sidenav';
import {Router} from '@angular/router';

import {GeneralService} from '@app/@core/@http/services/general.service';
import {MenuLink, RouteLink} from '@core/@data/General/Navbar';
import {GuestMenu, NormalLinks} from "@core/components/app-main-navbar/data";
import {AuthService} from "@core/@http/services/auth.service";
import {TranslateService} from '@ngx-translate/core';

@Component({
  selector: 'app-guest-nav-bar',
  templateUrl: './guest-nav-bar.component.html',
  styleUrls: ['./guest-nav-bar.component.scss'],
})
export class GuestNavBarComponent implements OnInit {
  @ViewChild(MatSidenav) sidenav!: MatSidenav;
  GuestMenu: MenuLink[] = GuestMenu;
  NormalLinks: RouteLink[] = NormalLinks;
  lang = {key: 'en', value: 'EN'};

  constructor(
    public authService: AuthService,
    private router: Router,
    public generalService: GeneralService,
    public translateService: TranslateService,
    @Inject(PLATFORM_ID) private platformId: Object) {
    this.generalService.getCurrentLang().subscribe((lang) => {
      if (lang == 'en') {
        this.lang = {key: 'ar', value: 'ع'};
      } else {
        this.lang = {key: 'en', value: 'EN'};
      }
    });
  }

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      this.sidenav?.close().then();
    });
  }

  changeLang(): void {
    if (isPlatformBrowser(this.platformId)) {
      window.location.replace(`${this.lang.key}/${this.router.url.slice(4)}`);
    }
  }
}
