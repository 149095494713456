import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'checkExisting',
})
export class CheckExistingPipe implements PipeTransform {

    transform(value: unknown): boolean {
        return !!(value && value !== 'null');
    }

}
