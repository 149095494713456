import {Directive, Input, TemplateRef, ViewContainerRef} from '@angular/core';
import {AuthService} from '@core/@http/services/auth.service';


@Directive({
  selector: '[appRole]'
})
export class RoleDirective {
  private hasView = false;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    public authService: AuthService
  ) {
  }

  @Input() set appRole(role: string) {
    this.authService.userDataBehaviorSubject.subscribe((isAuthenticated) => {
      if (isAuthenticated !== null && !this.hasView) {
        if (isAuthenticated.type !== '' && isAuthenticated.type === role) {
          this.viewContainer.createEmbeddedView(this.templateRef);
          this.hasView = true;
        } else {
          this.viewContainer.clear();
          this.hasView = false;
        }
      } else if (this.hasView) {
        this.viewContainer.clear();
        this.hasView = false;
      }
    });
  }
}
