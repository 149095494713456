import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Observable} from 'rxjs';

import {environment as env} from '../../../../../environments/environment';
import {ApiResponse} from '@core/@data/Api/Api';
import {StudentInsights} from '@core/@data/CoursesData/StudentInsights';
import {Teacher} from '@core/@data/UsersData/Teacher';
import {LastestExamsContainer} from '@core/@data/Exams/Exam';
import {LangSectionLevel} from '@core/@data/Exams/LangSectionLevel';

@Injectable({
  providedIn: 'root'
})
export class StudentAuthService {

  constructor(private http: HttpClient) {
  }

  GetStudentInsights(): Observable<StudentInsights> {
    return this.http.get<StudentInsights>(`${env.ApiUrl}/student/insights`);
  }

  GetStudentExams(): Observable<ApiResponse<LastestExamsContainer>> {
    return this.http.get<ApiResponse<LastestExamsContainer>>(`${env.ApiUrl}/student/exams/old/placement`);
  }

  GetStudentTeachers(page: number = 1): Observable<ApiResponse<Teacher[]>> {
    return this.http.get<ApiResponse<Teacher[]>>(`${env.ApiUrl}/student/teachers?page=${page}`);
  }

  RequestVerifyEmail(): Observable<ApiResponse<any>> {
    return this.http.get<ApiResponse<any>>(`${env.ApiUrl}/student/account/email/validate`);
  }

  ValidateEmail(code: number): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/student/account/email/validate`, {validation_code: code});
  }

  UploadMedia(formData: FormData): Observable<ApiResponse<{ path: string }>> {
    return this.http.post<ApiResponse<{ path: string }>>(`${env.ApiUrl}/student/media/upload`, formData);
  }

  GetLanguageSectionsLevels(): Observable<ApiResponse<LangSectionLevel[]>> {
    return this.http.get<ApiResponse<LangSectionLevel[]>>(`${env.ApiUrl}/student/languages/sections/levels`);
  }

  GetStudentGeneralLevel(): Observable<ApiResponse<{ general: string; sub_level: string; }>> {
    return this.http.get<ApiResponse<{ general: string; sub_level: string; }>>(`${env.ApiUrl}/student/languages/sections/general-level`);
  }
}// End of Class
