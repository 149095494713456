import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {StudentProfile} from '@core/@data/UsersData/StudentProfile';
import {Teacher} from '@core/@data/UsersData/Teacher';
import {GeneralService} from '@core/@http/services/general.service';
import {LocalstorageService} from '@core/@http/services/localstorage.service';
import {environment} from '../../../../../environments/environment';

@Injectable()
export class AutomaticAuthService {

  constructor(
    private localStorageService: LocalstorageService,
    private http: HttpClient,
    private generalService: GeneralService,
  ) {
  }


  AutoMaticLogin(type: string | null, token: string | null): void {
    this.localStorageService.ClearStorage();
    if (type === 'teacher') {
      this.localStorageService.setState('token', token);
      this.localStorageService.setState('isAuth', 'true');
      this.localStorageService.setState('r', 't');
      this.http.get<{ data: Teacher }>(`${environment.ApiUrl}/teacher/account`)
        .subscribe((data) => {
          this.localStorageService.setState('d', data.data.account.id);
          this.localStorageService.setState('fn', data.data.account.first_name);
          this.localStorageService.setState('ln', data.data.account.last_name);
          this.localStorageService.setState('sg', data.data.account.created_at);
          this.localStorageService.setState('mb', data.data.account.mobile);
          this.localStorageService.setState('e', data.data.account.email);
          this.localStorageService.setState('st', data.data.account.status);
          this.localStorageService.setState('tz', data.data.address.country.timezone);
          this.localStorageService.setState('hmac', data.data.account.intercom_hmac);
          this.generalService.navigateToWithParamsWithReturn('/teacher/my-account').then(() => {
            this.generalService.reloadPage();
          });
        }, (error) => {
          this.localStorageService.ClearStorage();
          this.generalService.navigateToHome();
        });
    }
    if (type === 'student') {
      this.localStorageService.setState('token', token);
      this.localStorageService.setState('isAuth', 'true');
      this.localStorageService.setState('r', 's');
      this.http.get<{ data: StudentProfile }>(`${environment.ApiUrl}/student/account`)
        .subscribe((data) => {
          this.localStorageService.setState('d', data.data.account.id);
          this.localStorageService.setState('fn', data.data.account.first_name);
          this.localStorageService.setState('ln', data.data.account.last_name);
          this.localStorageService.setState('sg', data.data.account.created_at);
          this.localStorageService.setState('e', data.data.account.email);
          this.localStorageService.setState('st', data.data.account.status);
          this.localStorageService.setState('rc', data.data.account.referral_code);
          this.localStorageService.setState('lv', data.data.account.level.main);
          this.localStorageService.setState('mb', data.data.account.mobile);
          this.localStorageService.setState('tz', data.data.address.country.timezone);
          this.localStorageService.setState('hmac', data.data.account.intercom_hmac);
          this.localStorageService.setState('gid', data.data.account?.group?.id);
          this.localStorageService.setState('gname', data.data.account?.group?.name);
          this.localStorageService.setState('hpt', data.data.transactions?.has_previous_transactions);
          this.localStorageService.setState('lpa', data.data.transactions?.last_payment_amount);
          this.localStorageService.setState('lpd', data.data.transactions?.last_payment_date);
          this.localStorageService.setState('tsp', data.data.transactions?.total_sessions_purchased);
          this.localStorageService.setState('tta', data.data.transactions?.total_transactions_amount);
          this.localStorageService.setState('ttc', data.data.transactions?.total_transactions_count);
          this.localStorageService.setState('is_v', data.data.account.email_verified_at === null ? 'false' : 'true');
          this.generalService.navigateToWithParamsWithReturn('/my-account').then(() => {
            this.generalService.reloadPage();
          });
        }, (error) => {
          this.localStorageService.ClearStorage();
          this.generalService.navigateToHome();
        });
    }
  }
}
