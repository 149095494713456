<button (click)="toggleOpen()" class="floating-btn">
    <svg class="feather feather-message-square" fill="#ffffff" height="24" stroke="#ffffff" stroke-linecap="round"
         stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24" width="24"
         xmlns="http://www.w3.org/2000/svg">
        <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
    </svg>
    <ng-container *ngIf="(chatMainService.unReadMessageBehaviorSubject$ | async);let value">
        <span *ngIf="value > 0">{{value}}</span>
    </ng-container>
</button>

<div
    [class.closed]="!isOpened"
    [class.opened]="isOpened" class="app-container">
    <div [class.show]="isShow" class="right-side">
        <div class="chat-container">
            <header
                [class.justify-content-between]="currentView === 'Messages'"
                [class.justify-content-end]="currentView !== 'Messages'"
                class="chat-header d-flex  align-items-center w-100">
                <button (click)="changeView('Channels')" *ngIf="currentView === 'Messages'" class="chat-header-button">
                    < Back
                </button>
                <button (click)="toggleOpen()" class="chat-header-button">
                    Close X
                </button>
            </header>
            <main class="chat-body-main">
                <ng-container *ngIf="currentView === 'Channels'">
                    <app-chat-channels (channelSelected)="ChannelSelected($event)"></app-chat-channels>
                </ng-container>
                <ng-container *ngIf="currentView === 'Messages'">
                    <app-chat-body [ActiveChannel]="SelectedChannel"></app-chat-body>
                </ng-container>
                <ng-container *ngIf="currentView === 'Loading'">
                    <div class="d-flex align-items-center justify-content-center w-100 h-100">
                        <h2 class="text-center m-auto">Loading...</h2>
                    </div>
                </ng-container>
            </main>
        </div>
    </div>
</div>
