<content-loader
  *ngIf="type === 'sidebar'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 300 615"
>
  <svg:rect height="1" rx="0" ry="0" width="0" x="79" y="20"/>
  <svg:rect height="400" rx="0" ry="0" width="3" x="4" y="1"/>
  <svg:rect height="3" rx="0" ry="0" width="294" x="4" y="400"/>
  <svg:rect height="3" rx="0" ry="0" width="5" x="158" y="400"/>
  <svg:rect height="3" rx="0" ry="0" width="294" x="5" y="1"/>
  <svg:rect height="400" rx="0" ry="0" width="3" x="296" y="1"/>
  <svg:rect height="3" rx="0" ry="0" width="294" x="5" y="60"/>
  <svg:rect height="23" rx="0" ry="0" width="129" x="22" y="20"/>
  <svg:rect height="9" rx="4" ry="4" width="81" x="35" y="76"/>
  <svg:rect height="18" rx="4" ry="4" width="18" x="271" y="22"/>
  <svg:rect height="9" rx="4" ry="4" width="81" x="186" y="76"/>
  <svg:rect height="44" rx="0" ry="0" width="2" x="150" y="63"/>
  <svg:rect height="3" rx="0" ry="0" width="144" x="6" y="104"/>
  <svg:rect height="1" rx="0" ry="0" width="145" x="152" y="106"/>
  <svg:rect height="31" rx="4" ry="4" width="243" x="28" y="127"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="62" y="188"/>
  <svg:circle cx="39" cy="197" r="10"/>
  <svg:circle cx="39" cy="247" r="10"/>
  <svg:circle cx="39" cy="297" r="10"/>
  <svg:circle cx="39" cy="347" r="10"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="64" y="237"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="65" y="287"/>
  <svg:rect height="19" rx="4" ry="4" width="148" x="64" y="337"/>
</content-loader>
<content-loader
  *ngIf="type === 'teacher-card'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 700 260"
>
  <svg:rect height="240" rx="3" ry="3" width="3" x="0" y="2"/>
  <svg:rect height="3" rx="3" ry="3" width="650" x="0" y="240"/>
  <svg:rect height="244" rx="3" ry="3" width="3" x="648" y="0"/>
  <svg:rect height="3" rx="3" ry="3" width="650" x="0" y="0"/>
  <svg:rect height="32" rx="14" ry="14" width="72" x="480" y="200"/>
  <svg:rect height="32" rx="14" ry="14" width="72" x="560" y="200"/>
  <svg:circle cx="75" cy="75" r="50"/>
  <svg:rect height="7" rx="3" ry="3" width="102" x="150" y="40"/>
  <svg:rect height="7" rx="3" ry="3" width="102" x="150" y="60"/>
  <svg:rect height="6" rx="3" ry="3" width="178" x="150" y="80"/>
  <svg:rect height="6" rx="3" ry="3" width="178" x="150" y="100"/>
  <svg:rect height="29" rx="3" ry="3" width="608" x="25" y="150"/>
</content-loader>
<content-loader
  *ngIf="type === 'profile-page'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 650 390"
>
  <svg:rect height="380" rx="3" ry="3" width="3" x="0" y="2"/>
  <svg:rect height="3" rx="3" ry="3" width="650" x="0" y="380"/>
  <svg:rect height="383" rx="3" ry="3" width="3" x="648" y="0"/>
  <svg:rect height="3" rx="3" ry="3" width="650" x="0" y="0"/>

  <svg:rect height="29" rx="3" ry="3" width="75" x="2" y="2"/>
  <svg:rect height="29" rx="3" ry="3" width="75" x="575" y="2"/>

  <svg:circle cx="250" cy="75" r="50"/>
  <svg:rect height="7" rx="3" ry="3" width="85" x="150" y="135"/>
  <svg:rect height="7" rx="3" ry="3" width="85" x="275" y="135"/>
  <svg:rect height="7" rx="3" ry="3" width="85" x="400" y="135"/>

  <svg:rect height="7" rx="3" ry="3" width="102" x="325" y="40"/>
  <svg:rect height="7" rx="3" ry="3" width="102" x="325" y="60"/>
  <svg:rect height="6" rx="3" ry="3" width="178" x="325" y="80"/>
  <svg:rect height="6" rx="3" ry="3" width="178" x="325" y="100"/>

  <svg:rect height="29" rx="3" ry="3" width="185" x="25" y="155"/>
  <svg:rect height="29" rx="3" ry="3" width="185" x="236" y="155"/>
  <svg:rect height="29" rx="3" ry="3" width="185" x="447" y="155"/>


  <svg:circle cx="32" cy="205" r="10"/>
  <svg:rect height="10" rx="6" ry="6" width="587" x="46" y="201"/>
  <svg:circle cx="32" cy="230" r="10"/>
  <svg:rect height="10" rx="6" ry="6" width="587" x="46" y="226"/>
  <svg:circle cx="32" cy="255" r="10"/>
  <svg:rect height="10" rx="6" ry="6" width="587" x="46" y="250"/>
  <svg:circle cx="32" cy="280" r="10"/>
  <svg:rect height="10" rx="6" ry="6" width="587" x="46" y="275"/>


  <svg:rect height="7" rx="3" ry="3" width="608" x="25" y="300"/>
  <svg:rect height="7" rx="3" ry="3" width="608" x="25" y="320"/>
  <svg:rect height="6" rx="3" ry="3" width="608" x="25" y="340"/>
  <svg:rect height="6" rx="3" ry="3" width="608" x="25" y="360"/>

</content-loader>
<content-loader
  *ngIf="type === 'table'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 1000 550">
  <svg:rect height="17" rx="3" ry="3" width="906" x="51" y="45"/>
  <svg:circle cx="879" cy="123" r="11"/>
  <svg:circle cx="914" cy="123" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="104" y="115"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="305" y="114"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="661" y="114"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="55" y="155"/>
  <svg:circle cx="880" cy="184" r="11"/>
  <svg:circle cx="915" cy="184" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="105" y="176"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="306" y="175"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="662" y="175"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="56" y="216"/>
  <svg:circle cx="881" cy="242" r="11"/>
  <svg:circle cx="916" cy="242" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="106" y="234"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="307" y="233"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="663" y="233"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="57" y="274"/>
  <svg:circle cx="882" cy="303" r="11"/>
  <svg:circle cx="917" cy="303" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="107" y="295"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="308" y="294"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="664" y="294"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="58" y="335"/>
  <svg:circle cx="881" cy="363" r="11"/>
  <svg:circle cx="916" cy="363" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="106" y="355"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="307" y="354"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="663" y="354"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="57" y="395"/>
  <svg:circle cx="882" cy="424" r="11"/>
  <svg:circle cx="917" cy="424" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="107" y="416"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="308" y="415"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="664" y="415"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="55" y="453"/>
  <svg:rect height="465" rx="3" ry="3" width="2" x="51" y="49"/>
  <svg:rect height="465" rx="3" ry="3" width="2" x="955" y="49"/>
  <svg:circle cx="882" cy="484" r="11"/>
  <svg:circle cx="917" cy="484" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="107" y="476"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="308" y="475"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="664" y="475"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="55" y="513"/>
  <svg:rect height="17" rx="3" ry="3" width="906" x="52" y="80"/>
  <svg:rect height="33" rx="3" ry="3" width="68" x="53" y="57"/>
  <svg:rect height="33" rx="3" ry="3" width="149" x="222" y="54"/>
  <svg:rect height="33" rx="3" ry="3" width="137" x="544" y="55"/>
  <svg:rect height="33" rx="3" ry="3" width="72" x="782" y="56"/>
  <svg:rect height="33" rx="3" ry="3" width="24" x="933" y="54"/>
</content-loader>
<content-loader
  *ngIf="type === 'small-table'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 1000 350">
  <svg:rect height="17" rx="3" ry="3" width="906" x="51" y="45"/>
  <svg:circle cx="879" cy="123" r="11"/>
  <svg:circle cx="914" cy="123" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="104" y="115"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="305" y="114"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="661" y="114"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="55" y="155"/>
  <svg:circle cx="880" cy="184" r="11"/>
  <svg:circle cx="915" cy="184" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="105" y="176"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="306" y="175"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="662" y="175"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="56" y="216"/>
  <svg:circle cx="881" cy="242" r="11"/>
  <svg:circle cx="916" cy="242" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="106" y="234"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="307" y="233"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="663" y="233"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="57" y="274"/>
  <svg:circle cx="882" cy="303" r="11"/>
  <svg:circle cx="917" cy="303" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="107" y="295"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="308" y="294"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="664" y="294"/>
  <svg:rect height="2" rx="3" ry="3" width="905" x="52" y="335"/>
  <svg:rect height="288" rx="3" ry="3" width="2" x="51" y="49"/>
  <svg:rect height="288" rx="3" ry="3" width="2" x="955" y="49"/>
  <svg:rect height="17" rx="3" ry="3" width="906" x="52" y="80"/>
  <svg:rect height="33" rx="3" ry="3" width="68" x="53" y="57"/>
  <svg:rect height="33" rx="3" ry="3" width="149" x="222" y="54"/>
  <svg:rect height="33" rx="3" ry="3" width="137" x="544" y="55"/>
  <svg:rect height="33" rx="3" ry="3" width="72" x="782" y="56"/>
  <svg:rect height="33" rx="3" ry="3" width="24" x="933" y="54"/>
</content-loader>
<content-loader
  *ngIf="type === 'general-stats'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 1000 180">
  <svg:rect height="2" rx="3" ry="3" width="905" x="52" y="25"/>
  <svg:rect height="150" rx="3" ry="3" width="2" x="51" y="25"/>
  <svg:rect height="150" rx="3" ry="3" width="2" x="955" y="25"/>
  <svg:circle cx="879" cy="60" r="11"/>
  <svg:circle cx="914" cy="60" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="104" y="50"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="305" y="50"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="661" y="50"/>
  <svg:rect height="2" rx="3" ry="3" width="897" x="55" y="100"/>
  <svg:circle cx="880" cy="135" r="11"/>
  <svg:circle cx="915" cy="135" r="11"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="105" y="125"/>
  <svg:rect height="15" rx="3" ry="3" width="299" x="306" y="125"/>
  <svg:rect height="15" rx="3" ry="3" width="141" x="662" y="125"/>
  <svg:rect height="2" rx="3" ry="3" width="905" x="51" y="175"/>
</content-loader>

<content-loader
  *ngIf="type === 'teachers-cards'"
  [rtl]="false"
  [speed]="speed"
  backgroundColor="#cfcfcf"
  foregroundColor="#ecebeb"
  viewBox="0 0 700 575">
  <svg:rect height="10" rx="2" ry="2" width="140" x="537" y="9"/>
  <svg:rect height="11" rx="2" ry="2" width="667" x="14" y="30"/>
  <svg:rect height="211" rx="2" ry="2" width="211" x="12" y="58"/>
  <svg:rect height="211" rx="2" ry="2" width="211" x="240" y="57"/>
  <svg:rect height="211" rx="2" ry="2" width="211" x="467" y="56"/>
  <svg:rect height="211" rx="2" ry="2" width="211" x="12" y="283"/>
  <svg:rect height="211" rx="2" ry="2" width="211" x="240" y="281"/>
  <svg:rect height="211" rx="2" ry="2" width="211" x="468" y="279"/>
  <svg:circle cx="286" cy="536" r="12"/>
  <svg:circle cx="319" cy="535" r="12"/>
  <svg:circle cx="353" cy="535" r="12"/>
  <svg:rect height="24" rx="0" ry="0" width="52" x="378" y="524"/>
  <svg:rect height="24" rx="0" ry="0" width="52" x="210" y="523"/>
  <svg:circle cx="210" cy="535" r="12"/>
  <svg:circle cx="428" cy="536" r="12"/>
</content-loader>

<div *ngIf="type === 'lines'" class="lines__skeleton">
  <ng-container *ngFor="let i of loopArray">
    <div class="line"></div>
  </ng-container>
</div>
