import {DOCUMENT, isPlatformBrowser} from '@angular/common';
import {Component, Inject, OnInit, PLATFORM_ID} from '@angular/core';
// import {SwUpdate} from '@angular/service-worker';
import {Event, NavigationEnd, NavigationStart, Router, Scroll} from '@angular/router';
import {SwUpdate} from '@angular/service-worker';
import {GeneralService} from '@core/@http/services/general.service';
import {LocalstorageService} from '@core/@http/services/localstorage.service';
import {TranslateService} from '@ngx-translate/core';

declare let fbq: Function;
declare let setIntercom: Function;
const intercomScript = '(function(){var w=window;var ic=w.Intercom;if(typeof ic==="function"){ic(\'reattach_activator\');ic(\'update\',w.intercomSettings);}else{var d=document;var i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var s=d.createElement(\'script\');s.type=\'text/javascript\';s.async=true;s.src=\'https://widget.intercom.io/widget/lpc95jna\';var x=d.getElementsByTagName(\'script\')[0];x.parentNode.insertBefore(s,x);};if(document.readyState===\'complete\'){l();}else if(w.attachEvent){w.attachEvent(\'onload\',l);}else{w.addEventListener(\'load\',l,false);}}})();\n';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: [],
})
export class AppComponent implements OnInit {
  title = 'OTO Courses';
  lang: string | null = 'ar';

  constructor(
    private router: Router,
    private swUpdate: SwUpdate,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService,
    public generalService: GeneralService,
    private localStorageService: LocalstorageService,
  ) {
  }

  ngOnInit(): void {
    this.translateService.stream('DIR').subscribe((dir) => {
      this.directionChanged(dir);
    });
    if (this.swUpdate.isEnabled) {
      this.swUpdate.available.subscribe((event) => {
        // if (confirm('New Version Available. Load New Version')) {
        //   this.generalService.reloadPage();
        // }
        this.generalService.reloadPage();
      });
    }
    if (isPlatformBrowser(this.platformId) && typeof setIntercom === 'function') {
      // const token = this.localStorageService.state$.getValue()?.token;
      // if (token) {
      //     if (this.localStorageService.state$.getValue()?.r === 's') {
      //         const obj = {
      //             app_id: 'lpc95jna',
      //             type: 'student',
      //             user_hash: `${this.localStorageService.state$.getValue()?.hmac}`,
      //             group_id: `${this.localStorageService.state$.getValue()?.gid}`,
      //             group_name: `${this.localStorageService.state$.getValue()?.gname}`,
      //             phone: `${this.localStorageService.state$.getValue()?.mb}`,
      //             level: `${this.localStorageService.state$.getValue()?.lv}`,
      //             referral_code: `${this.localStorageService.state$.getValue()?.rc}`,
      //             status: `${this.localStorageService.state$.getValue()?.st}`,
      //             signed_up: `${this.localStorageService.state$.getValue()?.sg}`,
      //             name: `${this.localStorageService.state$.getValue()?.fn + this.localStorageService.state$.getValue()?.ln}`,
      //             email: `${this.localStorageService.state$.getValue()?.e}`,
      //             has_previous_transactions: `${this.localStorageService.state$.getValue()?.hpt}`,
      //             last_payment_amount: `${this.localStorageService.state$.getValue()?.lpa}`,
      //             last_payment_date: `${this.localStorageService.state$.getValue()?.lpd}`,
      //             total_sessions_purchased: `${this.localStorageService.state$.getValue()?.tsp}`,
      //             total_transactions_amount: `${this.localStorageService.state$.getValue()?.tta}`,
      //             total_transactions_count: `${this.localStorageService.state$.getValue()?.ttc}`,
      //         };
      //         setIntercom(obj);
      //     }
      //     if (this.localStorageService.state$.getValue()?.r === 't') {
      //         const obj = {
      //             app_id: 'lpc95jna',
      //             user_hash: `${this.localStorageService.state$.getValue()?.hmac}`,
      //             group: 'Default',
      //             phone: `${this.localStorageService.state$.getValue()?.mb}`,
      //             type: 'teacher',
      //             status: `${this.localStorageService.state$.getValue()?.st}`,
      //             signed_up: `${this.localStorageService.state$.getValue()?.sg}`,
      //             name: `${this.localStorageService.state$.getValue()?.fn + this.localStorageService.state$.getValue()?.ln}`,
      //             email: `${this.localStorageService.state$.getValue()?.e}`,
      //         };
      //         setIntercom(obj);
      //     }
      // }
      // else {
      //     setIntercom({app_id: 'lpc95jna'});
      // }
    }
    this.router.events.subscribe((event: Event) => {
        if (isPlatformBrowser(this.platformId)) {
          if (event instanceof NavigationStart) {
            this.generalService.setLayout(this.getLayout(event.url));
          }
          if (event instanceof NavigationEnd) {
            // this.isTest = $event.url.includes('/placement-test/english');
            this.generalService.setLayout(this.getLayout(event.url));
            fbq('track', 'PageView');
          }
          if (event instanceof Scroll && event.anchor === null) {
            window.scrollTo(0, 0);
          }
        }
      },
    );
  }

  getLayout(url: string): boolean {
    return url.includes('/en/placement-test/english') || url.includes('/en/placement-test/test-results');
  }

  loadScript(url: string, callback: any): void {
    if (url) {
      const script: any = document.createElement('script');
      script.type = 'text/javascript';
      if (callback == 1) {
        script.id = 'appIntercomdId';
      }
      if (callback == 2) {
        script.id = 'appIntercomdId2';
      }
      if (callback == 3) {
        script.id = 'appIntercomdIdlogined';
      }
      if (callback == 4) {
        script.id = 'appIntercomdIdlogined2';
      }
      script.text = url;
      document.getElementsByTagName('head')[0].appendChild(script);
    }
  }

  private directionChanged(dir: string): void {
    const htmlTag = this.document.getElementsByTagName('html')[0] as HTMLHtmlElement;
    htmlTag.dir = dir === 'rtl' ? 'rtl' : 'ltr';
    htmlTag.lang = dir === 'rtl' ? 'ar' : 'en';
    // this.changeCssFile(dir);
    // {
    //     "input": "node_modules/bootstrap/dist/css/bootstrap.rtl.min.css",
    //     "inject": false
    // },
    // {
    //     "input": "node_modules/bootstrap/dist/css/bootstrap.min.css",
    //     "inject": false
    // },
  }

  private changeCssFile(dir: string): void {
    const headTag = this.document.getElementsByTagName('head')[0] as HTMLHeadElement;
    const existingLink = this.document.getElementById('bootstrap-css') as HTMLLinkElement;
    const bundleName = dir === 'rtl' ? 'bootstrap.rtl.min.css' : 'bootstrap.min.css';

    if (existingLink) {
      existingLink.href = bundleName;
    } else {
      const newLink = this.document.createElement('link');
      newLink.rel = 'stylesheet';
      newLink.id = 'bootstrap-css';
      newLink.href = bundleName;
      headTag.appendChild(newLink);
    }
  }
}


// if (token) {
//     if (document.getElementById('appIntercomdId')) {
//         // @ts-ignore
//         document.getElementsByTagName('head')[0].removeChild(document.getElementById('appIntercomdId'));
//         // @ts-ignore
//         document.getElementsByTagName('head')[0].removeChild(document.getElementById('appIntercomdId2'));
//     }
//     // user_hash:
//     if (this.localStorageService.state$.getValue()?.r === 's') {
//         // user_id:'${this.localStorageService.state$.getValue()?.d}',
//         if (!document.getElementById('appIntercomdIdlogined')) {
//             this.loadScript(
//                 `window.intercomSettings={app_id:"lpc95jna",
//  user_hash:'${this.localStorageService.state$.getValue()?.hmac}',
//  group_id:'${this.localStorageService.state$.getValue()?.gid}',
//  group_name:'${this.localStorageService.state$.getValue()?.gname}',
//  phone:'${this.localStorageService.state$.getValue()?.mb}',
//  type:'${'student'}',
//  level:'${this.localStorageService.state$.getValue()?.lv}',
//  referral_code:'${this.localStorageService.state$.getValue()?.rc}',
//  status:'${this.localStorageService.state$.getValue()?.st}',
//  signed_up:'${this.localStorageService.state$.getValue()?.sg}',
//  name:'${this.localStorageService.state$.getValue()?.fn + ' ' +
//                 this.localStorageService.state$.getValue()?.ln}',
//  email:'${this.localStorageService.state$.getValue()?.e}',
//  has_previous_transactions:'${this.localStorageService.state$.getValue()?.hpt}',
//  last_payment_amount:'${this.localStorageService.state$.getValue()?.lpa}',
//  last_payment_date:'${this.localStorageService.state$.getValue()?.lpd}',
//  total_sessions_purchased:'${this.localStorageService.state$.getValue()?.tsp}',
//  total_transactions_amount:'${this.localStorageService.state$.getValue()?.tta}',
//  total_transactions_count:'${this.localStorageService.state$.getValue()?.ttc}'};`,
//                 3);
//             this.loadScript(intercomScript, 4);
//         }
//     }
//     // user_id:'${this.localStorageService.state$.getValue()?.d}',
//     if (this.localStorageService.state$.getValue()?.r === 't') {
//         if (!document.getElementById('appIntercomdIdlogined')) {
//             this.loadScript(
//                 `window.intercomSettings={app_id:"lpc95jna",
//  user_hash:'${this.localStorageService.state$.getValue()?.hmac}',
//  group:'${'Default'}',
//  phone:'${this.localStorageService.state$.getValue()?.mb}',
//  type:'${'teacher'}',
//  status:'${this.localStorageService.state$.getValue()?.st}',
//  signed_up:'${this.localStorageService.state$.getValue()?.sg}',
//  name:'${this.localStorageService.state$.getValue()?.fn + ' ' +
//                 this.localStorageService.state$.getValue()?.ln}',
//  email:'${this.localStorageService.state$.getValue()?.e}' };`,
//                 3);
//             this.loadScript(intercomScript, 4);
//         }
//     }
// }
// else {
//     if (document.getElementById('appIntercomdIdlogined')) {
//         // @ts-ignore
//         document.getElementsByTagName('head')[0].removeChild(document.getElementById('appIntercomdIdlogined'));
//         // @ts-ignore
//         document.getElementsByTagName('head')[0].removeChild(document.getElementById('appIntercomdIdlogined2'));
//     }
//     if (!document.getElementById('appIntercomdId')) {
//         this.loadScript('window.intercomSettings = {app_id: "lpc95jna"};', 1);
//         this.loadScript(intercomScript, 2);
//     }
// }
