import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {ApiResponse} from '@core/@data/Api/Api';
import {CourseFile} from '@core/@data/CoursesData/CourseFile';
import {Course} from '@core/@data/CoursesData/Courses';
import {NewStudentCoursesStats, StudentPackage, StudentUpcomingSession} from '@core/@data/New/NEW-Student';
import {Observable} from 'rxjs';
import {environment as env} from '../../../../../environments/environment';


@Injectable({
    providedIn: 'root',
})
export class StudentCoursesService {
    EndPoint = 'student';

    constructor(private http: HttpClient) {
    }

    GetAllowedCourses(): Observable<ApiResponse<Course[]>> {
        return this.http.get<ApiResponse<Course[]>>(`${env.ApiUrl}/${this.EndPoint}/courses/registrable`);
    }

    GetFilesByCourse(courseId: number): Observable<ApiResponse<CourseFile>> {
        return this.http.get<ApiResponse<CourseFile>>(`${env.ApiUrl}/${this.EndPoint}/files/course/${courseId}`);
    }

    AssignTeacherToPackage(pkgId: number, teacherId: number): Observable<ApiResponse<any>> {
        return this.http.post<ApiResponse<any>>(
            `${env.ApiUrl}/${this.EndPoint}/courses/packages/${pkgId}/teacher`,
            {teacher_id: teacherId});
    }

    NewGetCourses(isPaginated: number = 0, isGroup: number = 0): Observable<ApiResponse<StudentPackage[]>> {
        return this.http.get<ApiResponse<StudentPackage[]>>(`${env.ApiUrl}/${this.EndPoint}/courses?isPaginated=${isPaginated}&isGroup=${isGroup}`);
    }

    GetActiveCourses(isPaginated: number = 0, isGroup: number = 0): Observable<ApiResponse<StudentPackage[]>> {
        return this.http.get<ApiResponse<StudentPackage[]>>(`${env.ApiUrl}/${this.EndPoint}/courses/active?isPaginated=${isPaginated}&isGroup=${isGroup}`);
    }

    GetCoursesStatistics(): Observable<ApiResponse<NewStudentCoursesStats>> {
        return this.http.get<ApiResponse<NewStudentCoursesStats>>(`${env.ApiUrl}/${this.EndPoint}/courses/statistics`);
    }

    GetUpcomingSessions(limit: number = 3): Observable<ApiResponse<StudentUpcomingSession[]>> {
        return this.http.get<ApiResponse<StudentUpcomingSession[]>>(`${env.ApiUrl}/${this.EndPoint}/courses/upcoming-sessions?limit=${limit}`);
    }

    GetAllUpcomingSessions(): Observable<ApiResponse<StudentUpcomingSession[]>> {
        return this.http.get<ApiResponse<StudentUpcomingSession[]>>(`${env.ApiUrl}/${this.EndPoint}/courses/upcoming-sessions`);
    }
}// End of Class
