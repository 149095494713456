import {DatePipe} from '@angular/common';
import {Injectable} from '@angular/core';
import {ChannelData, Message} from '@core/@data/chat';
import {User} from '@core/@data/UsersData/UserData';
import {AuthService} from '@core/@http/services/auth.service';
import {ChatService} from '@core/@http/services/chat.service';
import {NotificationService} from '@core/@http/services/notification.service';
import {WebsocketService} from '@core/@http/services/websocket.service';
import {getSafeDate} from '@shared/functions/schedule-utilities';
import {DataContentStatus} from '@shared/modules/chat/new-chat-ui/models/types';
import Pusher from 'pusher-js';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class ChatMainService {
    allChannels: ChannelData[] = [];
    allChannels$ = new BehaviorSubject<ChannelData[]>([]);
    unReadMessageBehaviorSubject$ = new BehaviorSubject<number>(0);
    pusher!: Pusher;
    Status$: BehaviorSubject<DataContentStatus> = new BehaviorSubject<DataContentStatus>('NotStarted');
    currentUser: User = {} as User;
    NewMessage$: BehaviorSubject<Message> = new BehaviorSubject<Message>({} as Message);
    timezone = '';

    constructor(
        private authService: AuthService,
        private notificationService: NotificationService,
        private websocketService: WebsocketService,
        private chatService: ChatService,
        private datePipe: DatePipe,
    ) {
    }

    init(User: User): void {
        this.currentUser = User;
        this.pusher = this.websocketService.pusher;
        this.chatService.GetChannels(User.type).subscribe((allResults) => {
            this.Status$.next('Pending');
            this.allChannels = this.SortChannels(allResults.data);
            this.allChannels.forEach((channel) => {
                if (channel.unread_messages_count > 0) {
                    this.updateUnReadMessageCount(channel.unread_messages_count);
                }
                this.websocketService.listenChatChannel(channel.token).bind('chat-message.sent', (newMessage: Message) => {
                    if (this.authService.userAccountBehaviorSubject.value) {
                        const timezone = this.authService.userAccountBehaviorSubject.value?.address?.country?.timezone;
                        if (timezone && timezone === 'Africa/Cairo') {
                            const date = this.datePipe.transform(getSafeDate(newMessage.date.day + ' ' + newMessage.date.time)
                                .getTime() + 2 * 60 * 60 * 1000, 'YYYY-MM-dd hh-mm a') + '';
                            const arr = date.split(' ');
                            newMessage.date = {
                                day: arr[0],
                                time: arr[1] + ' ' + arr[2],
                            };
                        }
                    }
                    this.UpdateChannelWithNewEvent(newMessage);
                    if (newMessage.sender.type !== this.currentUser.type && newMessage.sender.id !== this.currentUser.id) {
                        this.updateUnReadMessageCount();
                    }
                    this.NewMessage$.next(newMessage);
                });
            });
            this.allChannels$.next(this.allChannels);
            this.Status$.next('Finished');
        }, (error) => {
            this.Status$.next('Failed');
        });
    }

    updateUnReadMessageCount(newValue: number = 1, type: 'plus' | 'minus' = 'plus'): void {
        if (type === 'plus') {
            this.unReadMessageBehaviorSubject$.next(this.unReadMessageBehaviorSubject$.value + newValue);
        } else {
            this.unReadMessageBehaviorSubject$.next(this.unReadMessageBehaviorSubject$.value - newValue);
        }
    }

    UpdateChannelWithNewEvent(newMessage: Message): void {
        this.allChannels.forEach((channel, index) => {
            if (channel.token === newMessage.channel.token) {
                // calculating message unread counts
                if (newMessage.sender.type === this.currentUser.type && newMessage.sender.id === this.currentUser.id) {
                    if (this.allChannels[index].unread_messages_count >= 0) {
                        this.updateUnReadMessageCount(this.allChannels[index].unread_messages_count, 'minus');
                    }
                    this.allChannels[index].unread_messages_count = 0;
                } else {
                    this.allChannels[index].unread_messages_count++;
                }
                this.allChannels[index].last_message = {
                    message: newMessage.type === 'text' ? newMessage.message : 'there is a new message...',
                    seen_by: [],
                    channel: newMessage.channel,
                    file: null,
                    type: newMessage.type,
                    date: newMessage.date,
                    id: newMessage.id,
                    is_deleted: newMessage.is_deleted,
                    is_read: newMessage.is_read,
                    is_sender: newMessage.is_sender,
                    sender: newMessage.sender,
                };
                this.allChannels[index].last_message_at = newMessage.date.day + ' ' + newMessage.date.time;
            }
        });
        this.allChannels = this.SortChannels(this.allChannels);
        this.allChannels$.next(this.allChannels);
    }

    SortChannels(allChannels: ChannelData[]): ChannelData[] {
        return allChannels.sort((a: ChannelData, b: ChannelData) => {
            if (a.last_message_at && b.last_message_at) {
                const A = a.last_message_at ? getSafeDate(a.last_message_at).getTime() : new Date().getTime();
                const B = b.last_message_at ? getSafeDate(b.last_message_at).getTime() : new Date().getTime();
                return A < B ? 1 : -1;
            } else {
                return 1;
            }
        });
    }

    HandleReadMessage(activeToken: string): void {
        this.allChannels = this.allChannels$.value;
        this.allChannels.forEach((channel, index) => {
            if (channel.token === activeToken) {
                this.updateUnReadMessageCount(channel.unread_messages_count, 'minus');
                this.allChannels[index].unread_messages_count = 0;
            }
        });
        this.allChannels$.next(this.allChannels);
    }

    // changeTimeZone(date: string | Date, timeZone: string): Date {
    //     if (typeof date === 'string') {
    //         return new Date(
    //             getSafeDate(date).toLocaleString('en-US', {
    //                 timeZone,
    //             }),
    //         );
    //     }
    //
    //     return getSafeDate(
    //         date.toLocaleString('en-US', {
    //             timeZone,
    //         }),
    //     );
    // }

    // changeTimeZone2(date: string | Date, timeZone: string) {
    //     let intlDateObj = new Intl.DateTimeFormat('en-US', {
    //         timeZone: 'America/Los_Angeles',
    //         hour: 'numeric',
    //         hour12: true,
    //         minute: 'numeric',
    //         weekday: 'long',
    //     });
    //     if (typeof date === 'string') {
    //         return intlDateObj.format(getSafeDate(date));
    //     }
    //     return intlDateObj.format(date);
    //
    // }

    // console.log('authService', this.authService.userAccountBehaviorSubject.value);
    // console.log('authService address', this.authService.userAccountBehaviorSubject.value?.address);
    // const laDate = this.changeTimeZone(newMessage.date.day + ' ' + newMessage.date.time, 'Africa/Cairo');
    // const laDate2 = this.changeTimeZone2(newMessage.date.day + ' ' + newMessage.date.time, 'Africa/Cairo');
    // console.log(laDate); // 👉️ "Sun Jan 16 2022 01:22:07"
    // console.log('laDate2  ', laDate2); // 👉️ "Sun Jan 16 2022 01:22:07"
}
