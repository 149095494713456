<div class="loading">
  <svg id="Layer_1" class="loadingLogo" width="70px" height="70px" data-name="OTO English Courses"
       xmlns="http://www.w3.org/2000/svg"
       viewBox="0 0 44.96 44.95">
    <defs>
      <style>.cls-1 {
        fill: #fff;
      }

      .cls-2 {
        fill: #faaf40;
      }

      .cls-3 {
        fill: #7ebc42;
      }

      .cls-4 {
        fill: #ef5126;
      }

      .cls-5 {
        fill: #32aab7;
      }

      .cls-6 {
        fill: #231f20;
      }</style>
    </defs>
    <title>OTOCourseslogo</title>
    <path class="cls-1"
          d="M43.27,1.09a4.72,4.72,0,0,0-3-1.07H4.72A4.69,4.69,0,0,0,0,4.72V40.28A4.69,4.69,0,0,0,1.73,43.9a4.62,4.62,0,0,0,3,1.08H40.28a4.62,4.62,0,0,0,3-1.08A4.69,4.69,0,0,0,45,40.28V4.72A4.72,4.72,0,0,0,43.27,1.09Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-2"
          d="M43.27,1.09a4.72,4.72,0,0,0-3-1.07H4.72A4.69,4.69,0,0,0,0,4.72V40.28A4.69,4.69,0,0,0,1.73,43.9a4.62,4.62,0,0,0,3,1.08H40.28a4.62,4.62,0,0,0,3-1.08A4.69,4.69,0,0,0,45,40.28V4.72A4.72,4.72,0,0,0,43.27,1.09ZM5.86,43.53a4.38,4.38,0,0,1-4.39-4.39V5.86A4.38,4.38,0,0,1,5.86,1.47H39.14a4.39,4.39,0,0,1,4.4,4.39V39.14a4.39,4.39,0,0,1-4.4,4.39Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-3"
          d="M39.73,17.13H31.25a1.13,1.13,0,0,0-1.13,1.12v3.47L32.27,24l-2.15.69v2a1.13,1.13,0,0,0,1.13,1.12h8.48a1.12,1.12,0,0,0,1.12-1.12V18.25A1.12,1.12,0,0,0,39.73,17.13ZM38.61,25a.65.65,0,0,1-.65.65H33a.65.65,0,0,1-.65-.65V20a.66.66,0,0,1,.65-.66H38a.66.66,0,0,1,.65.66Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-4"
          d="M14.87,21.76V18.28a1.12,1.12,0,0,0-1.12-1.12H5.27a1.12,1.12,0,0,0-1.12,1.12v8.48a1.12,1.12,0,0,0,1.12,1.12h8.48a1.12,1.12,0,0,0,1.12-1.12V24.68L12.74,24ZM12.63,25a.65.65,0,0,1-.65.65H7A.65.65,0,0,1,6.39,25V20.05A.65.65,0,0,1,7,19.4H12a.65.65,0,0,1,.65.65Z"
          transform="translate(-0.02 -0.02)"/>
    <polygon class="cls-5"
             points="27.84 17.09 17.12 17.09 17.12 19.34 21.36 19.34 21.36 27.83 23.6 27.83 23.6 19.34 27.84 19.34 27.84 17.09"/>
    <path class="cls-6"
          d="M14.41,40.87H13.22a.87.87,0,0,1-.9-.86V37.59a1.25,1.25,0,0,1,0-.14.86.86,0,0,1,.89-.72h1.19a1,1,0,0,1,.51.15l-.55.42H13.23a.31.31,0,0,0-.35.31V40a.31.31,0,0,0,.35.31h1.22l.48.4A.85.85,0,0,1,14.41,40.87Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M17.07,40.87h-.93a.94.94,0,0,1-.94-.94V38.7a.94.94,0,0,1,.94-.94h.93a.94.94,0,0,1,.93.94v1.23A.94.94,0,0,1,17.07,40.87Zm.36-2.16a.38.38,0,0,0-.38-.38h-.9a.38.38,0,0,0-.38.38v1.21a.38.38,0,0,0,.38.38h.9a.38.38,0,0,0,.38-.38Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M21.07,40.87h-.56v-.32a.82.82,0,0,1-.71.32h-.25c-.34,0-1-.19-1-.84V37.77h.57v2.15c0,.38.4.39.4.39h.29l.09,0a.79.79,0,0,0,.62-.89V37.77h.56v3.1Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M22.81,38.33l-.09,0a.68.68,0,0,0-.55.77v1.75h-.56V37.76h.56v.38a.81.81,0,0,1,.64-.37h.25v.56Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M25.06,40.89a2.81,2.81,0,0,1-.85,0,1.52,1.52,0,0,1-.56-.27l.37-.43a1.54,1.54,0,0,0,.88.24.78.78,0,0,0,.53-.19c.17-.19.09-.39-.14-.51a1.28,1.28,0,0,0-.34-.1l-.28,0a2.8,2.8,0,0,1-.46-.12.89.89,0,0,1-.63-.65c-.09-.76.36-1.08,1.19-1.08a1.71,1.71,0,0,1,1,.3l-.27.44a1.58,1.58,0,0,0-.5-.22,1.05,1.05,0,0,0-.51,0c-.26.07-.49.21-.34.52a.76.76,0,0,0,.5.26l.57.09A1,1,0,0,1,26,40.25,1,1,0,0,1,25.06,40.89Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M28.49,39.74H27.14V40a.31.31,0,0,0,.34.32h.91a.5.5,0,0,0,.17,0l.6.38a1.26,1.26,0,0,1-.81.21h-.88a.87.87,0,0,1-.9-.86V38.86h0v-.2c0-.51.28-.88,1-.88h1a.84.84,0,0,1,.93.83v.26C29.5,39.54,29.13,39.74,28.49,39.74Zm.44-1a.38.38,0,0,0-.37-.38h-1a.36.36,0,0,0-.38.39v.45H28.5c.23,0,.43-.07.43-.35Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M31.53,40.89a2.76,2.76,0,0,1-.84,0,1.64,1.64,0,0,1-.57-.27l.38-.43a1.54,1.54,0,0,0,.88.24.75.75,0,0,0,.52-.19c.17-.19.1-.39-.13-.51a1.28,1.28,0,0,0-.34-.1l-.29,0a3.09,3.09,0,0,1-.46-.12.91.91,0,0,1-.63-.65c-.08-.76.36-1.08,1.2-1.08a1.66,1.66,0,0,1,1,.3l-.26.44a1.69,1.69,0,0,0-.5-.22,1.06,1.06,0,0,0-.51,0c-.26.07-.5.21-.34.52a.76.76,0,0,0,.49.26l.58.09a1,1,0,0,1,.78,1.18A1,1,0,0,1,31.53,40.89Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M5.35,31.22H4.67a.47.47,0,0,1-.46-.47V29.64a.47.47,0,0,1,.46-.46h.68a.47.47,0,0,1,.46.46v1.11A.47.47,0,0,1,5.35,31.22Zm.18-1.57a.19.19,0,0,0-.18-.19H4.67a.19.19,0,0,0-.18.19v1.1a.18.18,0,0,0,.18.18h.68a.18.18,0,0,0,.18-.18Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M10.09,31.22H10l-.12,0a.43.43,0,0,1-.21-.15l-.91-1.39c-.08-.15-.1-.14-.1-.14v1.7H8.4V29.31a.13.13,0,0,1,.13-.13h.09l.11,0a.28.28,0,0,1,.19.15c0,.09.5.78.93,1.44.07.1.1.08.1.08V29.18h.28v1.9A.15.15,0,0,1,10.09,31.22Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M13.12,31.22a.33.33,0,0,1-.16-.07.37.37,0,0,1-.14-.33v-1.5a.14.14,0,0,1,.13-.14H14.1v.28h-1v.6h.82v.28H13.1v.5a.09.09,0,0,0,.1.1h.9v.28Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6" d="M20.61,29.46v1.76h-.28V29.46h-.62v-.28h1.48v.28Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M24.9,31.22h-.68a.46.46,0,0,1-.46-.47V29.64a.46.46,0,0,1,.46-.46h.68a.47.47,0,0,1,.46.46v1.11A.47.47,0,0,1,24.9,31.22Zm.18-1.57a.19.19,0,0,0-.19-.19h-.67a.2.2,0,0,0-.19.19v1.1a.19.19,0,0,0,.19.18h.67a.18.18,0,0,0,.19-.18Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M32.1,31.22h-.68a.46.46,0,0,1-.45-.47V29.64a.46.46,0,0,1,.45-.46h.68a.46.46,0,0,1,.46.46v1.11A.46.46,0,0,1,32.1,31.22Zm.19-1.57a.2.2,0,0,0-.19-.19h-.67a.19.19,0,0,0-.19.19v1.1a.18.18,0,0,0,.19.18h.67a.19.19,0,0,0,.19-.18Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M36.85,31.22h-.08l-.11,0a.39.39,0,0,1-.21-.15l-.91-1.39c-.09-.15-.11-.14-.11-.14v1.7h-.28V29.31a.14.14,0,0,1,.14-.13h.08l.11,0a.26.26,0,0,1,.19.15c0,.09.51.78.94,1.44.07.1.09.08.09.08V29.18H37v1.9A.14.14,0,0,1,36.85,31.22Z"
          transform="translate(-0.02 -0.02)"/>
    <path class="cls-6"
          d="M39.88,31.22a.33.33,0,0,1-.16-.07.37.37,0,0,1-.15-.33v-1.5a.15.15,0,0,1,.14-.14h1.14v.28h-1v.6h.82v.28h-.82v.5a.1.1,0,0,0,.11.1h.89v.28Z"
          transform="translate(-0.02 -0.02)"/>
  </svg>
</div>
