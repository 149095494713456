import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'strToNumber',
})
export class StringToNumberPipe implements PipeTransform {
  transform(value: string): number {
    return parseFloat(value);
  }
}
