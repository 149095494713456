import {Component, Inject, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {AuthService} from '@core/@http/services/auth.service';
import {SessionsService} from '@core/@http/services/sessions.service';
import {ToasterService} from '@shared/services/toastr.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-assessments-dialogue',
  templateUrl: './change-session-url-dialogue.component.html',
  styleUrls: ['./change-session-url-dialogue.component.scss'],
})
export class ChangeSessionUrlDialogueComponent implements OnInit {
  updateUrlForm!: UntypedFormGroup;
  loading = false;
  formData = new FormData();

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
                sessionId: number;
              },
              private authService: AuthService,
              private fb: UntypedFormBuilder,
              private sessionService: SessionsService,
              private toasterService: ToasterService,
              private mdDialogRef: MatDialogRef<ChangeSessionUrlDialogueComponent>) {

  }

  ngOnInit() {
    this.updateUrlForm = this.fb.group({
      url: ['', [Validators.required]],
    });
    this.updateUrlForm.controls.url.valueChanges.subscribe((_) => {
      this.updateUrlForm.markAllAsTouched();
    });
  }

  onSubmit(): void {
    this.loading = true;
    this.sessionService.updateSessionLink(this.data.sessionId, this.updateUrlForm.controls.url.value).subscribe(
      (_) => {
        this.loading = false;
        this.toasterService.showSuccess('Session Link Updated Successfully');
        this.confirm(true);
      },
      (err) => {
        this.loading = false;
        err.error.data.errors.forEach((error: string) => {
          this.toasterService.showFail(error);
        });
        this.confirm(false);
      });
  }

  public cancel(): void {
    this.close(false);
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public confirm(status: boolean): void {
    this.close(status);
  }

}
