import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AutomaticLoginComponent} from '@shared/components/other/automatic-login/automatic-login.component';
import {NotFoundComponent} from '@shared/components/other/not-found/not-found.component';

import * as AllRoutes from './routes';

export const routes: Routes = [
  ...AllRoutes.StaticPagesRoutes,
  ...AllRoutes.TeacherPublicPagesRoutes,
  ...AllRoutes.StudentRoutes,
  ...AllRoutes.TeacherRoutes,
  ...AllRoutes.StudentAndTeacherRoutes,
  ...AllRoutes.AuthRoutes,
  ...AllRoutes.TeacherStudentSharedPages,
  ...AllRoutes.TestNewFeatures,
  /*--------------------------- Automatic Login -------------------------------*/
  {
    path: 'login',
    component: AutomaticLoginComponent,
  },
  /*--------------------------- WildCard Page -------------------------------*/
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'disabled',
    // enableTracing: false,
    errorHandler: (error: any) => {
      console.log('AppRoutingModule Error in routing', error);
    },
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
