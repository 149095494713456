import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot} from '@angular/router';
import {GeneralService} from '@core/@http/services/general.service';
import {LocalstorageService} from '../services/localstorage.service';

@Injectable({
    providedIn: 'root',
})
export class TeacherGuard implements CanActivate {
    constructor(private localstorageService: LocalstorageService,
                private generalService: GeneralService,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): boolean {
        if (!this.localstorageService.state$.getValue()?.r !== null) {
            if (this.localstorageService.state$.getValue()?.r === 't') {
                return true;
            }
        }
        this.generalService.navigateToHome();
        return false;
    }
}
