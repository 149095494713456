<iframe [height]="isScreenSmall ? '100%' : height"
        [src]="src| safeYoutubeUrl"
        [width]="isScreenSmall ? '100%' : width"
        allowfullscreen
        frameborder="0"
        mozallowfullscreen
        webkitallowfullscreen
></iframe>
<!--<iframe [class.thumbnail]="thumbnail" [src]="safeSrc" allowfullscreen frameborder="0" height="315" mozallowfullscreen-->
<!--        webkitallowfullscreen-->
<!--        width="560"></iframe>-->
<!--allow="accelerometer;-->
<!--autoplay;-->
<!--clipboard-write;-->
<!--encrypted-media;-->
<!--gyroscope;-->
<!--picture-in-picture"-->
