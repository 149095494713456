import {BehaviorSubject, Observable} from 'rxjs';
import {FacebookLoginProvider, GoogleLoginProvider, SocialAuthService} from '@libs/social-login';
import {LoginUserData, ResetPasswordData, User} from '@core/@data/UsersData/UserData';
import {map, tap} from 'rxjs/operators';

import {ApiResponse} from '@core/@data/Api/Api';
import {GeneralService} from '@core/@http/services/general.service';
import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {LoadingService} from '@core/@http/services/loading.service';
import {LocalstorageService} from './localstorage.service';
import {Location} from '@angular/common';
import {StudentProfile} from '@core/@data/UsersData/StudentProfile';
import {Teacher} from '@core/@data/UsersData/Teacher';
import {ToasterService} from '@shared/services/toastr.service';
import {environment as env} from '../../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  userDataBehaviorSubject = new BehaviorSubject<User | null>(null);
  userAccountBehaviorSubject = new BehaviorSubject<StudentProfile | Teacher | null>(null);
  authStatus: 'pending' | 'finished' = 'pending';
  isTestAccount = false;

  constructor(
    private localStorageService: LocalstorageService,
    private http: HttpClient,
    private toasterService: ToasterService,
    public generalService: GeneralService,
    private socialAuthService: SocialAuthService,
    private loadingService: LoadingService,
    private location: Location,
  ) {
    this.profileUser();
    this.socialLoginInit();
    // this.socialAuthService.initState.subscribe((state) => {
    //   console.log('socialAuthService.initState', state);
    //   if (state) {
    //     this.socialLoginInit();
    //     this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then((token) => {
    //       console.log('-> token', token);
    //     });
    //   }
    // });
  }


  socialLoginInit(): void {
    this.socialAuthService.authState.subscribe((user) => {
      if (user) {
        this.localStorageService.setState('social-login', JSON.stringify([
          {
            provider: user.provider.toLowerCase(),
            data: user
          }
        ]));
        this.loadingService.isGlobalLoading$.next(true);
        let token = '';
        if (user.provider.toLowerCase() === 'google') {
          token = user.idToken;
        } else {
          token = user.authToken;
        }
        if (this.userDataBehaviorSubject.value) {
          this.linkAccount(user.provider.toLowerCase(), token).subscribe((res) => {
            this.loadingService.isGlobalLoading$.next(false);
            this.generalService.reloadPage();
          }, (error) => {
            error.error.data.errors.forEach((err: string) => {
              this.toasterService.showFail(err);
            });
          });
        } else {
          this.socialLogin(user.provider.toLowerCase(), token).subscribe((data) => {
              this.localStorageService.setState('token', data.data.token);
              this.localStorageService.setState('isAuth', 'true');
              if (data.data.user.type === 'student') {
                this.localStorageService.setState('r', 's');
              } else {
                this.localStorageService.setState('r', 't');
              }
              this.profileUser();
              this.loadingService.isGlobalLoading$.next(false);
              if (data.data.user.is_new_account && data.data.user.type === 'student') {
                this.generalService.navigateToWithParams('/setting', {
                  queryParams: {
                    isNewAccount: true
                  }
                });
              } else {
                this.location.back();
              }
            },
            (error) => {
              error.error.data.errors.forEach((err: string) => {
                this.toasterService.showFail(err);
              });
              this.loadingService.isGlobalLoading$.next(false);
              this.socialAuthService.signOut(false).then();
              this.generalService.navigateToHome();
            }
          );
        }
      }
    });
  }

  refreshGoogleToken(): void {
    this.socialAuthService.refreshAuthToken(GoogleLoginProvider.PROVIDER_ID).then((token) => {
    });
  }

  getGoogleToken(): void {
    this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then((token) => {
    }, (error) => {
    });
  }

  Login(data: any): Observable<ApiResponse<LoginUserData>> {
    return this.http.post<ApiResponse<LoginUserData>>(`${env.ApiUrl}/auth/login`, data).pipe(
      tap((data) => {
        this.localStorageService.setState('token', data.data.token);
        this.localStorageService.setState('isAuth', 'true');
        if (data.data.user.type === 'student') {
          this.localStorageService.setState('r', 's');
        } else {
          this.localStorageService.setState('r', 't');
        }
        this.profileUser();
      }));
  }

  Register(data: object): Observable<ApiResponse<{ token: string }>> {
    const url = `${env.ApiUrl}/auth/register`;
    return this.http.post<ApiResponse<any>>(url, data);
  }

  Logout(): void {
    this.http.post(`${env.ApiUrl}/auth/logout`, {}).subscribe(
      (_) => {
        this.userDataBehaviorSubject.next(null);
        this.userAccountBehaviorSubject.next(null);
        this.socialAuthService.signOut(false).then();
        this.localStorageService.setState('token', null);
        this.localStorageService.setState('isAuth', 'false');
        this.localStorageService.setState('r', null);
        this.localStorageService.ClearStorage();
        this.generalService.reloadPage();
      },
      () => {
        this.toasterService.showFailTranslated('toaster.fails.something_wrong');
        this.socialAuthService.signOut(false).then();
        this.localStorageService.setState('token', null);
        this.localStorageService.setState('r', null);
        this.localStorageService.ClearStorage();
        this.generalService.reloadPage();
      },
    );
  }

  ForgetPassword(email: string): Observable<any> {
    return this.http.post(`${env.ApiUrl}/auth/password/forget`, {login: email});
  }

  ValidateResetPassword(email: string, code: number): Observable<any> {
    return this.http.get(`${env.ApiUrl}/auth/password/reset/code/validate?login=${email}&code=${code}`);
  }

  ResetPassword(data: ResetPasswordData): Observable<any> {
    return this.http.post(`${env.ApiUrl}/auth/password/reset`, data);
  }

  profileUser(): void {
    this.authStatus = 'pending';
    const token = this.localStorageService.state$.value?.token;
    const role = this.localStorageService.state$.value?.r;
    if (token && token !== '' && role && role !== '' && (role === 's' || role === 't')) {
      this.http.get<{ data: Teacher | StudentProfile }>(`${env.ApiUrl}/${role === 's' ? 'student' : 'teacher'}/account`)
        .pipe(map((data) => {
            this.userDataBehaviorSubject.next({
              type: role === 's' ? 'student' : 'teacher',
              id: data.data.account.id,
            });
            if (role === 's') {
              this.setStudentDateToLocalStorage(data.data as StudentProfile);
            } else {
              this.setTeacherDateToLocalStorage(data.data as Teacher);
            }
            this.userAccountBehaviorSubject.next(data.data);
            this.authStatus = 'finished';
          }),
        ).subscribe((res) => {
        this.loadingService.isGlobalLoading$.next(false);
      }, (error) => {
        this.userAccountBehaviorSubject.next(null);
        this.localStorageService.ClearStorage();
        this.authStatus = 'finished';
        this.socialAuthService.signOut(false).then();
        this.loadingService.isGlobalLoading$.next(false);
        this.generalService.navigateToHome();
      });
    } else {
      this.userDataBehaviorSubject.next(null);
      this.userAccountBehaviorSubject.next(null);
      this.localStorageService.ClearStorage();
      this.socialAuthService.signOut(false).then();
      this.authStatus = 'finished';
      this.loadingService.isGlobalLoading$.next(false);
    }
    // const localItem = this.localStorageService.state$.getValue()?.token;
    // if (localItem) {
    //   this.http.get<{ data: User }>(`${env.ApiUrl}/auth/check`)
    //     .subscribe((data) => {
    //       this.userDataBehaviorSubject.next(data.data);
    //       if (data.data.type === 'teacher') {
    //         this.localStorageService.setState('r', 't');
    //         this.TeacherAccountData();
    //       }
    //       if (data.data.type === 'student') {
    //         this.localStorageService.setState('r', 's');
    //         this.StudentAccountData();
    //       }
    //     }, () => {
    //       this.userDataBehaviorSubject.next(null);
    //       this.userAccountBehaviorSubject.next(null);
    //       this.localStorageService.setState('token', null);
    //       this.localStorageService.setState('isAuth', 'false');
    //       this.localStorageService.setState('r', null);
    //       this.localStorageService.ClearStorage();
    //       this.generalService.navigateToHome();
    //     });
    // }
  }

  StudentAccountData(): void {
    const localItem = this.localStorageService.state$.getValue()?.token;
    if (localItem !== null) {
      this.http.get<{ data: StudentProfile }>(`${env.ApiUrl}/student/account`)
        .subscribe((data) => {
          this.setStudentDateToLocalStorage(data.data);
          this.userAccountBehaviorSubject.next(data.data);
        }, (error) => {
          this.localStorageService.ClearStorage();
          this.generalService.navigateToHome();
        });
    }
  }

  TeacherAccountData(): void {
    const localItem = this.localStorageService.state$.getValue()?.token;
    if (localItem !== null) {
      this.http.get<{ data: Teacher }>(`${env.ApiUrl}/teacher/account`)
        .subscribe((data) => {
          this.setTeacherDateToLocalStorage(data.data);
          this.userAccountBehaviorSubject.next(data.data);
        }, (error) => {
          this.localStorageService.ClearStorage();
          this.generalService.navigateToHome();
        });
    }
  }

  setTeacherDateToLocalStorage(data: Teacher): void {
    this.localStorageService.setState('d', data.account.id);
    this.localStorageService.setState('fn', data.account.first_name);
    this.localStorageService.setState('ln', data.account.last_name);
    this.localStorageService.setState('sg', data.account.created_at);
    this.localStorageService.setState('mb', data.account.mobile);
    this.localStorageService.setState('e', data.account.email);
    this.localStorageService.setState('st', data.account.status);
    this.localStorageService.setState('tz', data.address.country.timezone);
    this.localStorageService.setState('hmac', data.account.intercom_hmac);
    if (data?.account?.email === 'TestTeacher100@test.com') {
      this.isTestAccount = true;
    }
  }

  setStudentDateToLocalStorage(data: StudentProfile): void {
    this.localStorageService.setState('d', data.account.id);
    this.localStorageService.setState('fn', data.account.first_name);
    this.localStorageService.setState('ln', data.account.last_name);
    this.localStorageService.setState('sg', data.account.created_at);
    this.localStorageService.setState('e', data.account.email);
    this.localStorageService.setState('st', data.account.status);
    this.localStorageService.setState('rc', data.account.referral_code);
    this.localStorageService.setState('lv', data.account.level.main);
    this.localStorageService.setState('mb', data.account.mobile);
    this.localStorageService.setState('tz', data.address.country.timezone);
    this.localStorageService.setState('hmac', data.account.intercom_hmac);
    this.localStorageService.setState('gid', data.account?.group?.id);
    this.localStorageService.setState('gname', data.account?.group?.name);
    this.localStorageService.setState('hpt', data.transactions?.has_previous_transactions);
    this.localStorageService.setState('lpa', data.transactions?.last_payment_amount);
    this.localStorageService.setState('lpd', data.transactions?.last_payment_date);
    this.localStorageService.setState('tsp', data.transactions?.total_sessions_purchased);
    this.localStorageService.setState('tta', data.transactions?.total_transactions_amount);
    this.localStorageService.setState('ttc', data.transactions?.total_transactions_count);
    this.localStorageService.setState('is_v', data.account.email_verified_at === null ? 'false' : 'true');
  }

  StudentUpdateProfile(data: FormData): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/student/account/update`, data).pipe(
      tap(() => {
        this.StudentAccountData();
      }),
    );
  }

  TeacherUpdateProfile(data: FormData): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/teacher/account/update`, data).pipe(
      tap(() => {
        this.TeacherAccountData();
      }),
    );
  }

  GetStudentAccount(): Observable<ApiResponse<StudentProfile>> {
    return this.http.get<ApiResponse<StudentProfile>>(`${env.ApiUrl}/student/account`);
  }

  GetTeacherAccount(): Observable<ApiResponse<Teacher>> {
    return this.http.get<ApiResponse<Teacher>>(`${env.ApiUrl}/teacher/account`);
  }

  signInWithFB(): void {
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).then();
  }

  socialLogin(provider: string, token: string): Observable<ApiResponse<LoginUserData>> {
    return this.http.post<ApiResponse<LoginUserData>>(`${env.ApiUrl}/auth/login/social`, {
      provider_name: provider,
      access_token: token
    });
  }

  linkAccount(provider: string, token: string): Observable<ApiResponse<any>> {
    const role = this.localStorageService.state$.value?.r;
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/${role === 's' ? 'student' : 'teacher'}/account/social/link`, {
      provider_name: provider,
      access_token: token
    });
  }

  unLinkAccount(provider: string): Observable<ApiResponse<any>> {
    const role = this.localStorageService.state$.value?.r;
    return this.http.delete<any>(`${env.ApiUrl}/${role === 's' ? 'student' : 'teacher'}/account/social/unlink`, {
      body: {
        provider_name: provider
      }
    });
  }
}
