import {isPlatformBrowser} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {Observable} from 'rxjs';
import {environment as env} from '../../../../environments/environment';
import {ApiResponse} from '../../@data/Api/Api';
import {Session, StartSession} from '../../@data/CoursesData/Session';


@Injectable({
    providedIn: 'root',
})
export class SessionsService {
    endPoint = 'courses/sessions';

    constructor(private http: HttpClient, @Inject(PLATFORM_ID) private platformId: Object) {
    }

    GetSession(userType: string, id: number): Observable<ApiResponse<Session>> {
        return this.http.get<ApiResponse<Session>>(`${env.ApiUrl}/${userType}/${this.endPoint}/${id}`);
    }

    JoinSession(userType: string, id: number): Observable<ApiResponse<StartSession>> {
        return this.http.get<ApiResponse<StartSession>>(`${env.ApiUrl}/${userType}/${this.endPoint}/${id}/join`);
    }

    RateSession(userType: string, id: number, data: any): Observable<ApiResponse<any>> {
        return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/${userType}/${this.endPoint}/${id}/rate`, data);
    }

    ReScheduleSession(userType: string, id: number, scheduleId: number): Observable<ApiResponse<any>> {
        return this.http.put<ApiResponse<any>>(`${env.ApiUrl}/${userType}/${this.endPoint}/${id}/schedule`, {schedule_id: scheduleId});
    }

    UnScheduleSession(userType: string, id: number): Observable<ApiResponse<any>> {
        return this.http.put<ApiResponse<any>>(`${env.ApiUrl}/${userType}/${this.endPoint}/${id}/schedule`, {});
    }

    StartSessionLiveNow(url: string): void {
        if (isPlatformBrowser(this.platformId)) {
            window.open(url, '_blank');
        }
    }

    UploadSessionRecords(sessionId: number, records: string[]): Observable<ApiResponse<any>> {
        return this.http.post<ApiResponse<any>>
        (`${env.ApiUrl}/teacher/courses/sessions/${sessionId}/recording/links`, {records: records});
    }

    RequestCancelSession(sessionId: number, reason: string): Observable<ApiResponse<any>> {
        return this.http.post<ApiResponse<any>>
        (`${env.ApiUrl}/teacher/courses/sessions/${sessionId}/cancel`, {cancellation_reason: reason});
    }

    RequestSessionRecords(sessionId: number): Observable<any> {
        return this.http.post<any>(`${env.ApiUrl}/student/courses/sessions/${sessionId}/records/request`, {});
    }

    RequestSessionMaterial(sessionId: number): Observable<any> {
        return this.http.post<any>(`${env.ApiUrl}/student/courses/sessions/${sessionId}/materials/request`, {});
    }

    getDaysBetweenDates(startDate: string): number {
        const toDay = new Date();
        const oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
        const firstDate = new Date(startDate);
        return Math.round(Math.abs((firstDate.getTime() - toDay.getTime()) / (oneDay)));
    }

    updateSessionLink(sessionId: number, url: string): Observable<any> {
        return this.http.post<any>(`${env.ApiUrl}/teacher/courses/sessions/${sessionId}/zoom`, {url});
    }

}// End of Class
