import {NgModule} from '@angular/core';
import {SharedModule} from '@shared/shared.module';
import {InViewportModule} from 'ng-in-viewport';
import {ChatActionsComponent} from './components/chat-actions/chat-actions.component';
import {ChatBodyComponent} from './components/chat-body/chat-body.component';
import {ChatChannelsComponent} from './components/chat-channels/chat-channels.component';
import {ChatFloatingButtonComponent} from './components/chat-floating-button/chat-floating-button.component';
import {ChatLoaderComponent} from './components/chat-loader/chat-loader.component';
import {MatProgressBarModule} from '@angular/material/progress-bar';


@NgModule({
  declarations: [
    ChatFloatingButtonComponent,
    ChatBodyComponent,
    ChatChannelsComponent,
    ChatActionsComponent,
    ChatLoaderComponent,
  ],
  exports: [
    ChatFloatingButtonComponent,
  ],
  imports: [
    SharedModule,
    InViewportModule,
    MatProgressBarModule,
  ],
})
export class NewChatUiModule {
}
