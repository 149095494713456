import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment as env} from '../../../../environments/environment';
import {BehaviorSubject, Observable} from 'rxjs';
import {ApiResponse} from '@core/@data/Api/Api';
import {Notification} from '@core/@data/Notification';


@Injectable({
  providedIn: 'root'
})
export class NotificationService {
  EndPoint = 'notifications';
  unReadMessageBehaviorSubject = new BehaviorSubject<number>(0);

  constructor(private http: HttpClient) {
  }

  GetAllNotifications(userType: string): Observable<ApiResponse<Notification[]>> {
    return this.http.get<ApiResponse<Notification[]>>(`${env.ApiUrl}/${userType}/${this.EndPoint}`);
  }

  GetSingleNotification(userType: string, id: string): Observable<ApiResponse<Notification[]>> {
    return this.http.get<ApiResponse<Notification[]>>(`${env.ApiUrl}/${userType}/${this.EndPoint}/${id}`);
  }

  MarkAsRead(userType: string, data: object): Observable<any> {
    return this.http.post(`${env.ApiUrl}/${userType}/${this.EndPoint}/read`, data);
  }

  CheckAvailableTriggers(userType: string): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/${userType}/${this.EndPoint}/triggers/check`, {});
  }

  UpdateDeviceToken(userType: string, deviceToken: any): Observable<ApiResponse<any>> {
    return this.http.post<ApiResponse<any>>(`${env.ApiUrl}/${userType}/${this.EndPoint}/refresh/device/token`, {device_token: deviceToken});
  }
}// End of Class
