import {Component} from '@angular/core';
import {MatIconRegistry} from '@angular/material/icon';
import {DomSanitizer} from '@angular/platform-browser';
import {Router} from '@angular/router';
import {GeneralService} from '@core/@http/services/general.service';
import {Observable} from 'rxjs';
import {LoadingService} from '@core/@http/services/loading.service';

@Component({
  selector: 'app-main--navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: [],
})
export class MainAppNavbarComponent {
  isTest$: Observable<boolean>;

  constructor(
    private router: Router, iconRegistry: MatIconRegistry,
    private generalService: GeneralService,
    public loadingService: LoadingService,
    sanitizer: DomSanitizer) {
    iconRegistry.addSvgIconSet(
      sanitizer.bypassSecurityTrustResourceUrl('assets/images/navbar-icons.svg'),
    );
    this.isTest$ = this.generalService.getLayout();
    // this.router.events.subscribe(($event) => {
    //     if ($event instanceof NavigationStart) {
    //         this.isTest = this.getLayout($event.url);
    //     }
    //     if ($event instanceof NavigationEnd) {
    //         // this.isTest = $event.url.includes('/placement-test/english');
    //         this.isTest = this.getLayout($event.url);
    //     }
    // });
  }

  getLayout(url: string): boolean {
    return url.includes('/en/placement-test/english') || url.includes('/en/placement-test/test-results');
  }
}
