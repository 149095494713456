<section class="main">
    <div class="layout">
        <svg class="left__svg" fill="none" viewBox="0 0 1434 1573"
             xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd"
                  d="M1160.91 782.241L723.241 344.569L285.569 782.241L723.241 1219.91L1160.91 782.241ZM1428.38 867.344C1475.38 820.343 1475.38 744.139 1428.38 697.138L808.344 77.1029C761.343 30.1019 685.139 30.1019 638.138 77.1029L18.1028 697.138C-28.8982 744.139 -28.8982 820.343 18.1028 867.344L638.138 1487.38C685.139 1534.38 761.342 1534.38 808.343 1487.38L1428.38 867.344Z"
                  fill="#FDC066"
                  fill-rule="evenodd"/>
        </svg>
        <svg class="right__svg" fill="none" viewBox="0 0 1434 1573"
             xmlns="http://www.w3.org/2000/svg">
            <path clip-rule="evenodd"
                  d="M1160.91 782.241L723.241 344.569L285.569 782.241L723.241 1219.91L1160.91 782.241ZM1428.38 867.344C1475.38 820.343 1475.38 744.139 1428.38 697.138L808.344 77.1029C761.343 30.1019 685.139 30.1019 638.138 77.1029L18.1028 697.138C-28.8982 744.139 -28.8982 820.343 18.1028 867.344L638.138 1487.38C685.139 1534.38 761.342 1534.38 808.343 1487.38L1428.38 867.344Z"
                  fill="#FDC066"
                  fill-rule="evenodd"/>
        </svg>
    </div>
    <section class="content__body">
        <div class="container">
            <div class="breadcrumbs">
                <app-bread-crumbs
                    [Routes]="[{name:'bread_crumbs.verify_email' |translate,route:'/user/verify-email'}]"></app-bread-crumbs>
            </div>
            <section class="auth__section row justify-content-center align-items-center align-content-center">
                <div class="col-12 col-md-7 col-lg-6 col-xl-5">
                    <div class="auth__card">
                        <a [routerLink]="'/' | localize" class="logo">
                            <img alt="OTO English" src="/assets/images/header/OTOCourseslogo.svg"
                                 width="75px">
                        </a>
                        <h1 class="card__title">{{'forms.sentences.verify_email' | translate}}</h1>
                        <div class="auth__form">
                            <div class="submit-btn text-center m-auto">
                                <button
                                    (click)="sendEmail()"
                                    [class.spinner]="loading"
                                    [disabled]=" loading"
                                    [ngStyle]="{'cursor':loading ? 'not-allowed':'pointer'  }"
                                    class="auth__btn--submit"
                                    mat-raised-button
                                    type="submit">
                                    {{'forms.sentences.send_verification_mail' | translate}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    </section>
</section>
