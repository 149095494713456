import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap} from '@angular/router';
import {AutomaticAuthService} from '@shared/components/other/automatic-login/automatic-auth.service';

@Component({
  selector: 'app-automatic-login',
  template: `
    <app-spinner *ngIf="loading"></app-spinner>
  `,
  styleUrls: [],
  providers: [
    AutomaticAuthService
  ]
})
export class AutomaticLoginComponent implements OnInit {
  loading = true;

  constructor(
    private authService: AutomaticAuthService,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit(): void {
    this.route.queryParamMap.subscribe((res: ParamMap) => {
      if (res?.has('type') && res?.has('token')) {
        this.authService.AutoMaticLogin(res.get('type'), res.get('token'));
      }
    });
  }

}
