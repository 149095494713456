import {Injectable} from '@angular/core';
import Pusher from 'pusher-js';
import {environment as env} from '../../../../environments/environment';
import {LocalstorageService} from './localstorage.service';

@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  pusher: any = '';
  pusherKeys = env.PusherKeys;

  constructor(private localStorageService: LocalstorageService) {
    this.pusher = new Pusher(this.pusherKeys.key, {
      cluster: this.pusherKeys.cluster,
      authEndpoint: this.pusherKeys.authEndpoint,
      auth: {
        headers: {
          Authorization: `Bearer ${this.localStorageService.state$.getValue()?.token}`,
        }
      }
    });
  }

  // Listen to Channels And Subscribe Them
  listenChannel(names: any[]): any {
    // tslint:disable-next-line:prefer-for-of
    names.forEach((item) => {
      this.pusher.subscribe('private-' + item);
      Pusher.log = msg => {
        // console.log('From Pusher', msg);
      };
      this.pusher.bind('connected', this.connectedExecute());
    });
    this.pusher.allChannels().forEach((channel: { name: any; }) => {
      // console.log('Subscribe: ', channel.name);
    });
  }


  // Execute Function After Connected
  connectedExecute(): any {

  }

  // UNSubscribe Channels
  unsubscribeChannel(names: any[]): any {
    names.forEach((item) => {
      this.pusher.unsubscribe('private-' + item);
      Pusher.log = msg => {
        // console.log(msg);
      };
      this.pusher.bind('disconnected', this.disConnectedExecute());
    });
    this.pusher.allChannels().forEach((channel: { name: any; }) => {
      // console.log('UNSubscribe: ', channel.name);
    });
  }

  // Execute Function After DisConnected
  disConnectedExecute(): any {

  }

  listenChatChannel(token: string): any {
    // Pusher.log = msg => {
    //   console.log('From Pusher', msg);
    // };
    return this.pusher.subscribe(`private-chat-channel.${token}`);
  }

  listenNotificationChannel(id: number): any {
    Pusher.log = msg => {
      // console.log('From Pusher IN Notification Subscribe', msg);
    };
    return this.pusher.subscribe(`private.notifiable.student.${id}`);
  }


}// End of Class
