import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'convertSecondsFormat',
  pure: true
})
export class ConvertSecondsFormatPipe implements PipeTransform {

  transform(value: number): string {
    return new Date(value).toISOString().substr(11, 8);
  }
}
