import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-small-start-rating',
  templateUrl: './small-start-rating.component.html',
  styleUrls: ['./small-start-rating.component.scss']
})
export class SmallStartRatingComponent {
  @Input() public rating = 5;
  @Input() showText = false;
  @Input() justifyCenter = false;

  public get fullStars(): number[] {
    const totalFullStars = Math.floor(this.rating);
    return Array(totalFullStars).fill(0);
  }

  public get hasHalfStar(): boolean {
    const highestStars = 5;
    return (this.rating - Math.floor(this.rating) >= 0.5) && this.rating !== highestStars;
  }

  public get emptyStars(): number[] {
    const highestStars = 5;
    let totalEmptyStars = Math.floor(highestStars - this.rating);
    (this.rating - Math.floor(this.rating) < 0.5) && (this.rating - Math.floor(this.rating) > 0) ? totalEmptyStars++ : '';
    return Array(totalEmptyStars).fill(0);
  }
}
