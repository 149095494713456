import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree} from '@angular/router';
import {GeneralService} from '@core/@http/services/general.service';
import {Observable} from 'rxjs';
import {LocalstorageService} from '../services/localstorage.service';

@Injectable({
    providedIn: 'root',
})
export class GuestGuardService implements CanActivate {
    constructor(private localstorageService: LocalstorageService,
                private generalService: GeneralService,
    ) {
    }

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        if (!this.localstorageService.state$.getValue()?.token !== null && !this.localstorageService.state$.getValue()?.token) {
            return true;
        }
        if (this.localstorageService.state$.getValue()?.token !== null && this.localstorageService.state$.getValue()?.token) {
            if (!this.localstorageService.state$.getValue()?.r !== null) {
                if (this.localstorageService.state$.getValue()?.r === 't') {
                    this.generalService.navigateToWithParams('/teacher/my-account');
                    return false;
                }
                if (this.localstorageService.state$.getValue()?.r === 's') {
                    this.generalService.navigateToWithParams('/my-account');
                    return false;
                }
            }
        }
        return false;
    }

}
