import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {RateDialogue} from '@core/@data/Dialogue/RateDialouge';
import {SessionsService} from '@core/@http/services/sessions.service';
import {ToasterService} from '@shared/services/toastr.service';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-presentational-dialogue',
  templateUrl: './rate-dialogue.component.html',
  styleUrls: ['../../../../../styles/components/dialogues.scss'],
})
export class RateDialogueComponent {
  @ViewChild('comment') comment!: ElementRef;
  loading = false;
  rate = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RateDialogue,
    private sessionService: SessionsService,
    private toasterService: ToasterService,
    private mdDialogRef: MatDialogRef<RateDialogueComponent>,
  ) {
  }


  onSubmit(): void {
    this.loading = true;
    if (this.data.isTeacher) {
      const data = {
        student_id: this.data?.data?.extra?.id,
        student_rate: this.rate,
        student_rate_comment: this.comment.nativeElement.value,
      };
      this.sessionService.RateSession('teacher', this.data?.data?.id, data)
        .subscribe((res) => {
          this.loading = false;
          this.toasterService.showSuccessTranslated('toaster.success.rate');
          this.close(true);
        }, (error) => {
          this.loading = false;
          error.error.data.errors.forEach((err: string) => {
            this.toasterService.showFail(err);
          });
        });
    } else {
      const data = {
        teacher_rate: this.rate,
        teacher_rate_comment: this.comment.nativeElement.value,
      };
      this.sessionService.RateSession('student', this.data?.data?.id, data).subscribe(
        (_) => {
          this.loading = false;
          this.toasterService.showSuccessTranslated('toaster.success.rate');
          this.close(true);
        },
        (error) => {
          this.loading = false;
          error.error.data.errors.forEach((err: string) => {
            this.toasterService.showFail(err);
          });
        });
    }
  }

  public cancel(): void {
    this.close(false);
  }

  public close(value: boolean): void {
    this.mdDialogRef.close(value);
  }

  public confirm(): void {
    this.close(true);
  }

}
