import {Location} from '@angular/common';
import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {Params} from '@angular/router';

export interface BreadCrumbRouting {
  route: string;
  name: string;
  isHasQueryParam?: boolean;
  queryParam?: Params | null;
  fragment?: string | null;
}

@Component({
  selector: 'app-bread-crumbs',
  templateUrl: './bread-crumbs.component.html',
  styleUrls: ['./bread-crumbs.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BreadCrumbsComponent implements OnInit {
  @Input() Routes: BreadCrumbRouting[] = [];
  @Input() isHaveBackButton = true;

  constructor(private location: Location) {
  }

  ngOnInit(): void {
  }

  back(): void {
    this.location.back();
  }
}
