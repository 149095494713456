import {Pipe, PipeTransform} from '@angular/core';
import {LangSectionLevel} from '@core/@data/Exams/LangSectionLevel';
import {ExamSections} from '@student/all-placement-tests/@models';

@Pipe({
  name: 'getLevelData',
  pure: true
})
export class GetLevelDataPipe implements PipeTransform {

  transform(value: LangSectionLevel[] | null = [], level: Capitalize<ExamSections>): LangSectionLevel | null {
    if (value && value.length > 0) {
      return value.filter((item) => {
        return item.name.includes(level + ' Test');
      })[0];
    }
    return null;
  }

}
