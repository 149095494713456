import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'calcAge',
  pure: true
})
export class AgeCalculationPipe implements PipeTransform {
  transform(value: string | undefined | null, serverTime: number): string {
    if (!value) {
      return '0 years';
    }
    if (value && value !== '') {
      const Age = Math.ceil((Math.ceil(Math.abs((serverTime * 1000) - new Date(value).getTime())) / (1000 * 3600 * 24)) / 365);
      return !isNaN(Age) ? `${Age} years` : '0 years';
    } else {
      return '0 years';
    }
  }
}

