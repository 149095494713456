import {Injectable} from '@angular/core';
import {CanActivate} from '@angular/router';
import {GeneralService} from '@core/@http/services/general.service';
import {LocalstorageService} from '../services/localstorage.service';

@Injectable({providedIn: 'root'})
export class AuthGuardService implements CanActivate {
    constructor(private localstorageService: LocalstorageService,
                private generalService: GeneralService,
    ) {
    }

    canActivate(): boolean {
        if (
            !this.localstorageService.state$.getValue()?.token ||
            this.localstorageService.state$.getValue()?.token === null ||
            this.localstorageService.state$.getValue()?.token === ''
        ) {
            this.generalService.navigateToWithParams('/user/login');
            return false;
        }
        return true;
    }

}
