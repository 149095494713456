// answer-type.pipe.ts

import {Pipe, PipeTransform} from '@angular/core';
import {AnswerContentType} from '@core/@data/Exams/PlacementExam';
import {getAnswerType} from '@shared/functions/checks';

@Pipe({
  name: 'answerType',
  pure: true
})
export class AnswerTypePipe implements PipeTransform {
  transform(answer: string): AnswerContentType {
    return getAnswerType(answer);
  }
}
