import {MenuLink, RouteLink} from '@core/@data/General/Navbar';

// Auth
export const StudentLinks: RouteLink[] = [
  {isExact: false, name: 'Our Courses', link: '/courses', key: 'routes.our_courses'},
  {isExact: false, name: 'Our Groups', link: '/groups/courses', key: 'routes.our_groups'},
  {isExact: true, name: 'Our Teachers', link: '/teachers', key: 'routes.our_teachers'},
  {isExact: true, name: 'What is CEFR?', link: '/cefr', key: 'routes.what_cefr'},
  {isExact: true, name: 'My Learning', link: '/my-learning', key: 'routes.my_learning'},
  // {isExact: true, name: 'My Courses', link: '/my-account/my-courses', key: 'routes.my_courses'},
];
export const TeacherLinks: RouteLink[] = [
  {isExact: true, name: 'My Calendar', link: '/teacher/my-calendar', key: 'routes.my_calendar'},
  {isExact: true, name: 'My Students', link: '/teacher/my-students', key: 'routes.my_students'},
  {isExact: true, name: 'My Groups', link: '/teacher/my-account/my-courses-groups', key: 'routes.my_groups'},
  {isExact: true, name: 'My Courses', link: '/teacher/my-account/my-courses', key: 'routes.my_courses'},
];
export const StudentMenu: MenuLink[] = [
  {route: '/my-account/my-courses', title: 'My Courses', icon: 'groups', key: 'routes.my_courses_1t1'},
  {route: '/my-account/my-active-group-courses', title: 'My Groups', icon: 'groups', key: 'routes.my_group_courses'},
  {route: '/my-schedule', title: 'My Schedule', icon: 'mySchedule', key: 'routes.my_schedule'},
  {route: '/my-credit', title: 'My Transactions', icon: 'myTransactionsIcon', key: 'routes.my_transactions'},
  {
    route: '/my-account/my-courses-history',
    title: 'Courses History',
    icon: 'allCoursesIcon',
    key: 'routes.my_courses_history'
  },
  {
    route: '/my-account/my-group-courses-history',
    title: 'Course Groups History',
    icon: 'allCoursesIcon',
    key: 'routes.my_group_courses_history',
  },
  {route: '/conversation-club', icon: 'mySchedule', title: 'Conversation Club', key: 'routes.conversation_club'},
  {
    route: '/check-placement-test',
    icon: 'mySchedule',
    title: 'Check Placement Club',
    key: 'routes.check_placement_test'
  },
  {route: '/features/change-log', icon: 'mySchedule', title: 'Site Updates', key: 'routes.change_log'},
  {route: '/setting', icon: 'settingIcon', title: 'Setting', key: 'routes.setting'},
];
export const TeacherMenu: MenuLink[] = [
  {route: '/teacher/my-account/all-courses', icon: 'allCoursesIcon', title: 'All Courses', key: 'routes.all_courses'},
  {
    route: '/teacher/unevaluated-tests',
    icon: 'testEvaluation',
    title: 'Test Evaluation',
    key: 'routes.test_evaluation'
  },
  {
    route: '/teacher/my-account/all-courses-groups',
    icon: 'allCoursesIcon',
    title: 'All Group Courses',
    key: 'routes.all_courses_groups',
  },
  {
    route: '/teacher/create-group',
    icon: 'allCoursesIcon',
    title: 'All Group Courses',
    key: 'routes.create_group',
  },
  {
    route: '/teacher/conversation-club',
    icon: 'mySchedule',
    title: 'Conversation Club',
    key: 'routes.conversation_club'
  },
  {route: '/features/change-log', icon: 'mySchedule', title: 'Site Updates', key: 'routes.change_log'},
  {route: '/teacher/profile', icon: 'profileIcon', title: 'Profile', key: 'routes.profile'},
  {route: '/teacher/setting', icon: 'settingIcon', title: 'Setting', key: 'routes.setting'},
];
// Guest
export const GuestMenu: MenuLink[] = [
  {route: '/teachers', title: 'Teachers', icon: 'teachersIcon', key: 'routes.our_teachers'},
  {route: '/courses', title: 'Our Courses', icon: 'ourCoursesIcon', key: 'routes.our_courses'},
  {route: '/groups/courses', title: 'Groups', icon: 'ourCoursesIcon', key: 'routes.our_groups'},
  {route: '/cefr', icon: 'cefrIcon', title: 'CEFR', key: 'routes.what_cefr'},
  {route: '/user/login', title: 'Sign In', icon: 'signInIcon', key: 'routes.login'},
  {route: '/user/signup', title: 'Sign Up', icon: 'signUpIcon', key: 'routes.sign_up'},
];
export const NormalLinks: RouteLink[] = [
  {isExact: false, name: 'Our Courses', link: '/courses', key: 'routes.our_courses'},
  {isExact: false, name: 'Our Groups', link: '/groups/courses', key: 'routes.our_groups'},
  {isExact: true, name: 'Our Teachers', link: '/teachers', key: 'routes.our_teachers'},
  {isExact: true, name: 'What is CEFR?', link: '/cefr', key: 'routes.what_cefr'},
];


export const Languages = [
  {value: 'en', label: 'EN', fullLabel: 'English', img: './assets/images/languages/united-states.png'},
  {value: 'ar', label: 'ع', fullLabel: 'العريية', img: './assets/images/languages/egypt.png'},
];
export const LangEN = [
  {key: 'en', value: 'EN'},
];
export const LangAR = [
  {key: 'ar', value: 'ع'},
];
