import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    name: 'timesDiff',
    pure: true,
})
export class TimesDiffPipe implements PipeTransform {
    CalcTime(timeInMile: number): { seconds: number; minutes: number; days: number; hours: number; } {
        let time = (timeInMile + 30000) / 1000;
        let days, hours, minutes, seconds;
        days = Math.floor(time / 86400);
        time -= days * 86400;
        hours = Math.floor(time / 3600) % 24;
        time -= hours * 3600;
        minutes = Math.floor(time / 60) % 60;
        time -= minutes * 60;
        seconds = Math.floor(time % 60);
        return {days: days, hours: hours, minutes: minutes, seconds: seconds};
    }

    transform(value: number | undefined, type: string, isEntersBefore: string | null | undefined, serverTime: number):
        { status: boolean; message: string; } {
        const time = this.CalcTime(value as number);
        if (type === 'student') {
            if (time.days === -1 && time.hours === 23 && time.minutes > 40) {
                if (isEntersBefore) {
                    return {message: 'Back to Session', status: true};
                }
                if (!isEntersBefore) {
                    return {message: 'Start Live', status: true};
                }
            }
            if (time.days === -1 && time.hours === 23 && time.minutes < 40) {
                if (isEntersBefore) {
                    return {message: 'Back to Session', status: true};
                }
                if (!isEntersBefore) {
                    return {message: 'Current Session not Attended', status: false};
                }
            }
            else {
                return {message: 'Current Session not Attended', status: false};
            }
        }
        if (type === 'teacher') {
            if (time.days === -1 && time.hours === 23 && time.minutes > 45) {
                if (isEntersBefore) {
                    return {message: 'Back to Session', status: true};
                }
                if (!isEntersBefore) {
                    return {message: 'Add Zoom Link & Submit Attendance', status: true};
                }
            }
            if (time.days === -1 && time.hours === 23 && time.minutes < 45) {
                if (isEntersBefore) {
                    return {message: 'Back to Session', status: true};
                }
                if (!isEntersBefore) {
                    return {message: 'Current Session not Attended', status: false};
                }
                else {
                    return {message: 'Add Zoom Link & Submit Attendance', status: true};
                }
            }
            else {
                return {message: 'Current Session not Attended', status: false};
            }
        }
        else {
            return {message: 'Current Session not Attended', status: false};
        }
    }

}
