<div class="chat-loading">
    <ng-container *ngFor="let item of  [1, 2, 3, 4, 5, 6, 7, 8]">
        <div class="post">
            <div class="loading-left">
                <div class="avatar"></div>
            </div>
            <div class="loading-right">
                <div class="line"></div>
                <div class="line"></div>
            </div>
        </div>
    </ng-container>
</div>
