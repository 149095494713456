import {NavigationExtras} from '@angular/router';

export function getUrlForRouting(route: string, currentLang: string, extra?: NavigationExtras): string {
  console.log('-> currentLang', currentLang);
  const url = `/${currentLang + route}`;

  // Construct URL with extra parameters if provided
  if (extra && extra.queryParams) {
    const params = new URLSearchParams(extra.queryParams).toString();
    const finalUrl = `${url}?${params}`;
    if (extra.fragment) {
      finalUrl.concat(`#${extra.fragment}`);
    }
    return finalUrl;
  } else {
    return url;
  }
}
