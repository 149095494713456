import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, take} from 'rxjs/operators';
import {PresentationalDialogueComponent} from './presentational-dialogue.component';
import {PresentationalDialogue} from '@core/@data/Dialogue/Presentational';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';


@Injectable({
  providedIn: 'root'
})
export class PresentationalDialogueService {
  dialogRef!: MatDialogRef<PresentationalDialogueComponent>;

  constructor(private dialog: MatDialog) {
  }

  public open(options: PresentationalDialogue<any>): void {
    this.dialogRef = this.dialog.open(PresentationalDialogueComponent, {
      data: {
        title: options.title,
        message: options.message,
        cancelText: options.cancelText,
        confirmText: options.confirmText,
        data: options?.data,
        isActions: options?.isActions,
        isData: options?.isData,
        isTitle: options?.isTitle,
        isMessage: options?.isMessage,
        isList: options?.isList,
      },
    });
  }

  public confirmed(): Observable<boolean> {
    return this.dialogRef.afterClosed().pipe(
      take(1),
      map((res) => {
        return res;
      })
    );
  }


  public openScheduleDateNotes(): void {
    const options: PresentationalDialogue<string[]> =
      {
        isList: true,
        data: [
          '1 - There are 4 views for your schedule: Month, Week, Day, & List.',
          '2 - You can change your availability in the week or day views only.',
          '4 - You may remove any slot as long as it’s not booked.',
          '5 - The week starts on Sunday and ends on Saturday.',
          '6 - You can’t have less than 16 hours available per week.',
          '7 - You must always have 4 weeks open ahead in your availability.',
          '8 - You must always have at least 4 days in a week.',
          '9 - You may not have more than 4 hours in a row.',
          'If you need help contact the support team.',
        ],
        title: 'Availability Rules',
        isActions: true,
        cancelText: 'Okay',
        isData: false,
        isTitle: true,
      };
    this.open(options);
  }
}
