import {inject, Pipe, PipeTransform} from '@angular/core';
import {getUrlForRouting} from '@shared/functions/routing.utils';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
  name: 'getUrl'
})
export class GetUrlPipe implements PipeTransform {
  translateService = inject(TranslateService);

  transform(value: string, extra: object): string {
    return getUrlForRouting(value, this.translateService.currentLang, extra)
  }

}
